import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { fetchSORMonitoringDetailAsync } from '@features/sorMonitoring/sorMonitoringSlice';
import { SOR_MONITORING_DETAIL_COLUMNS } from './constants/sorMonitoringDetailConstants';
import useSORMonitoringResponseFetching from './useSORMonitoringResponseFetching';

export default function useSORMonitoringDetailFetching(props) {
  const { defaultFilter, setFilterState, defaultPagination, setPaginationState } = props;
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [isReset, setIsReset] = useState(false);
  const [showMesageDialog, setShowMesageDialog] = useState(false);
  const [messageDialog, setMesageDialog] = useState({ title: '', message: '' });

  const handleFetchSORMonitoringDetailAsync = (pageIndex, pageSize, auditTrailsId, filters) => {
    dispatch(
      fetchSORMonitoringDetailAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageNumber: pageIndex,
        pageSize,
        auditTrailsId,
        filters,
      })
    );
  };

  const {
    id: auditTrailsDetailId,
    handleLoadData,
    handleOnColumnClicked,
    handleOnModalClosed,
  } = useSORMonitoringResponseFetching();

  useEffect(() => {
    if (isReset) {
      setFilterState(defaultFilter);
      setPaginationState(defaultPagination);
    }
    setIsReset(false);
  }, [isReset]);

  const tableColumns = () => {
    const columns = SOR_MONITORING_DETAIL_COLUMNS;

    // add click able function
    return columns.map((item) => {
      if (item.columnName === 'retryCount') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleOnColumnClicked(row.id);
          return '';
        };
      }
      if (item.columnName === 'httpResponse') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          setShowMesageDialog(true);
          setMesageDialog({ title: item.label, message: row[item.columnName] });
          return '';
        };
      }

      return item;
    });
  };

  return {
    handleFetchSORMonitoringDetailAsync,
    tableColumns,
    showMesageDialog,
    setShowMesageDialog,
    messageDialog,
    auditTrailsDetailId,
    handleLoadData,
    handleOnModalClosed,
    setMesageDialog,
  };
}
