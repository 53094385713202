import { resellersListPath } from '@config/Routes/WebClientRoutes';

export default function useDataService() {
  const syncedToXeroItemList = [
    {
      id: 1,
      title: 'Yes',
      value: 'true',
    },
    {
      id: 2,
      title: 'No',
      value: 'false',
    },
  ];

  const orderByItemList = [
    {
      id: 1,
      title: 'Invoice ID',
      value: 'ID',
    },
    {
      id: 2,
      title: 'Partner ID',
      value: 'ResellerID',
    },
    {
      id: 3,
      title: 'Partner Name',
      value: 'ResellerName',
    },
    {
      id: 4,
      title: 'Draft Generated At',
      value: 'CreatedAt',
    },
  ];

  const columnNames = [
    { id: 1, columnName: 'id', label: 'Invoice ID', sx: { paddingLeft: 2 }, size: 100 },
    { id: 2, columnName: 'resellerId', label: 'Partner ID', sx: { paddingLeft: 2 }, size: 100 },
    {
      id: 3,
      columnName: 'resellerName',
      label: 'Partner Name',
      sx: { paddingLeft: 2 },
      size: 240,
    },
    { id: 4, columnName: 'currency', label: 'Currency', sx: { paddingLeft: 2 }, size: 100 },
    {
      id: 5,
      columnName: 'draftInvoiceGeneratedAt',
      label: 'Draft Invoice Generated At',
      sx: { paddingLeft: 2 },
      size: 240,
    },
    {
      id: 6,
      columnName: 'finalInvoiceGeneratedAt',
      label: 'Final Invoice Generated At',
      sx: { paddingLeft: 2 },
      size: 240,
    },
    {
      id: 7,
      columnName: 'directUserEmailSubscriptionCost',
      label: 'Direct User Email Subscription Cost',
      sx: { paddingLeft: 2 },
    },
    {
      id: 8,
      columnName: 'subPartnerEmailSubscriptionCost',
      label: 'Sub-partner Email Subscription Cost',
      sx: { paddingLeft: 2 },
    },
    {
      id: 9,
      columnName: 'qboSubscriptionCost',
      label: 'QBO Subscription Cost',
      sx: { paddingLeft: 2 },
    },
    { id: 10, columnName: 'discount', label: 'Total Discounted Amount', sx: { paddingLeft: 2 } },
    {
      id: 11,
      columnName: 'adjustmentAmount',
      label: 'Total Adjusted Amount',
      sx: { paddingLeft: 2 },
    },
    { id: 12, columnName: 'subTotal', label: 'Sub-Total', sx: { paddingLeft: 2 } },
    { id: 13, columnName: 'gst', label: 'GST', sx: { paddingLeft: 2 } },
    { id: 14, columnName: 'amountBeforeTax', label: 'Amount Before Tax', sx: { paddingLeft: 2 } },
    { id: 15, columnName: 'totalAmount', label: 'Total Amount', sx: { paddingLeft: 2 } },
    { id: 16, columnName: 'xeroInvoiceId', label: 'Xero Invoice ID', sx: { paddingLeft: 2 } },
  ];

  const clickableCoulmnList = [{ id: 1, column: 'resellerName', path: resellersListPath, tab: 7 }];

  return {
    syncedToXeroItemList,
    orderByItemList,
    columnNames,
    clickableCoulmnList,
  };
}
