export const ENTRA_ID_STATUS = 'entraid';
export const ENTRA_ID_STATUS_COLUMNS = [
  { label: 'ID', columnName: 'downloadId' },
  { label: 'Backup Account ID', columnName: 'userBackupId' },
  { label: 'Name', columnName: 'userBackupName' },
  { label: 'Initial Domain', columnName: 'domainName' },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
    navigateURL: (row) =>
      `/organization-info?id=${row.organizationOwnerId}&name=${row.organizationName}`,
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
    navigateURL: (row) => `/partner-info?id=${row.partnerId}&name=${row.partnerName}`,
  },
  { label: 'Download Status', columnName: 'status', size: 'sm' },
  { label: 'Start Download Time', columnName: 'startTime', size: 'sm' },
  { label: 'End Download Time', columnName: 'endTime', size: 'sm' },
  { label: 'Download Parameters', columnName: 'downloadParams', size: 'sm' },
  { label: 'Current Object Number', columnName: 'currentObjectsCount', size: 'sm' },
  { label: 'Total Object Number', columnName: 'totalObjectsCount', size: 'sm' },
  { label: 'Download Size', columnName: 'downloadSize', size: 'sm' },
  { label: 'URLs', columnName: 'urls', size: 'sm' },
  { label: 'Retries Time', columnName: 'retries', size: 'sm' },
  { label: 'Error Messages', columnName: 'errorMessage', size: 'sm' },
  { label: 'Expire At', columnName: 'expireAt', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
];

export const systemStatusItems = [
  {
    id: 1,
    value: 'completed',
    title: 'Completed',
  },
  {
    id: 2,
    value: 'running',
    title: 'Running',
  },
  {
    id: 3,
    value: 'error',
    title: 'Error',
  },
];

export const buttonStyle = {
  height: 36,
  backgroundColor: '#a5a5a5',
  textDecoration: 'none',
  color: 'black',
  '&:hover': {
    backgroundColor: '#a5a5a5',
  },
  textTransform: 'unset',
  fontSize: 14,
};
