import { ownerListAsync, ownerListReducers } from './serviceActions/ownerListAsync';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  ownerListState: {
    fetching: false,
    data: {},
    errorMessage: null,
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    corsErrorMessage: null,
  },
};
const ownerListSlice = createSlice({
  name: 'OwnerList',
  initialState,
  reducers: {
    resetOwnerListState(state) {
      state.ownerListState = initialState.ownerListState;
    },
  },
  extraReducers: {
    ...ownerListReducers,
  },
});

export { ownerListAsync };
export const { resetOwnerListState } = ownerListSlice.actions;
export default ownerListSlice.reducer;
