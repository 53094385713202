import { QboButton, QboModal, QboSnackbar, QboTypography } from '@ui/Components';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import { useDispatch, useSelector } from 'react-redux';
import { toolsRequestDetailsAsync } from '@features/toolsRequestHistory/toolsRequestDetailsSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { Skeleton } from '@mui/material';
import useFeatureFlag from '@hooks/useFeatureFlag';
import NotFoundSection from '../SystemStatusPage/NotFoundSection';
import SummarySection from './SummarySection';
import BulkCreateOrganizationItems from './BulkCreateOrganizationItems';
import BulkCreateSubResellerItems from './BulkCreateSubResellerItems';
import TenantSizeReportDownloadSection from './TenantSizeReportDownloadSection';
import UserDiscrepancyReportDownloadSection from './UserDiscrepancyReportDownloadSection';
import UserBackupReportDownloadSection from './UserBackupReportDownloadSection';
import BulkMailboxDeactivationItems from './BulkMailboxDeactivationItems';

export default function ToolsRequestDetailsSection(props) {
  const { jobId, jobType, handleOnClose } = props;
  const { featureFlags } = useFeatureFlag();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();
  const { toolsRequestDetailsState } = useSelector((state) => state.toolsRequestDetails);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const { fetching, data } = toolsRequestDetailsState;

  const loadData = () => {
    if (!jobId || !jobType) return;
    dispatch(
      toolsRequestDetailsAsync({
        accessToken,
        reset: true,
        id: jobId,
        jobType,
      })
    );
  };

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  useEffect(loadData, [jobId, jobType]);

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  return (
    <QboModal
      onClose={handleOnClose}
      open={!!jobId && !!jobType}
      title="Request Details"
      showCloseIcon
      preventOutsideClick>
      {fetching && renderLoading()}
      {!fetching && !!Object.keys(data).length && (
        <>
          <QboSnackbar onClose={handleSnackbarClose} {...snackbarState} />
          <QboButton
            style={{ marginBottom: '1rem', marginRight: '1rem' }}
            variant="contained"
            onClick={loadData}>
            Refresh
          </QboButton>
          {data['service-type'] === featureFlags.TenantSizeReport && data.status === 200 && (
            <TenantSizeReportDownloadSection
              jobId={jobId}
              jobType={jobType}
              data={data}
              setSnackbarState={setSnackbarState}
            />
          )}
          {data['service-type'] === featureFlags.UserDiscrepancyReport && data.status === 200 && (
            <UserDiscrepancyReportDownloadSection
              jobId={jobId}
              jobType={jobType}
              data={data}
              setSnackbarState={setSnackbarState}
            />
          )}
          {data['service-type'] === featureFlags.UserBackupReport && data.status === 200 && (
            <UserBackupReportDownloadSection
              jobId={jobId}
              jobType={jobType}
              data={data}
              setSnackbarState={setSnackbarState}
            />
          )}
          <QboCardSingleList leftContent="ID" rightContent={data.id} />
          <QboCardSingleList leftContent="Job Type" rightContent={data['job-type']} />
          <QboCardSingleList leftContent="Service Type ID" rightContent={data['service-type-id']} />
          <QboCardSingleList leftContent="Service Type" rightContent={data['service-type']} />
          <QboCardSingleList leftContent="Executed On" rightContent={data['executed-on']} />
          <QboCardSingleList leftContent="Status" rightContent={data.status} />
          <QboCardSingleList
            leftContent="Status Description"
            rightContent={data['status-description']}
          />
          <>
            <QboTypography style={{ marginTop: 25, fontSize: '2rem' }}>Payload</QboTypography>
            <pre
              style={{
                textWrap: 'wrap',
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                padding: 20,
                backgroundColor: '#f2f0f0',
                fontSize: '1.5rem',
                lineHeight: '1.6',
              }}>
              {JSON.stringify(JSON.parse(data.payload), null, 2)}
            </pre>
            {[31, 32, 38].includes(data['service-type-id']) && <SummarySection job={data} />}
            {data['service-type-id'] === 31 && <BulkCreateOrganizationItems jobId={data.id} />}
            {data['service-type-id'] === 32 && <BulkCreateSubResellerItems jobId={data.id} />}
            {data['service-type-id'] === 38 && <BulkMailboxDeactivationItems jobId={data.id} />}
          </>
        </>
      )}
      {!fetching && !Object.keys(data).length && <NotFoundSection />}
    </QboModal>
  );
}

ToolsRequestDetailsSection.propTypes = {
  jobId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  jobType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleOnClose: PropTypes.func,
};

ToolsRequestDetailsSection.defaultProps = {
  handleOnClose: () => {},
};
