import { createSlice } from '@reduxjs/toolkit';
import { entraIdTenantAsync, entraIdTenantReducers } from './serviceActions/entraIdTenantAsync';

const initialState = {
  entraIdTenantState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    entraIdTenantInformation: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const entraIdTenantSlice = createSlice({
  name: 'EntraIdTenant',
  initialState,
  reducers: {
    resetEntraIdTenantState(state) {
      state.entraIdTenantState = initialState.entraIdTenantState;
    },
    resetEntraIdTenantStatusCodeState(state) {
      state.entraIdTenantState.statusCode = initialState.entraIdTenantState.statusCode;
    },
  },
  extraReducers: {
    ...entraIdTenantReducers,
  },
});

export { entraIdTenantAsync };
export const { resetEntraIdTenantState, resetEntraIdTenantStatusCodeState } =
  entraIdTenantSlice.actions;
export default entraIdTenantSlice.reducer;
