import { createAsyncThunk } from '@reduxjs/toolkit';
import { sorMonitoringResponseAPI } from '@services/WebApiService';

const fetchSORMonitoringResponseAsync = createAsyncThunk(
  'SORMonitoringResponse/getSORMonitoringResponses',
  async (paramsResponse) => {
    try {
      const data = await sorMonitoringResponseAPI(paramsResponse);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = fetchSORMonitoringResponseAsync;

const getSORMonitoringResponseExtraReducers = {
  [pending]: (state) => {
    state.sorMonitoringResponseState.fetching = true;
  },
  [rejected]: (state) => {
    state.sorMonitoringResponseState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.sorMonitoringResponseState.fetching = false;

    if (action?.payload?.status === 403) {
      state.sorMonitoringResponseState.forbiddenState = true;
      state.sorMonitoringResponseState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.sorMonitoringResponseState.UsersResponseCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.sorMonitoringResponseState.sorMonitoringResponse = action?.payload?.data?.data;
      state.sorMonitoringResponseState.success = true;
      state.sorMonitoringResponseState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.sorMonitoringResponseState.error = action.payload.error;
    }
  },
};

export { fetchSORMonitoringResponseAsync, getSORMonitoringResponseExtraReducers };
