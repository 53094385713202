import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { fetchSORMonitoringResponseAsync } from '@features/sorMonitoring/sorMonitoringSlice';

export default function useSORMonitoringResponseFetching() {
  const [id, setId] = useState(false);
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const handleOnColumnClicked = (_id) => {
    setId(_id);
  };

  const handleOnModalClosed = () => {
    setId(null);
  };

  const handleLoadData = () => {
    if (!id) return;
    dispatch(
      fetchSORMonitoringResponseAsync({
        accessToken: accessTokenSet,
        reset: true,
        auditTrailsDetailId: id,
      })
    );
  };

  useEffect(handleLoadData, [id]);

  return {
    id,
    handleLoadData,
    handleOnColumnClicked,
    handleOnModalClosed,
  };
}
