import { QboCard, QboNoStatus } from '@ui/Components';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import PropTypes from 'prop-types';
import React from 'react';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useEntraIdOrganizationService from './pageHooks/useEntraIdOrganizationService';
import useDataService from './pageHooks/useDataService';

export default function EntraIdOrganizationPage({ organizationOwnerId }) {
  const { t } = useTranslation();
  const { entraIdOrganizationState } = useEntraIdOrganizationService(organizationOwnerId);
  const { entraIdOrganizationHeaders } = useDataService();

  if (entraIdOrganizationState.fetching) {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  }

  if (!entraIdOrganizationState.entraIdOrganizationInformation) {
    return (
      <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
        <QboNoStatus title={t('entraid_organization_page.no_result')} />
      </QboCard>
    );
  }

  const valueHandler = (propertyName) =>
    entraIdOrganizationState.entraIdOrganizationInformation?.[propertyName] ?? '-';

  return (
    <QboCard
      className="QboCard__wrapper_search_text_highlight"
      sx={{
        scrollbarWidth: 'none !important',
        overflowY: 'scroll !important',
        height: '65vh',
      }}>
      <QboCard sx={{ marginTop: 2, marginBottom: 2 }}>
        {entraIdOrganizationHeaders.map((item) => (
          <QboCardSingleList
            key={item.label}
            leftContent={item.label}
            subHeadingText={item?.subHeadingText}
            rightContent={valueHandler(item.properties)}
          />
        ))}
      </QboCard>
    </QboCard>
  );
}

EntraIdOrganizationPage.propTypes = {
  organizationOwnerId: PropTypes.number,
};

EntraIdOrganizationPage.defaultProps = {
  organizationOwnerId: null,
};
