import { useDispatch, useSelector } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect } from 'react';
import { showFixedAlert } from '@features/application/applicationSlice';
import { useTranslation } from 'react-i18next';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import {
  editResellerConfigsAsync,
  resetEditResellerConfigsState,
} from '@features/resellerConfigs/editResellerConfigsSlice';
import useEditResellerConfigsForm from './useEditResellerConfigsForm';

export default function useEditResellerConfigs({
  resellerId,
  changedValues,
  onSuccessRequest,
  onFailedRequest,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useEditResellerConfigsForm();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { editResellerConfigsState } = useSelector((state) => state.editResellerConfigs);

  const submitRequest = () => {
    const entries = {};
    Object.keys(changedValues).forEach((k) => {
      entries[k] = changedValues[k]?.toString() ?? null;
    });
    dispatch(
      editResellerConfigsAsync({
        accessTokenSet,
        reset: true,
        parameters: {
          'support-ticket': form.supportTicketAttributes.value,
          description: form.descriptionAttributes.value,
          'escalation-ticket': form.escalationTicketAttributes.value,
          resellerId,
          entries,
        },
      })
    );
  };

  const handleOnValidateData = (e) => {
    e.preventDefault();
    submitRequest();
  };

  const resetAll = () => {
    dispatch(resetEditResellerConfigsState());
    form.resetForm();
  };

  useEffect(() => {
    if (editResellerConfigsState.statusCode === 200) {
      if (editResellerConfigsState.serviceType) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': editResellerConfigsState.serviceType,
        });
        dispatch(
          showFixedAlert({
            descriptionHtml: t(
              'partner_list_page.edit_reseller_configs.success_submitted_with_link',
              {
                link,
              }
            ),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('partner_list_page.edit_reseller_configs.success_submitted'),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
    } else if (editResellerConfigsState.error && onFailedRequest) {
      onFailedRequest(editResellerConfigsState.error?.data?.message);
    }
    resetAll();
  }, [editResellerConfigsState.statusCode]);

  return {
    form,
    editResellerConfigsState,
    handleOnValidateData,
    resetAll,
  };
}
