export const EMAIL_RESTORE = 'messages';
export const EMAIL_RESTORE_TEXT = 'email';
export const ONEDRIVE_RESTORE = 'od_files';
export const ONEDRIVE_RESTORE_TEXT = 'one drive';
export const GDRIVE_RESTORE = 'gd_files';
export const GDRIVE_RESTORE_TEXT = 'google drive';
export const CONTACT_RESTORE = 'contacts';
export const CONTACT_RESTORE_TEXT = 'contact';
export const CALENDAR_RESTORE = 'calendars';
export const CALENDAR_RESTORE_TEXT = 'calendar';
export const TASK_RESTORE = 'tasks';
export const TASK_RESTORE_TEXT = 'task';
export const SHAREPOINT_RESTORE = 'sharepoint';
export const SHAREPOINT_RESTORE_TEXT = 'sharepoint';
export const MSGROUP_RESTORE = 'ms_groups';
export const MSGROUP_RESTORE_TEXT = 'group & team';
export const MSCHAT_RESTORE = 'ms_chat';
export const MSCHAT_RESTORE_TEXT = 'ms chat';
export const SHAREDRIVE_RESTORE = 'sd_files';
export const SHAREDRIVE_RESTORE_TEXT = 'shared drive';

export const EMAIL_RESTORE_COLUMNS = [
  { label: 'ID', columnName: 'restoreId' },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
  },
  { label: 'Restore Status', columnName: 'status', size: 'sm' },
  { label: 'Restore Type', columnName: 'restorableType', size: 'sm' },
  { label: 'Email Account ID', columnName: 'emailAccountId' },
  {
    label: 'Email',
    columnName: 'email',
    size: 'sm',
  },
  { label: 'Start Restore Time', columnName: 'startTime', size: 'sm' },
  { label: 'End Restore Time', columnName: 'endTime', size: 'sm' },
  { label: 'Restore Size', columnName: 'restoreSize', size: 'sm' },
  { label: 'Current Items Numbers', columnName: 'currentMessagesCount', size: 'sm' },
  { label: 'Total Items Numbers', columnName: 'totalMessagesCount', size: 'sm' },
  { label: 'Query Params', columnName: 'queryParams', size: 'sm' },
  { label: 'Message Ids', columnName: 'messageIds', size: 'sm' },
  { label: 'From Date', columnName: 'fromDate', size: 'sm' },
  { label: 'To Date', columnName: 'toDate', size: 'sm' },
  { label: 'Destination Id', columnName: 'destinationId', size: 'sm' },
  { label: 'Ms Group Id', columnName: 'msGroupId', size: 'sm' },
  { label: 'Retries Time', columnName: 'retries', size: 'sm' },
  { label: 'Error Messages', columnName: 'errorMessage', size: 'sm' },
  { label: 'Skipped Items', columnName: 'skippedItems', size: 'sm' },
  { label: 'Additional Data', columnName: 'additionalData', size: 'sm' },
  { label: 'Run on Machine IP', columnName: 'machineIP', size: 'sm' },
  { label: 'Cancelled At', columnName: 'cancelledAt', size: 'sm' },
  { label: 'Cancelled By', columnName: 'cancelled By', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
];

export const systemStatusItems = [
  {
    id: 1,
    value: 'completed',
    title: 'Completed',
  },
  {
    id: 2,
    value: 'running',
    title: 'Running',
  },
  {
    id: 3,
    value: 'error',
    title: 'Error',
  },
];

export const buttonStyle = {
  height: 36,
  backgroundColor: '#a5a5a5',
  textDecoration: 'none',
  color: 'black',
  '&:hover': {
    backgroundColor: '#a5a5a5',
  },
  textTransform: 'unset',
  fontSize: 14,
};
