import { createSlice } from '@reduxjs/toolkit';

import {
  exportUserBackupReportAsync,
  exportUserBackupReportExtraReducers,
} from './serviceActions/exportUserBackupReportAsync';

const initialState = {
  exportUserBackupReportState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    data: null,
    contentType: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    extportUserBackupReportCorsErrorMessage: null,
    serviceType: null,
  },
};

const exportUserBackupReportSlice = createSlice({
  name: 'exportUserBackupReport',
  initialState,
  reducers: {
    resetExportUserBackupReportState(state) {
      state.exportUserBackupReportState = initialState.exportUserBackupReportState;
    },
  },
  extraReducers: {
    ...exportUserBackupReportExtraReducers,
  },
});

export { exportUserBackupReportAsync };

export const { resetExportUserBackupReportState } = exportUserBackupReportSlice.actions;
export default exportUserBackupReportSlice.reducer;
