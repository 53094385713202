import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { fontFamily, fontSize, fontActiveColor } from '@config/themes/default/variables';
import { childrenToString } from '@utilities';
import { TooltipWrapper } from '@ui/Components';

const styles = {
  fontFamily,
  fontSize,
  color: fontActiveColor,
  textDecorationColor: `${fontActiveColor} !important`,
};

export default function QboLink({
  sx,
  children,
  title,
  onClick,
  navigate,
  className,
  withTitle,
  href,
  to,
  ...rest
}) {
  // const routeNavigate = useNavigate();

  const onClickLink = (e) => {
    if (typeof onClick === 'function') onClick(e);
    // if (typeof navigate === 'string') routeNavigate(navigate);
  };

  return (
    <TooltipWrapper condition={withTitle} title={title || childrenToString(children)}>
      {typeof to === 'string' ? (
        <Link
          component={RouterLink}
          noWrap
          className={`QboLink__wrapper ${
            navigate || onClickLink ? 'QboLink__with-navigate' : ''
          } ${className}`}
          underline="none"
          sx={{
            ...styles,
            ...sx,
          }}
          onClick={onClickLink}
          to={to}
          {...rest}>
          {children}
        </Link>
      ) : (
        <Link
          noWrap
          className={`QboLink__wrapper ${
            navigate || onClickLink ? 'QboLink__with-navigate' : ''
          } ${className}`}
          underline="none"
          sx={{
            ...styles,
            ...sx,
          }}
          onClick={onClickLink}
          href={href}
          {...rest}>
          {children}
        </Link>
      )}
    </TooltipWrapper>
  );
}

QboLink.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  navigate: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  withTitle: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
};

QboLink.defaultProps = {
  sx: {},
  children: '',
  title: null,
  onClick: undefined,
  navigate: undefined,
  className: '',
  withTitle: false,
  href: undefined,
  to: undefined,
};
