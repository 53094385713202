export const SD_DRIVE = 'sd_drive';
export const SD_DRIVE_TEXT = 'Shared Drive';
export const SD_DRIVE_COLUMNS = [
  { label: 'ID', columnName: 'userBackupId' },
  { label: 'Shared Drive Name', columnName: 'sharedDriveName', size: 'sm' },
  {
    label: 'Domain ID',
    columnName: 'domainId',
    size: 'sm',
  },
  {
    label: 'Domain Name',
    columnName: 'domainName',
    size: 'sm',
  },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
  },
  {
    label: 'Parent Name',
    columnName: 'distributorName',
    size: 'sm',
  },
  { label: 'Item ID', columnName: 'itemId', size: 'sm' },
  { label: 'Is Active', columnName: 'isActive', size: 'sm' },
  { label: 'Number of files', columnName: 'fileCount', size: 'sm' },
  { label: 'Shared Drive Size', columnName: 'sharedDriveSize', size: 'sm' },
  { label: 'Last Backup At', columnName: 'lastBackupAt', size: 'sm' },
  { label: 'Last Backup Status', columnName: 'lastBackupStatus', size: 'sm' },
  {
    label: 'Last Notification Success Backup Sent At',
    columnName: 'lastBackupSuccessSentAt',
    size: 'sm',
  },
  {
    label: 'Last Status Check At',
    columnName: 'lastStatusCheckAt',
    size: 'sm',
  },
  { label: 'Next Backup At', columnName: 'nextBackupAt', size: 'sm' },
  {
    label: 'Total Storage Used (in KB)',
    columnName: 'totalStorageUsed',
    size: 'sm',
  },
  {
    label: 'Total Version',
    columnName: 'totalVersion',
    size: 'sm',
  },
  {
    label: 'Last Downloaded Snapshot At',
    columnName: 'lastDownloadedSnapshotAt',
    size: 'sm',
  },
  {
    label: 'Last Downloaded Snapshot Name',
    columnName: 'lastDownloadedSnapshotName',
    size: 'sm',
  },
  {
    label: 'Error Notification Pause Until',
    columnName: 'errorNotificationPauseUntil',
    size: 'sm',
  },

  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
  { label: 'Deactivated Since', columnName: 'deactivatedSince', size: 'sm' },
  { label: 'Deactivated By', columnName: 'deactivatedBy', size: 'sm' },
  { label: 'Deleted At', columnName: 'deletedAt', size: 'sm' },
];
export const SD_DRIVE_DETAIL_ROWS = {
  userBackupId: 'Shared Drive Account ID',
  sharedDriveName: 'Shared Drive Name',
  domainId: 'Domain ID',
  domainName: 'Domain Name',
  organizationName: 'Organization Name',
  partnerName: 'Partner Name',
  distributorName: 'Parent Name',
  itemId: 'Item ID',
  isActive: 'Is Active',
  fileCount: 'Number of files',
  sharedDriveSize: 'Shared Drive Size',
  lastBackupId: 'Last Backup ID',
  lastBackupAt: 'Last Backup At',
  nextBackupAt: 'Next Backup At',
  deactivatedSince: 'Deactivated Since',
  deactivatedBy: 'Deactivated By',
  totalStorageUsed: {
    title: 'Total Storage Used',
    sub: 'in KB - Reflecting the usage of s3',
  },
  totalVersion: 'Total Version',
  lastDownloadedSnapshotAt: 'Last Downloaded Snapshot At',
  lastDownloadedSnapshotName: 'Last Downloaded Snapshot Name',
  deltaLink: 'Delta Link',
  lastStatusCheckAt: 'Last Status Check At',
  LastBackupSuccessSentAt: 'Last Backup Success Sent At',
  errorNotificationPauseUntil: 'Error Notification Pause Until',
};
export const SD_DRIVE_LAST_BACKUP_ROWS = {
  backupId: 'Last Shared Drive Backup ID',
  userBackupId: 'Shared Drive Account ID',
  domainId: 'Domain ID',
  domainName: 'Domain Name',
  startTime: 'Start Backup Time',
  endTime: 'End Backup Time',
  totalFilesCount: 'Total Files Count',
  currentFilesCount: 'Current Files Count',
  status: 'Backup Status',
  backupStorage: 'Backup Storage (in Byte)',
  retries: 'Retries Time',
  errorMessage: 'Error Message',
  machineIP: 'Run on Machine IP',
  createdAt: 'Created At',
  updatedAt: 'Last Updated At',
};
