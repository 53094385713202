import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const DmeSettingsService = {
  getOwnerListApi: (paramsList) => {
    const { accessToken, reset } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.getOwnerListPath(),
    });
  },
  getChannelListApi: (paramsList) => {
    const { accessToken, reset } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.getChanneListPath(),
    });
  },
};

export default DmeSettingsService;
