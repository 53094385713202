import { useState } from 'react';

const descriptionName = 'description';
const supportTicketName = 'supportTicket';
const escalationTicketName = 'escalationTicket';

const InitialForm = {
  [descriptionName]: '',
  [supportTicketName]: '',
  [escalationTicketName]: '',
};

export default function useEditResellerConfigsForm() {
  const [formData, setFormData] = useState(InitialForm);

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    value: formData[supportTicketName],
  };

  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    value: formData[descriptionName],
  };

  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    value: formData[escalationTicketName],
  };
  const resetForm = () => {
    setFormData(InitialForm);
  };

  return {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    resetForm,
  };
}
