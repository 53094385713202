export default function useDataService() {
  const m365TenantInfoDetails = [
    {
      label: 'Credential ID',
      properties: 'id',
      valType: 'int',
    },
    {
      label: 'Organization ID',
      properties: 'organizationOwnerId',
      valType: 'int',
    },
    {
      label: 'Tenant ID',
      properties: 'tenant',
      valType: 'string',
    },
    {
      label: 'Partner Name',
      properties: 'tenantPartnerName',
      valType: 'string',
    },
    {
      label: 'Organization Name',
      properties: 'orgName',
      valType: 'string',
    },
    {
      label: 'Parent Name',
      properties: 'tenantParent',
      valType: 'string',
    },
    {
      label: 'Main App ID',
      properties: 'azureAppId',
      valType: 'string',
    },
    {
      label: 'Main App Redirect URL',
      properties: 'azureAppRedirectUrl',
      valType: 'string',
    },
    {
      label: 'Backup Admin Email',
      properties: 'encryptedEmail',
      valType: 'string',
    },
    {
      label: 'Type Process',
      properties: 'processType',
      valType: 'string',
    },
    {
      label: 'Main Credential',
      properties: 'isMain',
      valType: 'string',
    },
    {
      label: 'Created At',
      properties: 'createdAt',
      valType: 'string',
    },
    {
      label: 'Last Updated At',
      properties: 'updatedAt',
      valType: 'string',
    },
    {
      label: 'Azure App Version',
      properties: 'azureAppVersion',
      valType: 'string',
    },
    {
      label: 'Domain',
      properties: 'domain',
      valType: 'string',
    },
    {
      label: 'Managed Domain',
      properties: 'managedDomain',
      valType: 'string',
    },
    {
      label: 'Error Message',
      properties: 'errorMessage',
      valType: 'string',
    },
    {
      label: 'Initial Setup Status',
      properties: 'status',
      valType: 'string',
    },
    {
      label: 'Autodiscover Enabled At',
      properties: 'lastAutodiscoverActivatedAt',
      valType: 'string',
    },
    {
      label: 'Main App Permission Status',
      properties: 'permissionStatus',
      valType: 'int',
    },
    {
      label: 'Delegated Refresh Token Present?',
      properties: 'delegatedRefreshToken',
      valType: 'string',
    },
    {
      label: 'Delegated Refresh Token Valid',
      properties: 'isDelegatedRefreshTokenValid',
      valType: 'bool',
    },
    {
      label: 'Use App Password?',
      properties: 'encryptedAppPassword',
      valType: 'string',
    },
    {
      label: 'App Password Valid',
      properties: 'isAppPasswordValid',
      valType: 'bool',
    },
    {
      label: 'MFA Enabled',
      properties: 'mfaEnabled',
      valType: 'string',
    },
    {
      label: 'App Password Updated At',
      properties: 'appPasswordUpdatedAt',
      valType: 'string',
    },
    {
      label: 'Created Before MFA',
      properties: 'isCreatedBeforeMfa',
      valType: 'bool',
    },
    {
      label: 'Public Folder Backup',
      properties: 'backupPublicFolder',
      valType: 'string',
    },
    {
      label: 'Total Users in M365',
      properties: 'total_users',
      valType: 'int',
    },
    {
      label: 'Last Fetch Users At',
      properties: 'lastScheduleMsGraphRequestAt',
      valType: 'string',
    },
    {
      label: 'Adding Backup',
      properties: 'addingBackupEmail',
      valType: 'bool',
    },
    {
      label: 'Last Added User to Backup At',
      properties: 'lastAddedBackupEmailAt',
      valType: 'string',
    },
    {
      label: 'Last Added User Backup Refereces',
      properties: 'addBackupEmailReferences',
      valType: 'string',
    },
    {
      label: 'Device Refresh Token Present?',
      properties: 'deviceRefreshToken',
      valType: 'string',
    },
    {
      label: 'Device Refresh Token Valid',
      properties: 'isDeviceRefreshTokenValid',
      valType: 'bool',
    },
    {
      label: 'Use Device Authorization',
      properties: 'useDeviceAuthorization',
      valType: 'bool',
    },
    {
      label: 'Last Request Fetch User ID',
      properties: 'lastMsGraphRequestId',
      valType: 'int',
    },
    {
      label: 'Group Chat Restore Permission',
      properties: 'hasChatRestorePermission',
      valType: 'bool',
    },
    {
      label: 'Deleted At',
      properties: 'deletedAt',
      valType: 'string',
    },
    {
      label: 'Last Sync Users to M365 Throuh Sales ops At',
      properties: 'lastMailboxSync',
      valType: 'string',
    },
    {
      label: 'Syncing Users to M365 Throuh Sales ops At',
      properties: 'isMailboxSyncing',
      valType: 'bool',
    },
    {
      label: 'Private Chat Backup permission',
      properties: 'hasMsChatBackupPermission',
      valType: 'bool',
    },
    {
      label: 'Lisence Checker CSV Report Masked',
      properties: 'maskedReport',
      valType: 'int',
    },
    {
      label: 'Ad Sync Department',
      properties: 'adSyncDepartment',
      valType: 'bool',
    },
    {
      label: 'Ad Sync User Enabled At',
      properties: 'adSyncUserEnabledAt',
      valType: 'bool',
    },
    {
      label: 'Last Ad Sync Run At',
      properties: 'lastAdSyncAt',
      valType: 'string',
    },
    {
      label: 'Ad Sync Running',
      properties: 'adSyncProcess',
      valType: 'bool',
    },
    {
      label: 'Last Fetch Sp Sites ID',
      properties: 'lastSpSiteRequestId',
      valType: 'int',
    },
    {
      label: 'Adding Site to backup',
      properties: 'addingBackupSite',
      valType: 'bool',
    },
    {
      label: 'Total Site Numbers',
      properties: 'totalSites',
      valType: 'bool',
    },
    {
      label: 'Last Added Site Backup Refereces',
      properties: 'addBackupSiteReferences',
      valType: 'string',
    },
    {
      label: 'Last Added Site Backup At',
      properties: 'lastAddedBackupSiteAt',
      valType: 'string',
    },
    {
      label: 'Last Fetch Sp Sites At',
      properties: 'lastScheduleSpSiteRequestAt',
      valType: 'string',
    },
    {
      label: 'Sp Site Autodiscover Enabled At',
      properties: 'spSiteAutodiscoverActivatedAt',
      valType: 'string',
    },
    {
      label: 'Private Chat Request Limit',
      properties: 'monthlyChatMessageRequestLimit',
      valType: 'int',
    },
    {
      label: 'Private Chat Request Usage',
      properties: 'monthlyChatMessageRequestUsage',
      valType: 'int',
    },
    {
      label: 'Enable Customer Tenant App (for Private Chat Backup)',
      properties: 'enableCustomerApp',
      valType: 'bool',
    },
    {
      label: 'Retention Policy ID',
      properties: 'retentionPolicyId',
      valType: 'int',
    },
    {
      label: 'Last Sync Backup User At',
      properties: 'lastSyncUsersAt',
      valType: 'string',
    },
    {
      label: 'Syncing Backup User',
      properties: 'syncUsersProcess',
      valType: 'bool',
    },
    {
      label: 'Enabled Sync Users At',
      properties: 'activateSyncUsersAt',
      valType: 'string',
    },
    {
      label: 'Use Backup Admin',
      properties: 'useBackupAdmin',
      valType: 'string',
    },
    {
      label: 'EXO Auth Status',
      properties: 'exoAuthStatus',
      valType: 'int',
    },
    {
      label: 'SPA flow',
      properties: 'nonBackupAdminFlow',
      valType: 'int',
    },
    {
      label: 'Migrated to SPA At',
      properties: 'migratedToNonBackupAdminAt',
      valType: 'string',
    },
    {
      label: 'Migrated to SPA Status',
      properties: 'migrateToNonBackupAdminStatus',
      valType: 'int',
    },
    {
      label: 'Custom Role Creatio Initiated At',
      properties: 'customRoleCreationInitiatedAt',
      valType: 'string',
    },
    {
      label: 'Service App ID',
      properties: 'encryptedServiceAppId',
      valType: 'string',
    },
    {
      label: 'Service App Secret',
      properties: 'encryptedServiceAppSecret',
      valType: 'string',
    },
    {
      label: 'Service App Redirect URL',
      properties: 'serviceAppRedirectUrl',
      valType: 'string',
    },
    {
      label: 'Endpoint',
      properties: 'url',
      valType: 'string',
    },
    {
      label: 'Ms Group Created At',
      properties: 'msGroupCreatedAt',
      valType: 'string',
    },
    {
      label: 'Ms Group Updated At',
      properties: 'msGroupUpdatedAt',
      valType: 'string',
    },
    {
      label: 'Available Licensed Users Number',
      properties: 'availableEmailAccountCount',
      valType: 'string',
    },
    {
      label: 'Available Not Licensed Users Number',
      properties: 'availableEmailAccountUlCount',
      valType: 'string',
    },
    {
      label: 'Added Users Number',
      properties: 'activeEmailAccountCount',
      valType: 'string',
      subHeadingText: '(only mailbox)',
    },
    {
      label: 'Available Public Folder Number',
      properties: 'availablePublicFolderCount',
      valType: 'string',
    },
    {
      label: 'Added Public Folder Number',
      properties: 'activePublicFolderCount',
      valType: 'string',
    },
    {
      label: 'All Available Users Number',
      properties: 'allBlacklistAccountCount',
      valType: 'string',
    },
    {
      label: 'Excluded Users Numbers',
      properties: 'allAvailableAccountCount',
      valType: 'string',
    },
    {
      label: 'All Added Users Number',
      properties: 'allActiveAccountCount',
      valType: 'string',
      subHeadingText: '(mailbox + public folder)',
    },
    {
      label: 'All Licensed Users Number',
      properties: 'allLicensedCount',
      valType: 'string',
    },
    {
      label: 'All Added Licensed Users Number',
      properties: 'addedLicensedCount',
      valType: 'string',
    },
    {
      label: 'All Added Not Licensed Users Number',
      properties: 'addedSharedMailboxCount',
      valType: 'string',
    },
  ];
  return {
    m365TenantInfoDetails,
  };
}
