import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QboSortByButton from '@ui/Components/QboSortByButton';
import usePlanFilterHook from '@pages/sharedHooks/usePlanFilterHook';

export default function PlanFilterSection(props) {
  const {
    resellerId,
    value,
    handleChange,
    label,
    inputSize,
    marginTopValue,
    marginRightValue,
    buttonWidth,
    defaultValue,
  } = props;
  const { planFilterState, resetPlanFilter, UpdateFilters, planList } = usePlanFilterHook(
    resellerId,
    value
  );

  useEffect(() => {
    handleChange('');
  }, [resellerId]);

  useEffect(() => {
    if (value !== '') {
      UpdateFilters(value, ['selectedPlan']);
      handleChange(value);
    }
  }, [value]);

  return (
    <QboSortByButton
      defaultValue={defaultValue}
      forceUpdate={planFilterState.forceUpdate}
      searchState={planFilterState.searchState}
      handleCloseClick={() => {
        resetPlanFilter();
        handleChange('');
      }}
      isSearchEnable={!false}
      handleOnSearchChange={(e) => {
        UpdateFilters(e.target.value, ['searchState']);
      }}
      handleChange={(e) => {
        UpdateFilters(e.target.value, ['selectedPlan']);
        handleChange(e.target.value);
      }}
      marginRightValue={marginRightValue}
      marginTopValue={marginTopValue}
      value={planFilterState.selectedPlan}
      buttonWidth={buttonWidth}
      startAdornmentTextWidth={inputSize}
      startAdornmentText={label}
      menuItemList={planList}
    />
  );
}

PlanFilterSection.propTypes = {
  resellerId: PropTypes.number,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  inputSize: PropTypes.number,
  marginTopValue: PropTypes.number,
  marginRightValue: PropTypes.number,
  buttonWidth: PropTypes.number,
  defaultValue: PropTypes.string,
};

PlanFilterSection.defaultProps = {
  resellerId: 0,
  value: '',
  handleChange: () => {},
  label: 'Plans',
  inputSize: 180,
  marginTopValue: undefined,
  marginRightValue: 2,
  buttonWidth: 220,
  defaultValue: 'All',
};
