import { createSlice } from '@reduxjs/toolkit';
import {
  bulkMailboxDeactivationAsync,
  bulkMailboxDeactivationExtraReducers,
} from './serviceActions/bulkMailboxDeactivationAsync';

const initialState = {
  bulkMailboxDeactivationState: {
    fetching: false,
    statusCode: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
    serviceType: null,
  },
};

const bulkMailboxDeactivationSlice = createSlice({
  name: 'bulkMailboxDeactivation',
  initialState,
  reducers: {
    resetBulkMailboxDeactivationState(state) {
      state.bulkMailboxDeactivationState = initialState.bulkMailboxDeactivationState;
    },
  },
  extraReducers: {
    ...bulkMailboxDeactivationExtraReducers,
  },
});

export { bulkMailboxDeactivationAsync };

export const { resetBulkMailboxDeactivationState } = bulkMailboxDeactivationSlice.actions;
export default bulkMailboxDeactivationSlice.reducer;
