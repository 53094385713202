import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { pascalToTitleCase } from '@utilities/stringUtils';
import { partnerDetailAsync } from '@features/partnerDetail/partnerDetailSlice';

const supportTicketName = 'supportTicket';
const escalationTicketName = 'escalationTicket';
const descriptionName = 'description';
const partnerIdName = 'partnerId';
const requestTypeName = 'requestType';
const newBackupPriceName = 'newBackupPrice';
const newArchivePriceName = 'newArchivePrice';
const newNfpBackupPriceName = 'newNfpBackupPrice';
const newNfpArchivePriceName = 'newNfpArchivePrice';
const newQboPriceName = 'newQboPrice';
const planIdName = 'planId';
const newPlanPriceName = 'newPlanPrice';
const accountIdsName = 'AccountIds';
const FileName = 'mappingFile';

export const PRICE_GUIDE = 1;
export const PLAN_PRICE = 2;
export const ORGANIZATION_PRICE = 3;

const initialForm = {
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [descriptionName]: '',
  [partnerIdName]: '',
  [requestTypeName]: '',
  [newBackupPriceName]: null,
  [newArchivePriceName]: null,
  [newNfpBackupPriceName]: null,
  [newNfpArchivePriceName]: null,
  [newQboPriceName]: null,
  [planIdName]: 0,
  [newPlanPriceName]: null,
  [accountIdsName]: '',
  [FileName]: '',
};

export default function useFormValidation() {
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(initialForm);
  const [requestTypes, setRequestTypes] = useState({});
  const { partnerDetailState } = useSelector((state) => state.partnerDetail);
  const { data: resellerData } = partnerDetailState;

  const formSchema = yup
    .object()
    .shape({
      [supportTicketName]: yup.string().required('Support Ticket is required field'),
      [escalationTicketName]: yup.string(),
      [descriptionName]: yup.string(),
      [partnerIdName]: yup.number().required('Partner ID is required field'),
      [requestTypeName]: yup.number().required('Request Type is required field'),
      [newBackupPriceName]: yup
        .number()
        .nullable()
        .transform((value, original) => (original === '' ? null : value)),
      [newArchivePriceName]: yup
        .number()
        .nullable()
        .transform((value, original) => (original === '' ? null : value)),
      [newNfpBackupPriceName]: yup
        .number()
        .nullable()
        .transform((value, original) => (original === '' ? null : value)),
      [newNfpArchivePriceName]: yup
        .number()
        .nullable()
        .transform((value, original) => (original === '' ? null : value)),
      [newQboPriceName]: yup
        .number()
        .nullable()
        .transform((value, original) => (original === '' ? null : value)),
      [planIdName]: yup
        .string()
        .nullable()
        .when(requestTypeName, {
          is: (value) => {
            return [PLAN_PRICE, ORGANIZATION_PRICE].includes(value);
          },
          then: () => yup.string().required('Plan ID is required field'),
        }),
      [newPlanPriceName]: yup
        .number()
        .nullable()
        .when(requestTypeName, {
          is: (value) => {
            return [PLAN_PRICE, ORGANIZATION_PRICE].includes(value);
          },
          then: () => yup.number().required('New Price is required field'),
        }),
      [accountIdsName]: yup
        .string()
        .nullable()
        .when(requestTypeName, {
          is: (value) => {
            return [ORGANIZATION_PRICE].includes(value);
          },
          then: () => yup.string().required('Account Ids is required field'),
        }),
      [FileName]: yup
        .mixed()
        .test('isValidType', 'Not a valid type', (value) =>
          value ? ['txt', 'csv'].indexOf(value?.name?.split('.')?.pop()) > -1 : true
        ),
    })
    .test(
      'at-least-one-price',
      'At least one price field is required',
      (value) =>
        value[requestTypeName] !== PRICE_GUIDE ||
        (value[newBackupPriceName] != null && value[newBackupPriceName] !== '') ||
        (value[newArchivePriceName] != null && value[newArchivePriceName] !== '') ||
        (value[newNfpBackupPriceName] != null && value[newNfpBackupPriceName] !== '') ||
        (value[newNfpArchivePriceName] != null && value[newNfpArchivePriceName] !== '') ||
        (value[newQboPriceName] != null && value[newQboPriceName] !== '')
    );

  const validate = async (value) => {
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      // eslint-disable-next-line prefer-const
      let errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: pascalToTitleCase(error.message.split(',')[0]),
        };
      }, {});
      if (
        value[requestTypeName] === PRICE_GUIDE &&
        !value[newBackupPriceName] &&
        !value[newArchivePriceName] &&
        !value[newQboPriceName] &&
        !value[newNfpBackupPriceName] &&
        !value[newNfpArchivePriceName]
      ) {
        const errorMessage = 'At least one price field is required';
        errorLogs[newBackupPriceName] = errorMessage;
        errorLogs[newArchivePriceName] = errorMessage;
        errorLogs[newQboPriceName] = errorMessage;
        errorLogs[newNfpBackupPriceName] = errorMessage;
        errorLogs[newNfpArchivePriceName] = errorMessage;
      }

      setErrors(errorLogs);
      return errorLogs;
    });

    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
  };

  const validateAll = () => {
    validate(formData);
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };
  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    value: formData[escalationTicketName],
  };
  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    value: formData[descriptionName],
  };
  const partnerIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [partnerIdName]: value });
      if (value) dispatch(partnerDetailAsync({ id: value }));
    },
    errorMessage: errors[partnerIdName],
    value: formData[partnerIdName],
  };
  const requestTypeAttributes = {
    handleChange: async (value) => {
      updateFormData({ [requestTypeName]: parseInt(value, 10) });
    },
    errorMessage: errors[requestTypeName],
    value: formData[requestTypeName],
  };
  const newBackupPriceAttributes = {
    handleChange: async (value) => {
      updateFormData({ [newBackupPriceName]: value === '' ? null : value });
    },
    errorMessage: errors[newBackupPriceName],
    value: formData[newBackupPriceName],
  };
  const newArchivePriceAttributes = {
    handleChange: async (value) => {
      updateFormData({ [newArchivePriceName]: value === '' ? null : value });
    },
    errorMessage: errors[newArchivePriceName],
    value: formData[newArchivePriceName],
  };
  const newNfpBackupPriceAttributes = {
    handleChange: async (value) => {
      updateFormData({ [newNfpBackupPriceName]: value === '' ? null : value });
    },
    errorMessage: errors[newNfpBackupPriceName],
    value: formData[newNfpBackupPriceName],
  };
  const newNfpArchivePriceAttributes = {
    handleChange: async (value) => {
      updateFormData({ [newNfpArchivePriceName]: value === '' ? null : value });
    },
    errorMessage: errors[newNfpArchivePriceName],
    value: formData[newNfpArchivePriceName],
  };
  const newQboPriceAttributes = {
    handleChange: async (value) => {
      updateFormData({ [newQboPriceName]: value === '' ? null : value });
    },
    errorMessage: errors[newQboPriceName],
    value: formData[newQboPriceName],
  };
  const planIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [planIdName]: value });
    },
    errorMessage: errors[planIdName],
    value: formData[planIdName],
  };

  const newPlanPriceAttributes = {
    handleChange: async (value) => {
      updateFormData({ [newPlanPriceName]: value });
    },
    errorMessage: errors[newPlanPriceName],
    value: formData[newPlanPriceName],
  };

  const accountIdsAttributes = {
    handleChange: async (value) => {
      updateFormData({ [accountIdsName]: value });
    },
    errorMessage: errors[accountIdsName],
    value: formData[accountIdsName],
  };

  const FileAttributes = {
    handleChange: async (value) => {
      updateFormData({ [FileName]: value });
    },
    errorMessage: errors[FileName],
    value: formData[FileName],
  };

  const resetForm = () => {
    setFormData(initialForm);
    setErrors({});
    setIsValid(false);
  };

  const invalidateForm = () => {
    setIsValid(false);
  };

  useEffect(() => {
    if (resellerData.parentId != null) {
      setRequestTypes({
        [PLAN_PRICE]: 'Change Plan Price',
        [ORGANIZATION_PRICE]: 'Change Organization Price for Several Organizations',
      });
      updateFormData({ [requestTypeName]: PLAN_PRICE });
    } else {
      setRequestTypes({
        [PRICE_GUIDE]: 'Change Price Guide',
        [PLAN_PRICE]: 'Change Plan Price',
        [ORGANIZATION_PRICE]: 'Change Organization Price for Several Organizations',
      });
      updateFormData({ [requestTypeName]: PRICE_GUIDE });
    }
  }, [resellerData]);

  return {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    partnerIdAttributes,
    requestTypeAttributes,
    newBackupPriceAttributes,
    newArchivePriceAttributes,
    newNfpBackupPriceAttributes,
    newNfpArchivePriceAttributes,
    newQboPriceAttributes,
    planIdAttributes,
    newPlanPriceAttributes,
    accountIdsAttributes,
    FileAttributes,
    validateAll,
    isValid,
    resetForm,
    requestTypes,
    invalidateForm,
  };
}
