import { channelListAsync, channelListReducers } from './serviceActions/channelListAsync';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  channelListState: {
    fetching: false,
    data: {},
    errorMessage: null,
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    corsErrorMessage: null,
  },
};
const channelListSlice = createSlice({
  name: 'ChannelList',
  initialState,
  reducers: {
    resetChannelListState(state) {
      state.channelListState = initialState.channelListState;
    },
  },
  extraReducers: {
    ...channelListReducers,
  },
});

export { channelListAsync };
export const { resetChannelListState } = channelListSlice.actions;
export default channelListSlice.reducer;
