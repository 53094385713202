import { showFixedAlert } from '@features/application/applicationSlice';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export default function useEditReseller() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isEditting, setIsEditting] = useState({});
  const [originalValues, setOriginalValues] = useState({});
  const [fieldValues, setFieldValues] = useState({});
  const [modalState, setModalState] = useState({});
  const [labels, setLabels] = useState({});

  const getChangedValues = (section) => {
    if (!originalValues[section] || !fieldValues[section]) {
      return fieldValues[section] ?? {};
    }
    const retval = {};
    Object.keys(originalValues[section]).forEach((k) => {
      if (originalValues[section][k] === fieldValues[section][k]) return;
      retval[k] = fieldValues[section][k] ?? null;
    });
    return retval;
  };

  const isSaveable = useMemo(() => {
    const retval = {};
    Object.keys(originalValues).forEach((section) => {
      retval[section] = Object.keys(getChangedValues(section)).length > 0;
    });
    return retval;
  }, [originalValues, fieldValues]);

  const handleInitializeEdit = (section, data, fields) => {
    const sectionFieldValues = {};
    const sectionLabels = {};
    fields
      .filter((f) => f.editable && f.properties)
      .forEach((f) => {
        sectionFieldValues[f.properties] = data[f.properties];
        sectionLabels[f.properties] = { label: f.label, subHeadingText: f.subHeadingText };
      });

    setFieldValues({
      ...fieldValues,
      [section]: sectionFieldValues,
    });
    setOriginalValues({
      ...originalValues,
      [section]: sectionFieldValues,
    });

    setLabels({
      ...labels,
      [section]: sectionLabels,
    });

    setIsEditting({
      ...isEditting,
      [section]: true,
    });
  };

  const handleCancelEdit = (section) => {
    setFieldValues({
      ...fieldValues,
      [section]: undefined,
    });

    setOriginalValues({
      ...originalValues,
      [section]: undefined,
    });

    setLabels({
      ...labels,
      [section]: undefined,
    });

    setIsEditting({
      ...isEditting,
      [section]: undefined,
    });
  };

  const handleResetEdit = () => {
    setFieldValues({});
    setOriginalValues({});
    setLabels({});
    setIsEditting({});
    setModalState({});
  };

  const handleUpdateField = (section, fieldName, value) => {
    setFieldValues({
      ...fieldValues,
      [section]: {
        ...fieldValues[section],
        [fieldName]: value,
      },
    });
  };

  const handleOpenModal = (section) => {
    const changedValues = getChangedValues(section);
    if (!changedValues || Object.keys(changedValues).length === 0) {
      dispatch(
        showFixedAlert({
          descriptionHtml: t('partner_list_page.edit_reseller_configs.validations.no_changes'),
          severity: 'error',
        })
      );
      return;
    }
    setModalState({
      show: true,
      labels: labels[section],
      changedValues,
    });
  };

  const handleCloseModal = () => {
    setModalState({});
  };

  return {
    isEditting,
    isSaveable,
    fieldValues,
    modalState,
    handleInitializeEdit,
    handleCancelEdit,
    handleResetEdit,
    handleUpdateField,
    handleOpenModal,
    handleCloseModal,
  };
}
