export const MS_GROUP = 'ms_group';
export const MS_GROUP_TEXT = 'Groups & Teams';
export const MS_GROUP_COLUMNS = [
  { label: 'ID', columnName: 'userBackupId' },
  {
    label: 'Group Name',
    columnName: 'groupName',
    size: 'sm',
  },
  {
    label: 'Domain Name',
    columnName: 'domainName',
    size: 'sm',
  },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
  },
  {
    label: 'Parent Name',
    columnName: 'distributorName',
    size: 'sm',
  },
  { label: 'Mail', columnName: 'mail', size: 'sm' },
  { label: 'Active', columnName: 'active', size: 'sm' },
  { label: 'Mailbox Hidden', columnName: 'mailboxHidden', size: 'sm' },
  { label: 'Is Team', columnName: 'team', size: 'sm' },
  { label: 'Last Backup At', columnName: 'lastBackupAt', size: 'sm' },
  { label: 'Last Backup Status', columnName: 'lastBackupStatus', size: 'sm' },
  {
    label: 'Last Backup Successful At',
    columnName: 'lastBackupSuccessfulAt',
    size: 'sm',
  },
  {
    label: 'Last Notification Success Backup Sent At',
    columnName: 'lastBackupSuccessSentAt',
    size: 'sm',
  },
  { label: 'Next Backup At', columnName: 'nextBackupAt', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
  { label: 'Deactivated Since', columnName: 'deactivatedSince', size: 'sm' },
  { label: 'Deactivated By', columnName: 'deactivatedBy', size: 'sm' },
  { label: 'Deleted At', columnName: 'deletedAt', size: 'sm' },
];
export const MS_GROUP_DETAIL_ROWS = {
  userBackupId: 'Group Account ID',
  groupName: 'Group Name',
  domainName: 'Domain Name',
  organizationName: 'Organization Name',
  partnerName: 'Partner Name',
  distributorName: 'Parent Name',
  mail: 'Mail',
  Active: 'Active',
  mailboxHidden: 'Mailbox Hidden',
  team: 'Is Team',
  backupSumaries: 'Backup Sumaries',
  productsStatus: 'Products Status',
  lastBackupId: 'Last Backup ID',
  lastBackupAt: 'Last Backup At',
  lastBackupSuccessfulAt: 'Last Successful Backup At',
  lastBackupSuccessSentAt: 'Last Notification Success Backup Sent At',
  nextBackupAt: 'Next Backup At',
  deactivatedSince: 'Deactivated Since',
  deactivatedBy: 'Deactivated By',
  deletedAt: 'Deleted At',
  modifiedAt: 'Modified At',
  modifiedBy: 'Modified By',
  createdAt: 'Created At',
  updatedAt: 'Last Data Updated At',
};
export const MS_GROUP_LAST_BACKUP_ROWS = {
  backupId: 'Last Group Backup ID',
  userBackupId: 'Goup Account ID',
  startTime: 'Start Backup Time',
  endTime: 'End Backup Time',
  status: 'Backup Status',
  backupStorage: 'Backup Storage (in Byte)',
  currentFilesCount: 'Current File Numbers',
  totalFilesCount: 'Total File Numbers',
  retries: 'Retries Time',
  errorMessage: 'Error Message',
  machineIP: 'Run on Machine IP',
  createdAt: 'Created At',
  updatedAt: 'Last Updated At',
};
