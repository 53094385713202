import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPlansFilterListAPI } from '@services/WebApiService';

const getPlansFilterListAsync = createAsyncThunk(
  'plansFilterList/getPlansFilterLists',
  async (paramsList) => {
    try {
      const data = await getPlansFilterListAPI(paramsList);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

const { pending, fulfilled, rejected } = getPlansFilterListAsync;

const getPlansFilterListExtraReducers = {
  [pending]: (state) => {
    state.getPlansFilterListState.fetching = true;
  },
  [rejected]: (state) => {
    state.getPlansFilterListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getPlansFilterListState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getPlansFilterListState.forbiddenState = true;
      state.getPlansFilterListState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getPlansFilterListState.plansFilterListCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getPlansFilterListState.plansFilterList = action?.payload?.data?.data;
      state.getPlansFilterListState.paginationList = JSON.parse(action?.payload?.data?.pagination);
      state.getPlansFilterListState.success = true;
      state.getPlansFilterListState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getPlansFilterListState.error = action.payload.error;
    }
  },
};

export { getPlansFilterListAsync, getPlansFilterListExtraReducers };
