import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  QboSimpleDataTable,
  QboCard,
  QboAlert,
  QboPagination,
  QboCardListItem,
  QboTabs,
  QboFormGroup,
  QboModal,
  QboTypography,
} from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import PartnerFilterSection from '@ui/Sections/Shared/PartnerFilterSection';
import OrganizationFilterSection from '@ui/Sections/Shared/OrganizationFilterSection';
import QboSortByButton from '@ui/Components/QboSortByButton';
import { t } from 'i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  resetPartnerFilterState,
  resetOrganizationFilterState,
} from '@features/application/applicationSlice';
import {
  EMAIL_RESTORE,
  EMAIL_RESTORE_TEXT,
  CALENDAR_RESTORE_TEXT,
  CONTACT_RESTORE_TEXT,
  GDRIVE_RESTORE_TEXT,
  MSCHAT_RESTORE_TEXT,
  MSGROUP_RESTORE_TEXT,
  ONEDRIVE_RESTORE_TEXT,
  SHAREDRIVE_RESTORE_TEXT,
  TASK_RESTORE_TEXT,
  CALENDAR_RESTORE,
  CONTACT_RESTORE,
  GDRIVE_RESTORE,
  MSCHAT_RESTORE,
  MSGROUP_RESTORE,
  ONEDRIVE_RESTORE,
  SHAREDRIVE_RESTORE,
  TASK_RESTORE,
  SHAREPOINT_RESTORE_TEXT,
  SHAREPOINT_RESTORE,
} from '@pages/RestoreStatusPage/pageHooks/constants/emailRestoreConstants';
import useRestoreStatusFetching from './pageHooks/useRestoreStatusFetching';
import { systemStatusItems, buttonStyle } from './pageHooks/constants/emailRestoreConstants';
import {
  ENTRA_ID_RESTORE,
  ENTRA_ID_RESTORE_TEXT,
} from './pageHooks/constants/entraidRestoreConstants';

export default function RestoreStatusPage(props) {
  const { resellerId, organizationOwnerId } = props;
  const dispatch = useDispatch();
  const { restoreStatusListState } = useSelector((state) => state.restoreStatusState);
  const {
    fetching,
    restoreStatusList,
    paginations,
    forbiddenErrorMessage,
    restoreStatusListCorsErrorMessage,
  } = restoreStatusListState;

  // fetching
  const defaultPagination = { pageNumber: 1, pageSize: 10 };
  const [paginationState, setPaginationState] = useState(defaultPagination);

  // filter
  const [onSearch, setOnSearch] = useState(false);
  const defaultFilter = {
    status: '',
    startDate: dayjs().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    date: dayjs(),
    selectedPartnerId: resellerId,
    selectedOrganizationOwnerId: organizationOwnerId,
    selectedParentId: null,
  };
  const [filterState, setFilterState] = useState(defaultFilter);

  // tabs
  const tabNames = [
    EMAIL_RESTORE_TEXT,
    CONTACT_RESTORE_TEXT,
    CALENDAR_RESTORE_TEXT,
    TASK_RESTORE_TEXT,
    ONEDRIVE_RESTORE_TEXT,
    GDRIVE_RESTORE_TEXT,
    SHAREPOINT_RESTORE_TEXT,
    MSGROUP_RESTORE_TEXT,
    SHAREDRIVE_RESTORE_TEXT,
    ENTRA_ID_RESTORE_TEXT,
  ];
  const [restoreType, setResoreType] = useState(EMAIL_RESTORE);
  const [selectTab, setSelectTab] = useState(0);
  const convertToRestoreType = (tabName) => {
    if (tabName === EMAIL_RESTORE_TEXT) {
      return EMAIL_RESTORE.toLowerCase();
    }
    if (tabName === CONTACT_RESTORE_TEXT) {
      return CONTACT_RESTORE.toLowerCase();
    }
    if (tabName === CALENDAR_RESTORE_TEXT) {
      return CALENDAR_RESTORE.toLowerCase();
    }
    if (tabName === TASK_RESTORE_TEXT) {
      return TASK_RESTORE.toLowerCase();
    }
    if (tabName === ONEDRIVE_RESTORE_TEXT) {
      return ONEDRIVE_RESTORE.toLowerCase();
    }
    if (tabName === GDRIVE_RESTORE_TEXT) {
      return GDRIVE_RESTORE.toLowerCase();
    }
    if (tabName === SHAREPOINT_RESTORE_TEXT) {
      return SHAREPOINT_RESTORE.toLowerCase();
    }
    if (tabName === MSGROUP_RESTORE_TEXT) {
      return MSGROUP_RESTORE.toLowerCase();
    }
    if (tabName === MSCHAT_RESTORE_TEXT) {
      return MSCHAT_RESTORE.toLowerCase();
    }
    if (tabName === SHAREDRIVE_RESTORE_TEXT) {
      return SHAREDRIVE_RESTORE.toLowerCase();
    }
    if (tabName === ENTRA_ID_RESTORE_TEXT) {
      return ENTRA_ID_RESTORE.toLowerCase();
    }
    return tabName.toLowerCase();
  };
  const handleOnTabClick = (e, newValue) => {
    setPaginationState(defaultPagination);
    setFilterState(defaultFilter);
    setSelectTab(newValue);
    const newRestoreType = tabNames[newValue];
    setResoreType(convertToRestoreType(newRestoreType));
  };

  const {
    handleFetchRestoreStatusListAsync,
    tableColumns,
    showMesageDialog,
    setShowMesageDialog,
    messageDialog,
  } = useRestoreStatusFetching(
    defaultFilter,
    setFilterState,
    defaultPagination,
    setPaginationState
  );

  const fetchHandler = () => {
    setOnSearch(true);
    if (!fetching) {
      handleFetchRestoreStatusListAsync(
        paginationState.pageNumber,
        paginationState.pageSize,
        filterState.selectedPartnerId,
        filterState.selectedOrganizationOwnerId,
        restoreType,
        filterState
      );
      setOnSearch(false);
    }
  };

  const handleChangePage = (newPage) => {
    setPaginationState({ pageNumber: newPage, pageSize: paginationState.pageSize });
  };

  const handleChangePageSize = (newPageSize) => {
    setPaginationState({ pageNumber: 1, pageSize: newPageSize });
  };

  const handleFilterdChange = (value, key) => {
    const newFilter = { ...filterState, [key]: value };
    setFilterState(newFilter);
  };

  const handleOnSearchClick = async (e) => {
    e.preventDefault();
    setPaginationState(defaultPagination);
    setOnSearch(true);
  };

  const handleOnResetButtonClick = (e) => {
    setPaginationState(defaultPagination);
    setFilterState(defaultFilter);
    setOnSearch(true);
    dispatch(resetPartnerFilterState());
    dispatch(resetOrganizationFilterState());
  };

  const handleOnChangeDate = (newDate) => {
    const startDate = newDate.startOf('month').format('YYYY-MM-DDTHH:mm:ss');
    const endDate = newDate.endOf('month').format('YYYY-MM-DDTHH:mm:ss');

    const newFilter = { ...filterState, startDate, endDate };
    setFilterState(newFilter);
  };

  useEffect(() => {
    if (onSearch) {
      fetchHandler();
    }
  }, [onSearch]);

  useEffect(() => {
    fetchHandler();
  }, [paginationState.pageNumber, paginationState.pageSize]);

  useEffect(() => {
    setPaginationState(defaultPagination);
    fetchHandler();
  }, [restoreType]);

  return (
    <>
      <HeaderPageBlock>Restore Status</HeaderPageBlock>
      {restoreStatusListCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {restoreStatusListCorsErrorMessage}
        </QboAlert>
      )}
      <QboTabs
        noPadding
        isMainWrappe
        tabIndex={selectTab}
        title={tabNames}
        onChange={handleOnTabClick}
        sx={{ textTransform: 'capitalize', marginTop: 2 }}
      />
      <QboCard isMainWrapper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Period"
            views={['year', 'month']}
            value={filterState.date}
            defaultValue={dayjs()}
            maxDate={dayjs()}
            minDate={dayjs().subtract(2, 'year')}
            onChange={(value) => {
              handleOnChangeDate(value);
            }}
            sx={{ marginBottom: 2 }}
          />
        </LocalizationProvider>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <div>
            <QboSortByButton
              defaultValue="All"
              marginRightValue={2}
              value={filterState.status}
              buttonWidth={220}
              startAdornmentTextWidth={70}
              startAdornmentText="Status"
              handleChange={(e) => {
                handleFilterdChange(e.target.value, 'status');
              }}
              menuItemList={systemStatusItems}
            />
            {!resellerId && !organizationOwnerId && (
              <PartnerFilterSection
                parentId={filterState.selectedParentId}
                label="Partners"
                inputSize={180}
                handleChange={(value) => {
                  handleFilterdChange(parseInt(value, 10), 'selectedPartnerId');
                }}
              />
            )}
            {!organizationOwnerId && (
              <OrganizationFilterSection
                partnerId={filterState.selectedPartnerId}
                label="Organizations"
                inputSize={180}
                handleChange={(value) => {
                  handleFilterdChange(value, 'selectedOrganizationOwnerId');
                }}
              />
            )}
          </div>
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <>
          {(restoreStatusList?.length || fetching) && (
            <QboSimpleDataTable
              sx={{ width: '100%' }}
              rows={restoreStatusList}
              header={tableColumns(restoreType)}
              loading={fetching}
            />
          )}
          {(restoreStatusList?.length || fetching) && (
            <QboCardListItem
              justifyContent="right"
              alignItems="center"
              style={{ paddingRight: '1.5em' }}
              rightContent={
                <QboPagination
                  hasNextLink={paginations?.HasNext}
                  hasPrevLink={paginations?.HasPrevious}
                  onClickPrevLink={() => {
                    const newPageNumber = paginationState.pageNumber - 1;
                    handleChangePage(newPageNumber);
                  }}
                  onClickNextLink={() => {
                    const newPageNumber = paginationState.pageNumber + 1;
                    handleChangePage(newPageNumber);
                  }}
                  onChangePageNumber={handleChangePageSize}
                  pageNumber={paginationState.pageSize}
                  paginationLabel="Page size"
                />
              }
            />
          )}

          {!restoreStatusList?.length && !fetching && (
            <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
          )}

          <QboModal
            classNameStyle="Qbo_model_error_message"
            title={messageDialog.title}
            showCloseIcon
            open={showMesageDialog}
            onClose={() => setShowMesageDialog(false)}>
            <QboTypography wrap={!false}>{messageDialog.message}</QboTypography>
          </QboModal>
        </>
      </QboCard>
    </>
  );
}

RestoreStatusPage.propTypes = {
  resellerId: PropTypes.number,
  organizationOwnerId: PropTypes.number,
};

RestoreStatusPage.defaultProps = {
  resellerId: null,
  organizationOwnerId: null,
};
