import React from 'react';
import PropTypes from 'prop-types';
import RunBackupEmailToolSection from '@ui/Sections/Shared/tools/RunBackupEmailToolSection';
import ExportEmailBackupSection from '../Shared/tools/ExportEmailBackupSection';
import BulkMailboxDeactivationToolSection from '../Shared/tools/BulkMailboxDeactivationToolSection';

export default function UserEmailBackupToolsSection({
  list,
  selectedIds,
  onCloseModal,
  fetchParams,
  hasResults,
}) {
  return (
    <div style={{ display: 'flex', margin: '20px 40px', justifyContent: 'flex-end', gap: '20px' }}>
      <RunBackupEmailToolSection
        list={list}
        selectedIds={selectedIds}
        onCloseModal={onCloseModal}
      />
      <BulkMailboxDeactivationToolSection
        list={list}
        selectedIds={selectedIds}
        onCloseModal={onCloseModal}
      />
      <ExportEmailBackupSection fetchParams={fetchParams} hasResults={hasResults} />
    </div>
  );
}

UserEmailBackupToolsSection.propTypes = {
  list: PropTypes.array,
  selectedIds: PropTypes.array,
  onCloseModal: PropTypes.func,
  fetchParams: PropTypes.object,
  hasResults: PropTypes.bool,
};

UserEmailBackupToolsSection.defaultProps = {
  list: [],
  selectedIds: [],
  onCloseModal: () => {},
  fetchParams: null,
  hasResults: false,
};
