import { createSlice } from '@reduxjs/toolkit';

import {
  getGwsSharedDrivesTenantAsync,
  getGwsSharedDrivesTenantReducers,
} from './serviceActions/getGwsSharedDrivesTenantAsync';

const initialState = {
  getGwsSharedDrivesTenantState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    gwsSharedDrivesTenantInformation: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    gwsSharedDrivesTenantCorsErrorMessage: null,
  },
};

const gwsSharedDrivesTenantSlice = createSlice({
  name: 'GwsSharedDrivesTenant',
  initialState,
  reducers: {
    resetGwsSharedDrivesTenantState(state) {
      state.getGwsSharedDrivesTenantState = initialState.getGwsSharedDrivesTenantState;
    },
    resetGwsSharedDrivesTenantStatusCodeState(state) {
      state.getGwsSharedDrivesTenantState.statusCode =
        initialState.getGwsSharedDrivesTenantState.statusCode;
    },
  },
  extraReducers: {
    ...getGwsSharedDrivesTenantReducers,
  },
});

export { getGwsSharedDrivesTenantAsync };
export const { resetGwsSharedDrivesTenantState, resetGwsSharedDrivesTenantStatusCodeState } =
  gwsSharedDrivesTenantSlice.actions;
export default gwsSharedDrivesTenantSlice.reducer;
