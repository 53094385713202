import React, { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '@hooks/useLocalStorage';
import useStepper from '@pages/sharedHooks/useStepper';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import {
  updateSpecialPricingAsync,
  resetupdateSpecialPricingState,
} from '@features/specialPricing/updateSpecialPricingSlice';
import { constant } from '@config/BaseSetting';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import { showFixedAlert, setPartnerFilterState } from '@features/application/applicationSlice';
import useForm from './useForm';

export default function usePricingService() {
  // Dependencies Hooks
  const dispatch = useDispatch();
  const form = useForm();
  const stepper = { ...useStepper() };
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { updateSpecialPricingState } = useSelector((state) => state.updateSpecialPricing);
  const { t } = useTranslation();

  const [showRequestModal, setShowRequestModal] = useState(false);

  const [validList, setValidList] = useState([]);
  const [inValidList, setInValidList] = useState([]);

  stepper.steps = ['1', '2'];
  stepper.stepIcons = {
    1: <SettingsIcon />,
    2: <VideoLabelIcon />,
  };

  const handleUpdateSpecialPricing = (parameters) => {
    dispatch(
      updateSpecialPricingAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const onSubmitForm = (e) => {
    if (form.isValid) {
      dispatch(
        setPartnerFilterState({
          searchState: '',
          selectedPartner: '',
        })
      );
      handleUpdateSpecialPricing([
        {
          'support-ticket': form.attributes.supportTicketAttributes.value,
          description: form.attributes.descriptionAttributes.value,
          'escalation-ticket': form.attributes.escalationTicketAttributes.value,
          'reseller-id': form.attributes.partnerIdAttributes.value,
          'special-pricing-type': form.attributes.requestTypeAttributes.value,
          'archive-price': form.attributes.newArchivePriceAttributes.value,
          'backup-price': form.attributes.newBackupPriceAttributes.value,
          'qbo-price': form.attributes.newQboPriceAttributes.value,
          'nfp-archive-price': form.attributes.newNfpArchivePriceAttributes.value,
          'nfp-backup-price': form.attributes.newNfpBackupPriceAttributes.value,
          'plan-id': form.attributes.planIdAttributes.value,
          'new-price': form.attributes.newPlanPriceAttributes.value,
          'organization-owner-ids': validList.map((l) => l.organizationOwnerId).toString(),
        },
      ]);
    }
  };

  const onStepNext = (e) => {
    form.onValidateData(e);
    if (form.isValid) stepper.handleNext();
  };

  useEffect(() => {
    if (form.isValid) stepper.handleNext();
  }, [form.isValid]);

  const onStepBack = () => {
    dispatch(
      setPartnerFilterState({
        searchState: '',
        selectedPartner: '',
      })
    );
    stepper.handleBack();
    form.invalidateForm();
  };

  const resetNewRequestModal = () => {
    onStepBack();
  };

  const closeSetupModal = () => {
    resetNewRequestModal();
    setShowRequestModal(false);
  };

  const openSetupModal = () => {
    setShowRequestModal(true);
    stepper.handleReset();
    form.resetForm();
  };

  useEffect(() => {
    if (updateSpecialPricingState.status === 200) {
      form.resetForm();
      dispatch(resetupdateSpecialPricingState());
      if (updateSpecialPricingState.serviceType) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': updateSpecialPricingState.serviceType,
        });

        dispatch(
          showFixedAlert({
            descriptionHtml: t('plans_list_page.success_submitted_with_link', {
              link,
            }),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('plans_list_page.success_submitted'),
          })
        );
      }
      closeSetupModal();
    }
  }, [updateSpecialPricingState.status]);

  return {
    form,
    stepper,
    openSetupModal,
    closeSetupModal,
    showRequestModal,
    onSubmitForm,
    onStepNext,
    onStepBack,
    validList,
    setValidList,
    inValidList,
    setInValidList,
  };
}
