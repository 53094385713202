import { createAsyncThunk } from '@reduxjs/toolkit';
import { restoreStatusListAPI } from '@services/WebApiService';

const fetchRestoreStatusListAsync = createAsyncThunk(
  'RestoreStatusList/getRestoreStatusLists',
  async (paramsList) => {
    try {
      const data = await restoreStatusListAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = fetchRestoreStatusListAsync;

const getRestoreStatusListExtraReducers = {
  [pending]: (state) => {
    state.restoreStatusListState.fetching = true;
  },
  [rejected]: (state) => {
    state.restoreStatusListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.restoreStatusListState.fetching = false;

    if (action?.payload?.status === 403) {
      state.restoreStatusListState.forbiddenState = true;
      state.restoreStatusListState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.restoreStatusListState.UsersListCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.restoreStatusListState.restoreStatusList = action?.payload?.data?.data;
      state.restoreStatusListState.paginations = JSON.parse(action?.payload?.data?.pagination);
      state.restoreStatusListState.success = true;
      state.restoreStatusListState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.restoreStatusListState.error = action.payload.error;
    }
  },
};

export { fetchRestoreStatusListAsync, getRestoreStatusListExtraReducers };
