import { organizationInformationPath, resellersListPath } from '@config/Routes/WebClientRoutes';

export default function useDataService() {
  const m365SitesTenantHeaders = [
    { id: 1, label: 'M365 ID', columnName: 'id', sx: { paddingLeft: 2 } },
    {
      id: 2,
      label: 'Site Name',
      columnName: 'name',
      sx: { paddingLeft: 2 },
    },
    {
      id: 3,
      label: 'Display Name',
      columnName: 'display_name',
      sx: { paddingLeft: 2 },
    },
    {
      id: 5,
      label: 'Organization Owner ID',
      columnName: 'organizationOwnerId',
    },
    {
      id: 6,
      label: 'Organization Name',
      columnName: 'orgName',
    },
    {
      id: 7,
      label: 'Partner Name',
      columnName: 'tenantPartnerName',
    },
    {
      id: 8,
      label: 'Parent Name',
      columnName: 'tenantParent',
    },
    {
      id: 9,
      label: 'Status',
      columnName: 'active',
      sx: { paddingLeft: 2 },
    },
    {
      id: 10,
      label: 'Order',
      columnName: 'order',
      sx: { paddingLeft: 2 },
    },
  ];

  const clickableColumnList = [
    {
      id: 1,
      column: 'tenantPartnerName',
      path: resellersListPath,
    },
    {
      id: 2,
      column: 'orgName',
      path: organizationInformationPath,
      tab: 0,
    },
    {
      id: 3,
      column: 'tenantParent',
      path: resellersListPath,
    },
  ];

  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const activeStatus = [
    { id: 1, value: 'active', title: 'Active' },
    { id: 2, value: 'available', title: 'Available' },
    { id: 3, value: 'blacklist', title: 'Blacklist' },
  ];

  const filterStates = {
    advanceClick: false,
    searchFieldValue: '',
    matchText: '',
    resetClicked: false,
  };

  const initialFilterState = [{ field: 'filter_active', value: '' }];

  return {
    m365SitesTenantHeaders,
    clickableColumnList,
    buttonStyle,
    activeStatus,
    filterStates,
    initialFilterState,
  };
}
