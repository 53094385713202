import { createAsyncThunk } from '@reduxjs/toolkit';
import { exportUserBackupReportAPI } from '@services/WebApiService';

const exportUserBackupReportAsync = createAsyncThunk(
  'userDiscrepancyReport/exportUserBackupReport',
  async (paramsList) => {
    try {
      const data = await exportUserBackupReportAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = exportUserBackupReportAsync;

const exportUserBackupReportExtraReducers = {
  [pending]: (state) => {
    state.exportUserBackupReportState.fetching = true;
  },
  [rejected]: (state) => {
    state.exportUserBackupReportState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.exportUserBackupReportState.fetching = false;

    if (action?.payload?.status === 403) {
      state.exportUserBackupReportState.forbiddenState = true;
      state.exportUserBackupReportState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.exportUserBackupReportState.extportUserBackupReportCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.exportUserBackupReportState.data = action?.payload?.data;
      state.exportUserBackupReportState.contentType = action?.payload?.headers['content-type'];
      state.exportUserBackupReportState.success = true;
      state.exportUserBackupReportState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.exportUserBackupReportState.error = action.payload.error;
    }
  },
};

export { exportUserBackupReportAsync, exportUserBackupReportExtraReducers };
