export const ENTRA_ID_RESTORE = 'entraid';
export const ENTRA_ID_RESTORE_TEXT = 'Entra';
export const ENTRA_ID_RESTORE_COLUMNS = [
  { label: 'ID', columnName: 'restoreId' },
  { label: 'Entra Tenant ID', columnName: 'entraIdTenantId' },
  {
    label: 'Name',
    columnName: 'name',
    size: 'sm',
  },
  {
    label: 'Initial Domain',
    columnName: 'initialDomain',
    size: 'sm',
  },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
  },
  { label: 'Restore Status', columnName: 'status', size: 'sm' },
  { label: 'Start Restore Time', columnName: 'startTime', size: 'sm' },
  { label: 'End Restore Time', columnName: 'endTime', size: 'sm' },
  { label: 'Current Items Numbers', columnName: 'currentObjectsCount', size: 'sm' },
  { label: 'Total Items Numbers', columnName: 'totalObjectsCount', size: 'sm' },
  { label: 'Query Params', columnName: 'queryParams', size: 'sm' },
  { label: 'Retries Time', columnName: 'retries', size: 'sm' },
  { label: 'Error Messages', columnName: 'errorMessage', size: 'sm' },
  { label: 'Skipped Items', columnName: 'skippedItems', size: 'sm' },
  { label: 'Notes', columnName: 'notes', size: 'sm' },
  { label: 'Run on Machine IP', columnName: 'machineIP', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
];

export const systemStatusItems = [
  {
    id: 1,
    value: 'completed',
    title: 'Completed',
  },
  {
    id: 2,
    value: 'running',
    title: 'Running',
  },
  {
    id: 3,
    value: 'error',
    title: 'Error',
  },
];

export const buttonStyle = {
  height: 36,
  backgroundColor: '#a5a5a5',
  textDecoration: 'none',
  color: 'black',
  '&:hover': {
    backgroundColor: '#a5a5a5',
  },
  textTransform: 'unset',
  fontSize: 14,
};
