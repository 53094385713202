import React, { useState } from 'react';
import {
  QboAdvanceTable,
  QboCard,
  QboFormGroup,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import { Button } from '@mui/material';
import QboSortByButton from '@ui/Components/QboSortByButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import useDataService from './pagehooks/useDataService';
import useEntraIdTenantService from './pagehooks/useEntraIdTenantService';

export default function EntraIdTenantPage({ organizationOwnerId }) {
  const { t } = useTranslation();
  const [showAdFilter, setShowAdFilter] = useState(false);
  const { entraIdTenantState } = useSelector((s) => s.entraIdTenant);
  const {
    entraIdTenantHeaders,
    buttonStyle,
    clickableColumn,
    sortColumnNames,
    completedSetupValues,
    statusValues,
  } = useDataService();
  const {
    filterState,
    matchText,
    paginationState,
    setPaginationState,
    handleSubmit,
    handleReset,
    handleTextFieldChange,
    handleAdFilterChange,
    handleSortColumnChange,
  } = useEntraIdTenantService(organizationOwnerId);

  return (
    <>
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={handleSubmit}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={handleReset}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <QboTextField
            id="fullTextSearch"
            value={filterState.textFieldValue}
            isWidthSetExplicit
            placeholder="Id/Name/Initial Domain"
            inputProps={{ maxLength: 225 }}
            onChange={(e) => {
              handleTextFieldChange(e.target.value);
            }}
          />

          <div style={{ marginTop: 8 }}>
            <Button
              disableRipple
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => setShowAdFilter(!showAdFilter)}>
              {showAdFilter
                ? t('partner_details_page.button.hide_advance_search')
                : t('partner_details_page.button.show_advance_search')}
            </Button>
          </div>

          {showAdFilter && (
            <>
              <div style={{ display: 'flex', gap: '3rem', alignItems: 'center' }}>
                <QboSortByButton
                  defaultValue="All"
                  marginTopValue={2}
                  value={filterState.adFilter.status ?? ''}
                  buttonWidth={220}
                  startAdornmentTextWidth={70}
                  startAdornmentText="Status: "
                  handleChange={(e) => handleAdFilterChange('status', e.target.value)}
                  menuItemList={statusValues}
                />
                <QboSortByButton
                  defaultValue="All"
                  marginTopValue={2}
                  value={filterState.adFilter.completedSetup ?? ''}
                  buttonWidth={240}
                  startAdornmentTextWidth={220}
                  startAdornmentText="Setup Completed:"
                  handleChange={(e) => handleAdFilterChange('completedSetup', e.target.value)}
                  menuItemList={completedSetupValues}
                />
                <QboTextField
                  id="tenantId"
                  value={filterState.adFilter.tenantId ?? ''}
                  placeholder="Tenant Id"
                  inputProps={{
                    maxLength: 225,
                    style: {
                      width: '193px',
                      height: '23px',
                      marginTop: '2rem',
                      marginRight: '2rem',
                    },
                  }}
                  onChange={(e) => handleAdFilterChange('tenantId', e.target.value)}
                />
              </div>
              <div>
                <QboSortByButton
                  defaultValue="None"
                  marginTopValue={2}
                  value={filterState.selectedSortColumn}
                  buttonWidth={220}
                  startAdornmentTextWidth={70}
                  startAdornmentText="Sort by:"
                  handleChange={(e) => handleSortColumnChange(e.target.value)}
                  menuItemList={sortColumnNames}
                />
              </div>
            </>
          )}
        </QboFormGroup>
      </QboCard>
      <QboCard>
        {matchText ? (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'pre-wrap',
            }}>
            {matchText}
          </QboTypography>
        ) : null}
        {((entraIdTenantState.entraIdTenantInformation &&
          entraIdTenantState.entraIdTenantInformation.length > 0) ||
          entraIdTenantState.fetching) && (
          <QboAdvanceTable
            isLoading={entraIdTenantState.fetching}
            enableTopToolBar={false}
            headers={entraIdTenantHeaders}
            rows={entraIdTenantState.entraIdTenantInformation}
            rowsCount={Number(paginationState?.TotalCount || 0)}
            pagination={paginationState}
            setPagination={setPaginationState}
            enableColumnClickable={!false}
            columnsClickableList={clickableColumn}
          />
        )}
        {(!entraIdTenantState.entraIdTenantInformation ||
          entraIdTenantState.entraIdTenantInformation.length === 0) &&
          !entraIdTenantState.fetching && <NoStatusSection />}
      </QboCard>
    </>
  );
}

EntraIdTenantPage.propTypes = {
  organizationOwnerId: PropTypes.number,
};

EntraIdTenantPage.defaultProps = {
  organizationOwnerId: null,
};
