import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { getInvoicesListAsync } from '@features/invoice/getInvoicesListSlice';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const initialPagination = { pageIndex: 0, pageSize: 10 };

export default function useInvoicesService(externalResellerId) {
  const { getInvoicesListState } = useSelector((state) => state.getInvoicesList);
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();
  const [date, setDate] = useState(dayjs());
  const [resellerId, setResellerId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [syncedToXero, setSyncedToXero] = useState('');
  const [pagination, setPagination] = useState(initialPagination);
  const [fetchFlag, setFetchFlag] = useState(true);
  const [matchText, setMatchText] = useState('');

  const getSearchId = () => {
    if (/^\d+$/.test(searchText)) {
      return parseInt(searchText, 10);
    }
    return 0;
  };

  const getFilterArray = () => {
    return [
      { field: 'filter_syncedToXero', value: syncedToXero },
      { field: 'filter_startDate', value: date.startOf('month').format('YYYY-MM-DDTHH:mm:ss') },
      { field: 'filter_endDate', value: date.endOf('month').format('YYYY-MM-DDTHH:mm:ss') },
    ];
  };

  const updateMatchText = () => {
    let currentMatchText = '';
    let first = true;
    const searchId = getSearchId();
    if (searchId) {
      currentMatchText += 'Matching';
      first = false;
      currentMatchText += ` ID = ${searchId}`;
    }
    if (searchText) {
      if (first) {
        currentMatchText += 'Matching';
        first = false;
      }
      currentMatchText += ` Partner Name LIKE %${searchText}% Xero ID LIKE %${searchText}%`;
    }
    if (resellerId) {
      if (first) {
        currentMatchText += 'Matching';
        first = false;
      }
      currentMatchText += ` Partner ID = ${resellerId}`;
    }
    if (syncedToXero) {
      if (first) {
        currentMatchText += 'Matching';
        first = false;
      }
      currentMatchText += ` Synced to Xero = ${syncedToXero}`;
    }

    setMatchText(currentMatchText);
  };

  const fetchInvoices = () => {
    updateMatchText();
    dispatch(
      getInvoicesListAsync({
        accessToken,
        reset: true,
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        fullTextSearch: searchText,
        searchById: getSearchId(),
        resellerId: externalResellerId || resellerId,
        filter: getFilterArray(),
        orderBy: orderBy || 'CreatedAt',
      })
    );
  };

  useEffect(() => {
    if (fetchFlag) {
      if (!getInvoicesListState.fetching) {
        fetchInvoices();
      }
      setFetchFlag(false);
    }
  }, [fetchFlag]);

  const handleChangeDate = setDate;

  const handleChangeSyncedToXero = (e) => setSyncedToXero(e.target.value);

  const handleChangeResellerId = (v) => setResellerId(v);

  const handleChangeSearchText = (e) => setSearchText(e.target.value);

  const handleChangeOrderBy = (e) => setOrderBy(e.target.value);

  const handleChangePagination = (newPagination) => {
    setPagination(newPagination);
    setFetchFlag(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPagination(initialPagination);
    setFetchFlag(true);
  };

  const handleReset = () => {
    setSyncedToXero('');
    setDate(dayjs());
    setResellerId('');
    setSearchText('');
    setOrderBy('');
    setPagination(initialPagination);
    setFetchFlag(true);
  };

  return {
    date,
    handleChangeDate,
    resellerId,
    handleChangeResellerId,
    syncedToXero,
    handleChangeSyncedToXero,
    searchText,
    handleChangeSearchText,
    orderBy,
    handleChangeOrderBy,
    pagination,
    handleChangePagination,
    handleSubmit,
    handleReset,
    matchText,
    listState: getInvoicesListState,
  };
}
