import {
  transferSubresellerPagePath,
  transferSubscriptionPagePath,
  mailBoxChangePath,
  viewExcludedMailBoxPath,
  dashboardPath,
  mailBoxSyncPath,
  viewAutoDiscoveryPath,
  dataIngestionPath,
  dataIngestionListPath,
  bulkPurgePath,
  bulkPurgeListPath,
  domainBackupsPath,
  transferPath,
  reportsPagePath,
  listReportsPagePath,
  seatUsageReportsPagePath,
  purgeExternalAccountPath,
  purgeSharedDrivePath,
  showPartnerListPath,
  showOrganizationListPath,
  resellersListPath,
  numberOfUsersListPath,
  numberOfSubResellersListPath,
  numberOfOrganizationListPath,
  showPlansListPath,
  showUsersListPath,
  seatCountChangePath,
  monthlyBackupReportsPagePath,
  recreateBackupAdminPath,
  viewTenantAuthErrorsPath,
  organizationInformationPath,
  switchSPATypePath,
  m365TenantListPath,
  gwsTenantListPath,
  purgeM365TenantPath,
  userBackupInfoPath,
  m365TenantDetailPath,
  purgeGWSTenantPath,
  userBackupDetailPath,
  changeBackupAdminPath,
  gwsTenantDetailPath,
  hexTenantListPath,
  hexTenantDetailPath,
  mailBoxChangeDetailsPath,
  switchCredentialsPath,
  switchCredentialsDetailPath,
  showOrganizationUsersListPath,
  toolsRequestStatusPath,
  backupStatusesPath,
  downloadStatusesPath,
  restoreStatusesPath,
  invoicesListPath,
  sorMonitoringPath,
  sorMonitoringDetailPath,
} from '@config/Routes/WebClientRoutes';
import { useSelector } from 'react-redux';

export default function useFeatureFlag() {
  const { getFeatureFlagState } = useSelector((state) => state.flags);
  const { featureFlagList } = getFeatureFlagState;

  const featureFlags = {
    RunLicenseChecker: 'RunLicenseChecker',
    RunBackup: 'RunBackup',
    UpdateEnforceSSO: 'UpdateEnforceSSO',
    RunExportOrganizationInfo: 'RunExportOrganizationInfo',
    RunExportM365TenantInfo: 'RunExportM365TenantInfo',
    RunExportEmailBackupInfo: 'RunExportEmailBackupInfo',
    InvalidateServiceToken: 'InvalidateServiceToken',
    RunAutodiscover: 'RunAutodiscover',
    TenantSizeReport: 'TenantSizeReport',
    BulkCreateOrganization: 'BulkCreateOrganization',
    BulkCreateSubReseller: 'BulkCreateSubReseller',
    UserDiscrepancyReport: 'UserDiscrepancyReport',
    InvoicesList: 'InvoicesList',
    XeroSync: 'XeroSync',
    UserBackupReport: 'UserBackupReport',
    EditResellerSettings: 'EditResellerSettings',
    BulkMailboxDeactivation: 'BulkMailboxDeactivation',
    EntraId: 'EntraId',
  };

  const getFeatureFlagStateHandler = () => {
    const data = [];

    featureFlagList?.forEach((item) => {
      const { flagName, isActive } = item;

      switch (flagName) {
        case 'OrgTransfer':
          data.push({ path: transferSubresellerPagePath, isActive });
          break;

        case 'ResellerTransfer':
          data.push({ path: transferSubscriptionPagePath, isActive });
          data.push({ path: transferPath, isActive });
          break;

        case 'MailboxChange':
          data.push({ path: mailBoxChangePath, isActive });
          data.push({ path: mailBoxChangeDetailsPath, isActive });
          break;

        case 'MailboxSync':
          data.push({ path: mailBoxSyncPath, isActive });
          break;

        case 'ExcludedMailbox':
          data.push({ path: viewExcludedMailBoxPath, isActive });
          break;

        case 'CheckAutodiscover':
          data.push({ path: viewAutoDiscoveryPath, isActive });
          break;
        case 'Ingestion':
          data.push({ path: dataIngestionPath, isActive });
          data.push({ path: dataIngestionListPath, isActive });
          data.push({ path: reportsPagePath, isActive });
          data.push({ path: listReportsPagePath, isActive });
          break;
        case 'PurgeMailAccount':
          data.push({ path: bulkPurgePath, isActive });
          data.push({ path: bulkPurgeListPath, isActive });
          break;
        case 'InitiateDomainO365':
          data.push({ path: domainBackupsPath, isActive });
          break;
        case 'SeatUsageReport':
          data.push({ path: seatUsageReportsPagePath, isActive });
          break;
        case 'PurgeExternalAccount':
          data.push({ path: purgeExternalAccountPath, isActive });
          break;
        case 'PurgeSharedDrive':
          data.push({ path: purgeSharedDrivePath, isActive });
          break;
        case 'PartnerDetails':
          data.push({ path: showPartnerListPath, isActive });
          data.push({ path: resellersListPath, isActive });
          data.push({ path: numberOfUsersListPath, isActive });
          data.push({ path: numberOfSubResellersListPath, isActive });
          data.push({ path: numberOfOrganizationListPath, isActive });
          break;
        case 'OrganizationDetails':
          data.push({ path: showOrganizationListPath, isActive });
          data.push({ path: organizationInformationPath, isActive });
          break;
        case 'PlansList':
          data.push({ path: showPlansListPath, isActive });
          break;
        case 'SeatCountChange':
          data.push({ path: seatCountChangePath, isActive });
          break;
        case 'UsersList':
          data.push({ path: showUsersListPath, isActive });
          data.push({ path: showOrganizationUsersListPath, isActive });
          break;
        case 'TenantAuthErrors':
          data.push({ path: viewTenantAuthErrorsPath, isActive });
          break;
        case 'TenantsList':
          data.push({ path: m365TenantListPath, isActive });
          data.push({ path: m365TenantDetailPath, isActive });
          data.push({ path: gwsTenantListPath, isActive });
          data.push({ path: gwsTenantDetailPath, isActive });
          data.push({ path: hexTenantListPath, isActive });
          data.push({ path: hexTenantDetailPath, isActive });
          break;
        case 'MonthlyBackupReport':
          data.push({ path: monthlyBackupReportsPagePath, isActive });
          break;
        case 'SwitchSPAType':
          data.push({ path: switchSPATypePath, isActive });
          break;
        case 'RecreateBackupAdmin':
          data.push({ path: recreateBackupAdminPath, isActive });
          break;
        case 'PurgeM365Credential':
          data.push({ path: purgeM365TenantPath, isActive });
          break;
        case 'UserBackupInfo':
          data.push({ path: userBackupInfoPath, isActive });
          data.push({ path: userBackupDetailPath, isActive });
          break;
        case 'PurgeGWSCredential':
          data.push({ path: purgeGWSTenantPath, isActive });
          break;
        case 'ChangeBackupAdmin':
          data.push({ path: changeBackupAdminPath, isActive });
          break;
        case 'SwitchCredential':
          data.push({ path: switchCredentialsPath, isActive });
          data.push({ path: switchCredentialsDetailPath, isActive });
          break;
        case 'ToolsRequestHistory':
          data.push({ path: toolsRequestStatusPath, isActive: true });
          break;
        case 'BackupStatus':
          data.push({ path: backupStatusesPath, isActive });
          break;
        case 'DownloadStatus':
          data.push({ path: downloadStatusesPath, isActive });
          break;
        case 'RestoreStatus':
          data.push({ path: restoreStatusesPath, isActive });
          break;
        case 'InvoicesList':
          data.push({ path: invoicesListPath, isActive });
          break;
        case 'SORMonitoring':
          data.push({ path: sorMonitoringPath, isActive: true });
          data.push({ path: sorMonitoringDetailPath, isActive: true });
          break;
        default:
          data.push({ path: dashboardPath, isActive: true });
      }
    });
    return data;
  };

  const hasPermission = (flag) => {
    try {
      return featureFlagList.find((f) => f.flagName === flag).isActive;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  return { getFeatureFlagStateHandler, featureFlags, hasPermission };
}
