import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  QboSimpleDataTable,
  QboCard,
  QboAlert,
  QboPagination,
  QboCardListItem,
  QboFormGroup,
  QboModal,
  QboTypography,
} from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import QboSortByButton from '@ui/Components/QboSortByButton';
import { t } from 'i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  resetPartnerFilterState,
  resetOrganizationFilterState,
} from '@features/application/applicationSlice';
import useSORMonitoringFetching from './pageHooks/useSORMonitoringFetching';
import { systemStatusItems } from './pageHooks/constants/sorMonitoringConstants';

export default function SORMonitorPage() {
  const dispatch = useDispatch();
  const { sorMonitoringListState } = useSelector((state) => state.sorMonitoring);
  const {
    fetching,
    sorMonitoringList,
    paginations,
    forbiddenErrorMessage,
    sorMonitoringListCorsErrorMessage,
  } = sorMonitoringListState;

  // fetching
  const defaultPagination = { pageNumber: 1, pageSize: 10 };
  const [paginationState, setPaginationState] = useState(defaultPagination);

  // filter
  const [onSearch, setOnSearch] = useState(false);
  const defaultFilter = {
    status: '',
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
    date: dayjs(),
  };
  const [filterState, setFilterState] = useState(defaultFilter);

  const {
    handleFetchSORMonitoringListAsync,
    tableColumns,
    showMesageDialog,
    setShowMesageDialog,
    messageDialog,
  } = useSORMonitoringFetching(
    defaultFilter,
    setFilterState,
    defaultPagination,
    setPaginationState
  );

  const fetchHandler = () => {
    setOnSearch(true);
    if (!fetching) {
      handleFetchSORMonitoringListAsync(
        paginationState.pageNumber,
        paginationState.pageSize,
        filterState
      );
      setOnSearch(false);
    }
  };

  const handleChangePage = (newPage) => {
    setPaginationState({ pageNumber: newPage, pageSize: paginationState.pageSize });
  };

  const handleChangePageSize = (newPageSize) => {
    setPaginationState({ pageNumber: 1, pageSize: newPageSize });
  };

  const handleFilterdChange = (value, key) => {
    const newFilter = { ...filterState, [key]: value };
    setFilterState(newFilter);
  };

  const handleOnSearchClick = async (e) => {
    e.preventDefault();
    setPaginationState(defaultPagination);
    setOnSearch(true);
  };

  const handleOnResetButtonClick = (e) => {
    setPaginationState(defaultPagination);
    setFilterState(defaultFilter);
    setOnSearch(true);
    dispatch(resetPartnerFilterState());
    dispatch(resetOrganizationFilterState());
  };

  const handleOnChangeDate = (newDate) => {
    const startDate = newDate.startOf('month').format('YYYY-MM-DD');
    const endDate = newDate.endOf('month').format('YYYY-MM-DD');

    const newFilter = { ...filterState, startDate, endDate };
    setFilterState(newFilter);
  };

  useEffect(() => {
    if (onSearch) {
      fetchHandler();
    }
  }, [onSearch]);

  useEffect(() => {
    fetchHandler();
  }, [paginationState.pageNumber, paginationState.pageSize]);

  useEffect(() => {
    console.log(sorMonitoringListState);
    console.log(paginations);
  }, [sorMonitoringListState]);

  return (
    <>
      <HeaderPageBlock>System Of Record Monitoring</HeaderPageBlock>
      {sorMonitoringListCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {sorMonitoringListCorsErrorMessage}
        </QboAlert>
      )}
      <QboCard isMainWrapper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Period"
            views={['year', 'month']}
            value={filterState.date}
            defaultValue={dayjs()}
            maxDate={dayjs()}
            minDate={dayjs().subtract(2, 'year')}
            onChange={(value) => {
              handleOnChangeDate(value);
            }}
            sx={{ marginBottom: 2 }}
          />
        </LocalizationProvider>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <div>
            <QboSortByButton
              defaultValue="All"
              marginRightValue={2}
              value={filterState.status}
              buttonWidth={220}
              startAdornmentTextWidth={70}
              startAdornmentText="Status"
              handleChange={(e) => {
                handleFilterdChange(e.target.value, 'status');
              }}
              menuItemList={systemStatusItems}
            />
          </div>
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <>
          {(sorMonitoringList?.length || fetching) && (
            <QboSimpleDataTable
              sx={{ width: '100%' }}
              rows={sorMonitoringList}
              header={tableColumns()}
              loading={fetching}
            />
          )}
          {(sorMonitoringList?.length || fetching) && (
            <QboCardListItem
              justifyContent="right"
              alignItems="center"
              style={{ paddingRight: '1.5em' }}
              rightContent={
                <QboPagination
                  hasNextLink={paginations?.HasNext}
                  hasPrevLink={paginations?.HasPrevious}
                  onClickPrevLink={() => {
                    const newPageNumber = paginationState.pageNumber - 1;
                    handleChangePage(newPageNumber);
                  }}
                  onClickNextLink={() => {
                    const newPageNumber = paginationState.pageNumber + 1;
                    handleChangePage(newPageNumber);
                  }}
                  onChangePageNumber={handleChangePageSize}
                  pageNumber={paginationState.pageSize}
                  paginationLabel="Page size"
                />
              }
            />
          )}

          {!sorMonitoringList?.length && !fetching && (
            <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
          )}

          <QboModal
            classNameStyle="Qbo_model_error_message"
            title={messageDialog.title}
            showCloseIcon
            open={showMesageDialog}
            onClose={() => setShowMesageDialog(false)}>
            <QboTypography wrap={!false}>{messageDialog.message}</QboTypography>
          </QboModal>
        </>
      </QboCard>
    </>
  );
}
