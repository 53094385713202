import { createSlice } from '@reduxjs/toolkit';

import {
  getM365SitesTenantAsync,
  getM365SitesTenantReducers,
} from './serviceActions/getM365SitesTenantAsync';

const initialState = {
  getM365SitesTenantState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    m365SitesTenantInformation: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    m365SitesTenantCorsErrorMessage: null,
  },
};

const M365SitesTenantSlice = createSlice({
  name: 'M365SitesTenant',
  initialState,
  reducers: {
    resetM365SitesTenantState(state) {
      state.getM365SitesTenantState = initialState.getM365SitesTenantState;
    },
    resetM365SitesTenantStatusCodeState(state) {
      state.getM365SitesTenantState.statusCode = initialState.getM365SitesTenantState.statusCode;
    },
  },
  extraReducers: {
    ...getM365SitesTenantReducers,
  },
});

export { getM365SitesTenantAsync };
export const { resetM365SitesTenantState, resetM365SitesTenantStatusCodeState } =
  M365SitesTenantSlice.actions;
export default M365SitesTenantSlice.reducer;
