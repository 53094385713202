import { constant } from '@config/BaseSetting';
import { channelListAsync } from '@features/dmeSettings/channelListSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useChannelList() {
  const dispatch = useDispatch();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { channelListState } = useSelector((state) => state.channelList);

  useEffect(() => {
    if (channelListState.data && Object.keys(channelListState.data).length > 0) return; // No need to fetch selections if its already fetched
    dispatch(
      channelListAsync({
        accessToken,
        reset: true,
      })
    );
  }, []);

  const channelList = useMemo(() => {
    if (!channelListState.data) return [];
    return Object.keys(channelListState.data).map((k, i) => {
      return {
        id: i,
        value: k,
        title: channelListState.data[k],
      };
    });
  }, [channelListState.data]);

  return {
    channelList,
  };
}
