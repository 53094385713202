import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEntraIdTenantAPI } from '@services/WebApiService';

const entraIdTenantAsync = createAsyncThunk('EntraId/entraIdTenant', async (paramsList) => {
  try {
    const data = await getEntraIdTenantAPI(paramsList);
    return data;
  } catch (error) {
    return { error: error.response };
  }
});

const { pending, fulfilled, rejected } = entraIdTenantAsync;

const entraIdTenantReducers = {
  [pending]: (state) => {
    state.entraIdTenantState.fetching = true;
  },
  [rejected]: (state) => {
    state.entraIdTenantState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.entraIdTenantState.fetching = false;

    if (action?.payload?.status === 403) {
      state.entraIdTenantState.forbiddenState = true;
      state.entraIdTenantState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.entraIdTenantState.corsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.entraIdTenantState.entraIdTenantInformation = action?.payload?.data?.data;
      state.entraIdTenantState.success = true;
      state.entraIdTenantState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.entraIdTenantState.error = action.payload.error;
    }
  },
};

export { entraIdTenantAsync, entraIdTenantReducers };
