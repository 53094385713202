import { createSlice } from '@reduxjs/toolkit';
import {
  fetchRestoreStatusListAsync,
  getRestoreStatusListExtraReducers,
} from './serviceActions/fetchRestoreStatusListAsync';

const initialState = {
  restoreStatusListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginations: {},
    restoreStatusList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    restoreStatusListCorsErrorMessage: null,
  },
};

const restoreStatusSlice = createSlice({
  name: 'restoreStatusList',
  initialState,
  reducers: {
    resetRestoreStatusListState(state) {
      state.restoreStatusListState = initialState.restoreStatusListState;
    },
  },
  extraReducers: {
    ...getRestoreStatusListExtraReducers,
  },
});

export { fetchRestoreStatusListAsync };
export const { resetRestoreStatusListState } = restoreStatusSlice.actions;
export default restoreStatusSlice.reducer;
