import { constant } from '@config/BaseSetting';
import { entraIdOrganizationAsync } from '@features/entraId/entraIdOrganizationSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useEntraIdOrganizationService(ownerId) {
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();
  const { entraIdOrganizationState } = useSelector((s) => s.entraIdOrganization);

  useEffect(() => {
    dispatch(
      entraIdOrganizationAsync({
        accessToken,
        reset: true,
        ownerId,
      })
    );
  }, []);

  return { entraIdOrganizationState };
}
