import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import {
  QboAdvanceTable,
  QboCard,
  QboFormGroup,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { resetGwsSharedDrivesTenantState } from '@features/gwsSharedDrivesTenant/gwsSharedDrivesTenantSlice';
import useDataService from './pageHooks/useDataService';
import useGwsSharedDrivesTenantService from './pageHooks/useGwsSharedDrivesTenantService';

export default function GwsSharedDrivesTenantPage(props) {
  const { lastRequestId } = props;
  const { t } = useTranslation();
  const { getGwsSharedDrivesTenantState } = useSelector((state) => state.gwsSharedDrivesTenant);
  const { gwsSharedDrivesTenantInformation, fetching, paginationList } =
    getGwsSharedDrivesTenantState;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const paginationProp = useRef(pagination);

  const { gwsSharedDrivesTenantHeaders, buttonStyle, filterStates } = useDataService();

  const [filterDataState, setFilterDataState] = useState(filterStates);

  const dispatch = useDispatch();

  const {
    handleFetchGwsSharedDrivesTenantAsync,
    handleOnTextFiledChange,
    handleOnResetButtonClick,
    matchTextHandler,
  } = useGwsSharedDrivesTenantService(
    filterDataState,
    setFilterDataState,
    filterStates,
    pagination,
    lastRequestId,
    setPagination
  );

  const handleOnSearchClick = (e) => {
    e.preventDefault();
    matchTextHandler();
    handleFetchGwsSharedDrivesTenantAsync(false);
    setPagination({ pageIndex: 0, pageSize: 10 });
  };

  useEffect(() => {
    if (lastRequestId !== null) {
      handleFetchGwsSharedDrivesTenantAsync(false);
    } else {
      dispatch(resetGwsSharedDrivesTenantState());
    }
  }, []);

  useEffect(() => {
    const paginationPropList = paginationProp.current;
    if (!isEqual(paginationPropList, pagination)) {
      paginationProp.current = pagination;
      handleFetchGwsSharedDrivesTenantAsync(false);
    }
  }, [pagination]);
  return (
    <>
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <div style={{ display: 'flex' }}>
            <QboTextField
              id="fullTextSearch"
              explicitStyleClass="FormControl__width-wrapper_40"
              value={filterDataState.textFieldValue}
              isWidthSetExplicit
              placeholder="Name/GWS ID"
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                handleOnTextFiledChange(e);
              }}
            />
          </div>
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <hr />
        <QboTypography
          sx={{
            marginLeft: 2,
            marginBottom: 3,
            fontSize: 18,
            color: '#000000DE',
            fontWeight: 600,
          }}>
          GWS Shared Drives Tenant List
        </QboTypography>
        {!filterDataState.resetClicked && filterDataState.matchText ? (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'pre-wrap',
            }}>
            {!filterDataState.resetClicked && filterDataState.matchText}
          </QboTypography>
        ) : null}

        {lastRequestId && (gwsSharedDrivesTenantInformation.length > 0 || fetching) && (
          <QboAdvanceTable
            enableColumnClickable={!false}
            isLoading={fetching}
            enableTopToolBar={false}
            headers={gwsSharedDrivesTenantHeaders}
            rows={gwsSharedDrivesTenantInformation}
            rowsCount={Number(paginationList?.TotalCount || 0)}
            pagination={pagination}
            setPagination={setPagination}
          />
        )}

        {gwsSharedDrivesTenantInformation.length === 0 && !fetching && <NoStatusSection />}
      </QboCard>
    </>
  );
}

GwsSharedDrivesTenantPage.propTypes = {
  lastRequestId: PropTypes.number,
};

GwsSharedDrivesTenantPage.defaultProps = {
  lastRequestId: null,
};
