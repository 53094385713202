import { createSlice } from '@reduxjs/toolkit';

import {
  updateSpecialPricingAsync,
  updateSpecialPricingExtraReducers,
} from './serviceActions/updateSpecialPricingAsync';

const initialState = {
  updateSpecialPricingState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    updateSpecialPricingCorsErrorMessage: null,
  },
};

const updateSpecialPricingSlice = createSlice({
  name: 'updateSpecialPricing',
  initialState,
  reducers: {
    resetupdateSpecialPricingState(state) {
      state.updateSpecialPricingState = initialState.updateSpecialPricingState;
    },
  },
  extraReducers: {
    ...updateSpecialPricingExtraReducers,
  },
});

export { updateSpecialPricingAsync };

export const { resetupdateSpecialPricingState } = updateSpecialPricingSlice.actions;
export default updateSpecialPricingSlice.reducer;
