export const SOR_MONITORING_COLUMNS = [
  { label: 'ID', columnName: 'id' },
  { label: 'Report Date', columnName: 'reportDate' },
  { label: 'Dag Job Id', columnName: 'dagJobsId', size: 'sm' },
  { label: 'Dag Id', columnName: 'dagId', size: 'sm' },
  { label: 'Run Type', columnName: 'runType', size: 'sm' },
  { label: 'Status', columnName: 'jobsStatus', size: 'sm' },
  { label: 'Total Distributor', columnName: 'distributorCount', size: 'sm' },
  { label: 'Total Sub-Reseller', columnName: 'subResellerCount', size: 'sm' },
  { label: 'Total DirectReseller', columnName: 'directResellerCount', size: 'sm' },
  { label: 'Total Contact ', columnName: 'contactCounts', size: 'sm' },
  { label: 'Data Interval Start', columnName: 'dataIntervalStart', size: 'sm' },
  { label: 'Data Interva lEnd', columnName: 'dataIntervalEnd', size: 'sm' },
  { label: 'Start Time', columnName: 'startDate', size: 'sm' },
  { label: 'End Time', columnName: 'endDate', size: 'sm' },
];

export const systemStatusItems = [
  {
    id: 1,
    value: '1',
    title: 'Running',
  },
  {
    id: 2,
    value: '2',
    title: 'Completed',
  },
  {
    id: 3,
    value: '3',
    title: 'Error',
  },
];
