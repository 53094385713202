import { createSlice } from '@reduxjs/toolkit';
import {
  fetchUserBackupListAsync,
  getUserBackupListExtraReducers,
} from './serviceActions/fetchUserBackupListAsync';
import {
  exportUserBackupAsync,
  exportUserBackupExtraReducers,
} from './serviceActions/exportUserBackupAsync';
import {
  fetchUserBackupDetailAsync,
  getUserBackupDetailExtraReducers,
} from './serviceActions/fetchUserBackupDetailAsync';
import {
  fecthLastBackupAsync,
  getLastBackupExtraReducers,
} from './serviceActions/fecthLastBackupAsync';

const initialState = {
  userBackupListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginations: {},
    userBackupList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    userBackupListCorsErrorMessage: null,
  },
  exportUserBackupState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    userBackupExportData: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    exportUserBackupCorsErrorMessage: null,
  },
  userBackupDetailState: {
    fetching: false,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    statusCode: null,
    success: false,
    error: null,
    data: {},
    userBackupDetailCorsErrorMessage: null,
  },
  lastBackupState: {
    fetching: false,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    statusCode: null,
    success: false,
    error: null,
    data: {},
    lastBackupCorsErrorMessage: null,
  },
  filterState: {
    searchById: '',
    organizationOwnerId: null,
    resellerId: null,
    status: null,
    m365Id: '',
    tenantId: '',
    protocol: null,
    hasCct: null,
    completedSetup: null,
    journalCompletedSetup: null,
    inplaceArchiveStatus: null,
    isActive: null,
    deleted: null,
    hasGroup: null,
    orderBy: 'id desc',
  },
  paginationState: { pageNumber: 1, pageSize: 10 },
  detailPageState: {
    id: null,
    email: null,
    backupType: null,
  },
};

const userBackupSlice = createSlice({
  name: 'userBackupList',
  initialState,
  reducers: {
    resetUserBackupListState(state) {
      state.userBackupListState = initialState.userBackupListState;
    },
    resetExportUserBackupState(state) {
      state.exportUserBackupState = initialState.exportUserBackupState;
    },
    resetUserBackupDetailState(state) {
      state.userBackupDetailState = initialState.userBackupDetailState;
    },

    resetLastBackupState(state) {
      state.lastBackupState = initialState.lastBackupState;
    },
    setFilterState(state, action) {
      state.filterState = action?.payload;
    },
    resetFilterState(state) {
      state.filterState = initialState.filterState;
    },
    setPaginationState(state, action) {
      state.paginationState = action?.payload;
    },
    resetPaginationState(state) {
      state.paginationState = initialState.paginationState;
    },
    setDetailPageState(state, action) {
      state.detailPageState = action?.payload;
    },
  },
  extraReducers: {
    ...getUserBackupListExtraReducers,
    ...exportUserBackupExtraReducers,
    ...getUserBackupDetailExtraReducers,
    ...getLastBackupExtraReducers,
  },
});

export {
  exportUserBackupAsync,
  fetchUserBackupListAsync,
  fetchUserBackupDetailAsync,
  fecthLastBackupAsync,
};
export const {
  resetUserBackupListState,
  resetExportUserBackupState,
  resetUserBackupDetailState,
  resetLastBackupState,
  setFilterState,
  resetFilterState,
  setPaginationState,
  resetPaginationState,
  setDetailPageState,
} = userBackupSlice.actions;
export default userBackupSlice.reducer;
