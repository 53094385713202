export default function useDataService() {
  const entraIdOrganizationHeaders = [
    {
      label: 'ID',
      properties: 'id',
      valType: 'int',
    },
    {
      label: 'Partner Name',
      properties: 'partnerName',
      valType: 'string',
    },
    {
      label: 'Organization Name',
      properties: 'organizationName',
      valType: 'string',
    },
    {
      label: 'Status',
      properties: 'status',
      valType: 'string',
    },
    {
      label: 'Deactivated At',
      properties: 'deactivatedAt',
      valType: 'string',
    },
    {
      label: 'Deactivation Reason',
      properties: 'deactivationReason',
      valType: 'string',
    },
    {
      label: 'Deleted At',
      properties: 'deletedAt',
      valType: 'string',
    },
  ];
  return {
    entraIdOrganizationHeaders,
  };
}
