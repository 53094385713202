export const SOR_MONITORING_DETAIL_COLUMNS = [
  { label: 'Detail ID', columnName: 'id' },
  { label: 'Report Date', columnName: 'reportDate' },
  { label: 'Dag Job Id', columnName: 'dagJobsId', size: 'sm' },
  { label: 'Task', columnName: 'task', size: 'sm' },
  { label: 'Status', columnName: 'status', size: 'sm' },
  { label: 'Retry Count', columnName: 'retryCount', size: 'sm' },
  { label: 'Region ID', columnName: 'regionId', size: 'sm' },
  { label: 'Entity ID', columnName: 'entityId', size: 'sm' },
  { label: 'Entity Name', columnName: 'entityName', size: 'sm' },
  { label: 'Entity Type ', columnName: 'entityType', size: 'sm' },
  { label: 'Entity Role', columnName: 'entityRole', size: 'sm' },
  { label: 'Completed At', columnName: 'completedAt', size: 'sm' },
  { label: 'HTTP Response', columnName: 'httpResponse', size: 'sm' },
];

export const systemStatusItems = [
  {
    id: 1,
    value: '1',
    title: 'Running',
  },
  {
    id: 2,
    value: '2',
    title: 'Completed',
  },
  {
    id: 3,
    value: '3',
    title: 'Error',
  },
];

export const booleanItems = [
  {
    id: 1,
    value: 'true',
    title: 'True',
  },
  {
    id: 2,
    value: 'false',
    title: 'False',
  },
];

export const entityTypeItems = [
  {
    id: 1,
    value: 'account',
    title: 'Account',
  },
  {
    id: 2,
    value: 'contact',
    title: 'Contact',
  },
];

export const entityRoleItems = [
  {
    id: 1,
    value: 'Distributor',
    title: 'Distributor',
  },
  {
    id: 2,
    value: 'Direct',
    title: 'Direct',
  },
  {
    id: 3,
    value: 'Subreseller',
    title: 'Subreseller',
  },
  {
    id: 4,
    value: 'Reseller Owner',
    title: 'Reseller Owner',
  },
  {
    id: 5,
    value: 'Super Admin',
    title: 'Super Admin',
  },
  {
    id: 6,
    value: 'Admin',
    title: 'Admin',
  },
  {
    id: 7,
    value: 'Support',
    title: 'Support',
  },
  {
    id: 8,
    value: 'Finance',
    title: 'Finance',
  },
];
