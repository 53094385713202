import { constant } from '@config/BaseSetting';
import {
  getGwsSharedDrivesTenantAsync,
  resetGwsSharedDrivesTenantState,
} from '@features/gwsSharedDrivesTenant/gwsSharedDrivesTenantSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useDispatch } from 'react-redux';

export default function useGwsSharedDrivesTenantService(
  filterDataState,
  setFilterDataState,
  filterStates,
  pagination,
  lastRequestId,
  setPagination
) {
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();

  const handleFetchGwsSharedDrivesTenantAsync = (flag) => {
    dispatch(
      getGwsSharedDrivesTenantAsync({
        accessToken,
        reset: true,
        lastRequestId,
        fullTextSearch: flag
          ? filterStates.searchFieldValueText
          : filterDataState.searchFieldValueText,
        pageNumber: flag ? 1 : pagination.pageIndex + 1,
        pageSize: flag ? 10 : pagination.pageSize,
        orderBy: flag ? filterDataState.selectedSortColumn : filterStates.selectedSortColumn,
        filter: [],
      })
    );
  };

  const handleOnResetButtonClick = () => {
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: filterStates.textFieldValue,
      searchFieldValueText: filterStates.searchFieldValueText,
      searchFieldValueID: filterStates.searchFieldValueID,
      selectedSortColumn: filterStates.selectedSortColumn,
      matchText: filterStates.matchText,
      resetClicked: filterStates.resetClicked,
    }));
    setPagination({ pageIndex: 0, pageSize: 10 });

    dispatch(resetGwsSharedDrivesTenantState());
    handleFetchGwsSharedDrivesTenantAsync(true);
  };

  const handleOnTextFiledChange = (e) => {
    const input = e.target.value;
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: input,
    }));
    setFilterDataState((prevState) => ({
      ...prevState,
      searchFieldValueID: '',
      searchFieldValueText: input,
    }));
  };

  const matchTextHandler = () => {
    const { searchFieldValueID, searchFieldValueText } = filterDataState;

    let textData = 'Matching ';

    if (searchFieldValueText) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` Name/GWS ID = '${searchFieldValueText}' `;
    }

    if (searchFieldValueID) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` Name/GWS ID = '${searchFieldValueID}' `;
    }

    if (textData === 'Matching ') {
      textData = '';
    }
    textData = textData.replace(/,\s*$/, '');

    setFilterDataState((prevState) => ({
      ...prevState,
      matchText: textData,
    }));
  };

  return {
    handleFetchGwsSharedDrivesTenantAsync,
    handleOnResetButtonClick,
    handleOnTextFiledChange,
    matchTextHandler,
  };
}
