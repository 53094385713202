import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { showFixedAlert } from '@features/application/applicationSlice';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import { EMAIL } from '@pages/UserBackupPage/pageHooks/constants/emailConstants';
import {
  resetUserBackupReportState,
  userBackupReportAsync,
} from '@features/userBackupReport/userBackupReportSlice';
import useForm from './useForm';

export default function useUserBackupReport({ selectedAccount, onSuccessRequest }) {
  const dispatch = useDispatch();
  const form = useForm();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { userBackupReportState } = useSelector((state) => state.userBackupReport);
  const [reportType, setReportType] = useState(EMAIL);
  const { t } = useTranslation();

  const handleUserBackupReportRequestAsync = (parameters) => {
    dispatch(
      userBackupReportAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const submitRequest = () => {
    if (form.isValid) {
      handleUserBackupReportRequestAsync([
        {
          'support-ticket': form.attributes.supportTicketAttributes.value,
          description: form.attributes.descriptionAttributes.value,
          'escalation-ticket': form.attributes.escalationTicketAttributes.value,
          'email-account-id': selectedAccount.id,
          'org-owner-id': selectedAccount.organizationId,
          'report-type': `${reportType}_backup`,
        },
      ]);
    }
  };

  const onValidateData = (e) => {
    form.onValidateData(e);
    submitRequest();
  };

  useEffect(() => {
    submitRequest();
  }, [form.isValid]);

  useEffect(() => {
    if (userBackupReportState.status === 200) {
      form.resetForm();
      dispatch(resetUserBackupReportState());
      if (userBackupReportState.serviceType) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': userBackupReportState.serviceType,
        });

        dispatch(
          showFixedAlert({
            descriptionHtml: t('user_backup_page.user_backup_report.success_submitted_with_link', {
              link,
            }),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('user_backup_page.user_backup_report.success_submitted'),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
    }
  }, [userBackupReportState.status]);

  return {
    form,
    onValidateData,
    userBackupReportState,
    reportType,
    setReportType,
  };
}
