import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import {
  QboAdvanceTable,
  QboCard,
  QboFormGroup,
  QboTextField,
  QboTypography,
} from '@ui/Components';

import QboSortByButton from '@ui/Components/QboSortByButton';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import useDataService from './pageHooks/useDataService';
import useM365SitesTenantService from './pageHooks/useM365SitesTenantService';

export default function M365SitesTenantPage(props) {
  const { m365TenantCredentialID } = props;
  const { t } = useTranslation();
  const { getM365SitesTenantState } = useSelector((state) => state.m365SitesTenant);
  const { m365SitesTenantInformation, fetching, paginationList } = getM365SitesTenantState;
  const [filterdData, setFilterdData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const paginationProp = useRef(pagination);

  const {
    m365SitesTenantHeaders,
    clickableColumnList,
    buttonStyle,
    activeStatus,
    filterStates,
    initialFilterState,
  } = useDataService();

  const [filterDataState, setFilterDataState] = useState(filterStates);

  const [adFilter, setAdFilter] = useState(initialFilterState);

  const {
    handleFetchM365SitesTenantAsync,
    handleOnTextFiledChange,
    handleOnResetButtonClick,
    handleStateUpdate,
    handleOnHideAdvanceClick,
    handleUpdateFilters,
    matchTextHandler,
  } = useM365SitesTenantService(
    filterDataState,
    setFilterDataState,
    filterStates,
    pagination,
    adFilter,
    setAdFilter,
    initialFilterState,
    m365TenantCredentialID,
    setPagination
  );

  const handleOnSearchClick = (e) => {
    e.preventDefault();
    matchTextHandler();
    handleFetchM365SitesTenantAsync(false);
    setPagination({ pageIndex: 0, pageSize: 10 });
  };

  useEffect(() => {
    if (m365SitesTenantInformation?.length === 0) {
      handleFetchM365SitesTenantAsync(false);
    }
  }, []);

  useEffect(() => {
    setFilterdData(m365SitesTenantInformation);
  }, [m365SitesTenantInformation]);

  useEffect(() => {
    const paginationPropList = paginationProp.current;
    if (!isEqual(paginationPropList, pagination)) {
      paginationProp.current = pagination;
      handleFetchM365SitesTenantAsync(false);
    }
  }, [pagination]);

  return (
    <>
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <div style={{ display: 'flex' }}>
            <QboTextField
              id="fullTextSearch"
              explicitStyleClass="FormControl__width-wrapper_40"
              value={filterDataState.textFieldValue}
              isWidthSetExplicit
              placeholder="M365 ID/Site Name/Display Name/Partner/Organization/Parent"
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                handleOnTextFiledChange(e);
              }}
            />
          </div>

          <div style={{ marginTop: 8 }}>
            <Button
              disableRipple
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => {
                handleStateUpdate('advanceClick', !filterDataState.advanceClick);
                handleOnHideAdvanceClick();
              }}>
              {filterDataState.advanceClick
                ? t('partner_details_page.button.hide_advance_search')
                : t('partner_details_page.button.show_advance_search')}
            </Button>
          </div>
          {filterDataState.advanceClick && (
            <div>
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={adFilter.find((item) => item.field === 'filter_active').value}
                buttonWidth={220}
                startAdornmentTextWidth={60}
                startAdornmentText="Status :"
                handleChange={(e) => handleUpdateFilters('filter_active', e.target.value)}
                menuItemList={activeStatus}
              />
            </div>
          )}
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <hr />
        <QboTypography
          sx={{
            marginLeft: 2,
            marginBottom: 3,
            fontSize: 18,
            color: '#000000DE',
            fontWeight: 600,
          }}>
          M365 Sites Tenant List
        </QboTypography>
        {!filterDataState.resetClicked && filterDataState.matchText ? (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'pre-wrap',
            }}>
            {!filterDataState.resetClicked && filterDataState.matchText}
          </QboTypography>
        ) : null}

        {(filterdData.length > 0 || fetching) && (
          <QboAdvanceTable
            isLoading={fetching}
            enableTopToolBar={false}
            headers={m365SitesTenantHeaders}
            rows={filterdData}
            rowsCount={Number(paginationList?.TotalCount || 0)}
            pagination={pagination}
            setPagination={setPagination}
            enableColumnClickable={!false}
            columnsClickableList={clickableColumnList}
          />
        )}

        {filterdData.length === 0 && !fetching && <NoStatusSection />}
      </QboCard>
    </>
  );
}

M365SitesTenantPage.propTypes = {
  m365TenantCredentialID: PropTypes.number,
};

M365SitesTenantPage.defaultProps = {
  m365TenantCredentialID: null,
};
