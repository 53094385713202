import { Grid } from '@mui/material';
import {
  ITBox,
  QboAlert,
  QboFormGroup,
  QboLabelGroup,
  QboModal,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useEditResellerConfigs from './pageHooks/useEditResellerConfigs';

function EditResellerConfigsModal({
  onSuccess,
  closeModal,
  showModal,
  resellerId,
  resellerName,
  changedValues,
  labels,
}) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState();
  const { form, editResellerConfigsState, handleOnValidateData, resetAll } = useEditResellerConfigs(
    { resellerId, changedValues, onSuccessRequest: onSuccess, onFailedRequest: setErrorMessage }
  );

  const closeAndReset = () => {
    resetAll();
    closeModal();
  };

  return (
    <QboModal
      open={showModal}
      onClose={closeAndReset}
      title={t('partner_list_page.edit_reseller_configs.title')}
      showCloseIcon
      preventOutsideClick>
      <ITBox direction="TransferSubscriptionFormPage__wrapper column">
        <QboFormGroup
          loadingButton={editResellerConfigsState.fetching}
          onSubmit={(e) => {
            setErrorMessage(null);
            handleOnValidateData(e);
          }}
          withGenerateAndResetButton
          handleOnResetButton={closeAndReset}
          secondButtonLabel="Cancel"
          firstButtonLabel="Submit">
          {errorMessage && (
            <QboAlert
              variant="outlined"
              type="error"
              style={{
                background: '#fdeded',
                fontSize: '10px !important',
              }}>
              <p>{t('partner_list_page.edit_reseller_configs.error', { error: errorMessage })}</p>
            </QboAlert>
          )}
          <QboAlert
            variant="outlined"
            type="info"
            style={{
              border: '1px solid #ebebeb',
              background: '#f3f3f3',
              fontSize: '10px !important',
              marginTop: '20px',
            }}>
            <QboTypography
              noWrap={false}
              style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
              {t('partner_list_page.edit_reseller_configs.confirmation')}
            </QboTypography>
          </QboAlert>
          <ITBox
            className="TransferSubresellerPage__information-box"
            direction="column"
            sx={{ marginBottom: '20px' }}>
            <QboLabelGroup title="Partner ID" description={resellerId || '-'} />
            <QboLabelGroup title="Partner Name" description={resellerName || '-'} />
          </ITBox>
          <div style={{ marginBottom: '20px' }}>
            <QboTypography sx={{ fontSize: 13, fontWeight: 700 }}>
              {t('partner_list_page.edit_reseller_configs.label.settings')}
            </QboTypography>
            {changedValues &&
              Object.keys(changedValues).map((k) => (
                <QboCardSingleList
                  subHeadingText={labels?.[k].subHeadingText}
                  leftContent={labels?.[k].label ?? k}
                  rightContent={changedValues[k].toString()}
                />
              ))}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboTextField
                id="supportTicket"
                placeholder="Support Ticket"
                label="Support Ticket"
                fullWidth
                value={form.supportTicketAttributes.value}
                onChange={(e) => {
                  form.supportTicketAttributes.handleChange(e.currentTarget.value);
                }}
                inputProps={{ maxLength: 225 }}
              />
            </Grid>
            <Grid item xs={6}>
              <QboTextField
                id="escalationTicket"
                placeholder="Escalation Ticket"
                label="Escalation Ticket"
                fullWidth
                value={form.escalationTicketAttributes.value}
                onChange={(e) =>
                  form.escalationTicketAttributes.handleChange(e.currentTarget.value)
                }
                inputProps={{ maxLength: 225 }}
              />
            </Grid>
          </Grid>
          <QboTextField
            id="description"
            placeholder="Description"
            label="Description"
            fullWidth
            value={form.descriptionAttributes.value}
            onChange={(e) => form.descriptionAttributes.handleChange(e.currentTarget.value)}
            inputProps={{ maxLength: 225 }}
          />
        </QboFormGroup>
      </ITBox>
    </QboModal>
  );
}

EditResellerConfigsModal.propTypes = {
  onSuccess: PropTypes.func,
  closeModal: PropTypes.func,
  showModal: PropTypes.bool,
  resellerId: PropTypes.number,
  resellerName: PropTypes.string,
  changedValues: PropTypes.object,
  labels: PropTypes.arrayOf(PropTypes.object),
};

EditResellerConfigsModal.defaultProps = {
  onSuccess: () => {},
  closeModal: () => {},
  showModal: false,
  resellerId: 0,
  resellerName: '',
  changedValues: {},
  labels: [],
};

export default EditResellerConfigsModal;
