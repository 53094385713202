import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetFilterState,
  setFilterState,
  resetPaginationState,
} from '@features/userBackup/userBackupInfoSlice';

export default function useUserBackupFilter(setOnSearch) {
  const dispatch = useDispatch();
  const { filterState } = useSelector((state) => state.userBackupState);
  const [advanceClick, setAdvanceClick] = useState(false);

  const handleOnSearchClick = async (e) => {
    e.preventDefault();
    dispatch(resetPaginationState());
    setOnSearch(true);
  };

  const handleOnResetButtonClick = (e) => {
    e.preventDefault();
    dispatch(resetFilterState());
    dispatch(resetPaginationState());
    setOnSearch(true);
  };

  const handleSearchFieldChange = (value, key) => {
    const newFilter = { ...filterState, [key]: value };
    dispatch(setFilterState(newFilter));
  };

  const handleOnAdvanceSearchClick = () => {
    setAdvanceClick(!advanceClick);
  };

  useEffect(() => {
    if (!advanceClick) {
      dispatch(
        setFilterState({
          searchById: filterState.searchById,
          organizationOwnerId: filterState.organizationOwnerId,
          resellerId: filterState.resellerId,
          status: null,
          m365Id: '',
          tenantId: '',
          protocol: null,
          hasCct: null,
          completedSetup: null,
          journalCompletedSetup: null,
          inplaceArchiveStatus: null,
          isActive: null,
          hasGroup: null,
          orderBy: 'id desc',
          deleted: null,
        })
      );
    }
  }, [advanceClick]);

  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const protocolItems = [
    {
      id: 1,
      value: '1',
      title: 'Imap',
    },
    {
      id: 2,
      value: '2',
      title: 'Pop3',
    },
    {
      id: 3,
      value: '3',
      title: 'Microsoft 365',
    },
    {
      id: 4,
      value: '4',
      title: 'Google Work Space',
    },
  ];

  const booleanItemList = [
    {
      id: 1,
      value: 'true',
      title: 'True',
    },
    {
      id: 2,
      value: 'false',
      title: 'False',
    },
  ];

  const statusItems = [
    {
      id: 1,
      value: 'completed',
      title: 'Completed',
    },
    {
      id: 2,
      value: 'preparing',
      title: 'Preparing Backup',
    },
    {
      id: 3,
      value: 'running',
      title: 'Running',
    },
    {
      id: 4,
      value: 'retrying',
      title: 'Retry',
    },
    {
      id: 5,
      value: 'error',
      title: 'Error',
    },
  ];

  return {
    handleOnSearchClick,
    handleOnResetButtonClick,
    handleSearchFieldChange,
    handleOnAdvanceSearchClick,
    filterState,
    advanceClick,
    buttonStyle,
    protocolItems,
    booleanItemList,
    statusItems,
  };
}
