import { constant } from '@config/BaseSetting';
import { ownerListAsync } from '@features/dmeSettings/ownerListSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useOwnerList() {
  const dispatch = useDispatch();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { ownerListState } = useSelector((state) => state.ownerList);

  useEffect(() => {
    if (ownerListState.data && Object.keys(ownerListState.data).length > 0) return; // No need to fetch selections if its already fetched
    dispatch(
      ownerListAsync({
        accessToken,
        reset: true,
      })
    );
  }, []);

  const ownerList = useMemo(() => {
    if (!ownerListState.data) return [];
    return Object.keys(ownerListState.data).map((k, i) => {
      return {
        id: i,
        value: k,
        title: ownerListState.data[k],
      };
    });
  }, [ownerListState.data]);

  return {
    ownerList,
  };
}
