import React from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
  Button,
  ListItemIcon,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  setPartnerDetailsState,
  setTabState,
  setOrganizationDetailState,
  setM365TenantDetailState,
  setPartnerManagementLink,
  setGWSTenantDetailState,
  setTenantLink,
} from '@features/application/applicationSlice';
import { setHEXTenantDetailState } from '@features/hexTenantDetails/hexTenantDetailsInfoSlice';
import { setM365TenantRowState } from '@features/m365Tenant/m365TenantSlice';

function TableCell(props, columnsClickableList, enableColumnClickable, showColumnId) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cell } = props;
  const showId = showColumnId || 'id';

  const handleOnColumnClick = (event, path, col, tab, column, handler) => {
    event.preventDefault();
    const rowData = col?.row?.original;
    let search = `?id=${rowData?.id}&name=${rowData?.name}`;
    let shouldNavigate = true;

    if (column === 'plan') {
      search = `?id=${rowData?.id}&name=${rowData?.partner}&plan=${rowData?.plan}`;
    } else if (column === 'numberOfOrganizations') {
      search = `?id=${rowData?.id}&name=${rowData?.partnerName}&plan=${rowData?.planName}`;
    } else if (column === 'parent') {
      search = `?id=${rowData?.parentId}&name=${rowData?.parent}`;
    } else if (column === 'partner') {
      search = `?id=${rowData?.resellerId}&name=${rowData?.partner}`;
    } else if (column === 'partnerName') {
      dispatch(setPartnerManagementLink({ open: true }));
      search = `?id=${rowData?.id}&name=${rowData?.partnerName}`;
    } else if (column === 'resellerName') {
      dispatch(setPartnerManagementLink({ open: true }));
      search = `?id=${rowData?.resellerId}&name=${rowData?.resellerName}`;
    } else if (column === 'tenantPartnerName') {
      dispatch(setPartnerManagementLink({ open: true }));
      search = `?id=${rowData?.partnerId}&name=${rowData?.tenantPartnerName}`;
    } else if (column === 'tenantParent') {
      dispatch(setPartnerManagementLink({ open: true }));
      search = `?id=${rowData?.parentId}&name=${rowData?.tenantParent}`;
    } else if (column === 'orgName') {
      search = `?id=${rowData?.organizationId}&name=${rowData?.orgName}`;
      dispatch(setOrganizationDetailState({ path, row: col }));
    } else if (column === 'organizationName') {
      const orgOwnerId = rowData?.userID ?? rowData?.organizationOwnerId;
      search = `?id=${orgOwnerId}&name=${rowData?.organizationName}`;
      dispatch(
        setOrganizationDetailState({
          path,
          row: { organizationOwnerId: orgOwnerId, orgName: rowData?.organizationName },
        })
      );
    } else if (column === 'tenant') {
      dispatch(setTenantLink({ open: true }));
      dispatch(setM365TenantRowState(rowData));
      search = `?id=${rowData?.id}&name=${rowData?.domain}`;
    } else if (column === 'guid') {
      dispatch(setTenantLink({ open: true }));
      search = `?id=${rowData?.id}&name=${rowData?.encryptedPowershellUsername}`;
    } else if (column === 'licensed') {
      search = '';
      handler(rowData);
    } else if (column === 'details') {
      shouldNavigate = false;
      handler(rowData);
    } else {
      search = '';
    }

    dispatch(setPartnerDetailsState({ path, row: col }));
    dispatch(setM365TenantDetailState({ path, row: col }));
    dispatch(setHEXTenantDetailState({ path, row: col }));
    dispatch(setGWSTenantDetailState({ path, row: col }));
    dispatch(setTabState(tab));
    if (shouldNavigate) navigate({ pathname: path, search });
  };

  const cellClickable = (col) => {
    const colContent = (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '1.6em',
          maxWidth: col?.column?.columnDef?.size,
          overflow: 'hidden',
          textWrap: 'pretty',
          textTransform: col?.column?.columnDef?.textTransform,
        }}>
        <p
          style={{
            maxHeight: 45,
            maxWidth: col?.column?.columnDef?.size,
            wordWrap: 'break-word',
          }}>
          {col.getValue()?.toString()}
        </p>
      </Box>
    );
    if (enableColumnClickable) {
      const clickableItem = columnsClickableList.find((item) => item.column === col.column.id);

      if (clickableItem) {
        return (
          <Link
            onClick={(event) => {
              handleOnColumnClick(
                event,
                clickableItem.path,
                col,
                clickableItem.tab,
                clickableItem.column,
                clickableItem?.handler
              );
            }}
            href={clickableItem.path}>
            {colContent}
          </Link>
        );
      }
    }
    return colContent;
  };

  return cellClickable(cell);
}

export default function QboAdvanceTable({
  headers,
  rows,
  isLoading,
  onClickRow,
  enableSorting,
  onClickRowSx,
  columnResizing,
  enableColumnOrdering,
  enableColumnActions,
  pagination,
  setPagination,
  rowsCount,
  enableTopToolBar,
  enableBottomToolbar,
  enableEditing,
  customActionsList,
  enablePaginationProp,
  rowSelection,
  setRowSelection,
  enableRowSelection,
  isCheckBoxFilterEnable,
  filterKey,
  filterValue,
  iconData,
  selectFilterKey,
  selectFilterValue,
  columnsClickableList,
  enableColumnClickable,
  enableStickyHeader,
  frozenColumns,
  showColumnId,
  renderRowActionMenuItems,
  enableRowActions,
  checkboxFilterKey,
  checkboxFilterValue,
}) {
  const columns = headers.map((item) => {
    const accessorKey = item.columnName;
    const header = item.label;
    const columnProps = {
      accessorKey,
      header,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#c2e2ef',
          fontSize: 14,
          paddingTop: 2,
          paddingBottom: 2,
          fontWeight: 500,
          ...item.sx,
        },
      },
      align: 'center',
      isFiledNeed: true,
      textTransform: item?.textTransform,
      Cell: (props) => TableCell(props, columnsClickableList, enableColumnClickable, showColumnId),
    };

    if (item.size) {
      columnProps.size = item.size;
    }

    return columnProps;
  });

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const rowsDropDown = () =>
    Array.from({ length: Math.floor((rowsCount > 50 ? 50 : rowsCount) / 10) }).map(
      (item, index) => (index + 1) * 10
    );

  const commonIcons = ({ iconDataProp, isEditProp, statusProp, rowProp }) => {
    return (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        {iconDataProp.map((icon, index) => (
          <Tooltip
            arrow
            key={icon?.tooltipTitle}
            placement={icon.tooltipPlacement}
            title={icon.tooltipTitle}>
            <IconButton
              disabled={!isEditProp || (icon.disableOnStatus && statusProp !== icon?.disabledValue)}
              style={
                !isEditProp || (icon.disableOnStatus && statusProp !== icon?.disabledValue)
                  ? { color: 'rgb(128,128,128)' }
                  : icon.style
              }
              onClick={() => icon.onClick(rowProp.original)}>
              {icon.icon}
            </IconButton>
          </Tooltip>
        ))}
      </Box>
    );
  };
  const { isEdit, status } = enableEditing;

  const {
    labelSize,
    minWidth,
    inputLabel,
    selectedValue,
    selectLabel,
    handleChange,
    dropdownListItem,
    isCustomActionEnable,
  } = customActionsList;

  const rest = renderRowActionMenuItems
    ? {
        enableRowActions,
        renderRowActionMenuItems,
      }
    : {
        enableRowSelection: enableRowSelection
          ? isCheckBoxFilterEnable &&
            ((row) =>
              row?.original[filterKey] === filterValue ||
              row?.original[selectFilterKey] === selectFilterValue ||
              row?.original[checkboxFilterKey] === checkboxFilterValue)
          : null,
        renderRowActions: ({ row }) =>
          commonIcons({
            iconDataProp: iconData,
            statusProp: status,
            isEditProp: isEdit,
            rowProp: row,
          }),
      };

  return isLoading ? (
    renderLoading()
  ) : (
    <MaterialReactTable
      {...rest}
      getRowId={(row) => row.id}
      onRowSelectionChange={setRowSelection}
      enablePagination={enablePaginationProp}
      manualPagination
      autoResetPageIndex={false}
      positionActionsColumn="first"
      initialState={{ density: 'compact', columnPinning: { left: frozenColumns } }}
      muiTableContainerProps={{ sx: { alignItems: 'center' } }}
      muiTableBodyCellProps={{ sx: { height: 50, paddingLeft: 2 } }}
      columns={columns}
      data={rows}
      rowCount={rowsCount}
      enableTopToolbar={enableTopToolBar}
      enableBottomToolbar={enableBottomToolbar}
      enableSorting={enableSorting}
      enableColumnOrdering={enableColumnOrdering}
      enableColumnResizing={columnResizing}
      enableColumnActions={enableColumnActions}
      onPaginationChange={setPagination}
      enableStickyHeader={enableStickyHeader}
      muiTablePaginationProps={{ rowsPerPageOptions: rowsDropDown(), style: { fontSize: 14 } }}
      state={{ pagination, showSkeletons: isLoading, rowSelection }}
      displayColumnDefOptions={{
        'mrt-row-actions': {
          muiTableHeadCellProps: {
            sx: {
              backgroundColor: '#c2e2ef',
              height: 50,
              fontSize: 14,
              paddingTop: 2,
              paddingLeft: 2,
            },
          },
          size: 80,
          align: 'right',
        },
        'mrt-row-select': {
          muiTableHeadCellProps: {
            sx: {
              backgroundColor: '#c2e2ef',
              height: 50,
              fontSize: 14,
              paddingTop: 2,
              paddingLeft: 2,
            },
          },
          size: 80,
          align: 'right',
        },
      }}
      muiTableBodyRowProps={({ row }) => ({
        onClick: () => {
          onClickRow(row.original);
        },
        sx: onClickRowSx,
      })}
      enableEditing={isEdit}
      renderTopToolbarCustomActions={() =>
        isCustomActionEnable ? (
          <FormControl sx={{ m: 1, minWidth: minWidth || 120 }} size={labelSize}>
            <InputLabel style={{ fontSize: 12 }} id="demo-select-small-label">
              {inputLabel}
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedValue || 'All'}
              label={selectLabel}
              onChange={handleChange}>
              <MenuItem value="All">All</MenuItem>
              {dropdownListItem?.map((item, index) => (
                <MenuItem key={item?.id || index} value={item || item?.name}>
                  {item || item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <div />
        )
      }
    />
  );
}

TableCell.propTypes = {
  cell: PropTypes.func,
  columnsClickableList: PropTypes.array,
  enableColumnClickable: PropTypes.bool,
  showColumnId: PropTypes.string,
};

TableCell.defaultProps = {
  cell: () => {},
  columnsClickableList: [],
  enableColumnClickable: false,
  showColumnId: null,
};

QboAdvanceTable.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.array,
  isLoading: PropTypes.bool,
  enablePaginationProp: PropTypes.bool,
  onClickRow: PropTypes.func,
  onClickRowSx: PropTypes.object,
  enableSorting: PropTypes.bool,
  columnResizing: PropTypes.bool,
  enableColumnOrdering: PropTypes.bool,
  enableColumnActions: PropTypes.bool,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  rowsCount: PropTypes.number,
  enableTopToolBar: PropTypes.bool,
  enableColumnClickable: PropTypes.bool,
  enableBottomToolbar: PropTypes.bool,
  enableEditing: PropTypes.object,
  customActionsList: PropTypes.object,
  enableRowSelection: PropTypes.bool,
  isCheckBoxFilterEnable: PropTypes.bool,
  rowSelection: PropTypes.object,
  columnsClickableList: PropTypes.array,
  setRowSelection: PropTypes.func,
  filterKey: PropTypes.string,
  filterValue: PropTypes.string,
  iconData: PropTypes.array,
  selectFilterKey: PropTypes.string,
  showColumnId: PropTypes.string,
  selectFilterValue: PropTypes.string,
  enableStickyHeader: PropTypes.bool,
  frozenColumns: PropTypes.array,
  renderRowActionMenuItems: PropTypes.func,
  enableRowActions: PropTypes.bool,
  checkboxFilterKey: PropTypes.string,
  checkboxFilterValue: PropTypes.string,
};

QboAdvanceTable.defaultProps = {
  headers: [],
  rows: [],
  isLoading: false,
  enablePaginationProp: true,
  onClickRow: () => {},
  onClickRowSx: {},
  enableSorting: false,
  columnResizing: false,
  enableColumnOrdering: false,
  enableColumnActions: false,
  setPagination: () => {},
  pagination: {},
  rowsCount: 0,
  enableTopToolBar: true,
  enableBottomToolbar: true,
  enableEditing: {},
  customActionsList: {},
  columnsClickableList: [],
  enableRowSelection: false,
  enableColumnClickable: false,
  isCheckBoxFilterEnable: false,
  rowSelection: {},
  setRowSelection: () => {},
  filterKey: null,
  filterValue: null,
  iconData: [],
  selectFilterKey: null,
  showColumnId: null,
  selectFilterValue: null,
  enableStickyHeader: false,
  frozenColumns: [],
  renderRowActionMenuItems: null,
  enableRowActions: false,
  checkboxFilterKey: null,
  checkboxFilterValue: null,
};
