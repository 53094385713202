export const SHAREPOINT = 'sharepoint';
export const SHAREPOINT_COLUMNS = [
  { label: 'ID', columnName: 'userBackupId' },
  {
    label: 'Site Name',
    columnName: 'siteName',
    size: 'sm',
  },
  {
    label: 'Domain Name',
    columnName: 'domainName',
    size: 'sm',
  },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
  },
  {
    label: 'Parent Name',
    columnName: 'distributorName',
    size: 'sm',
  },
  { label: 'Has Ms Group', columnName: 'hasGroup', size: 'sm' },
  { label: 'Active', columnName: 'active', size: 'sm' },
  { label: 'Last Backup At', columnName: 'lastBackupAt', size: 'sm' },
  { label: 'Last Backup Status', columnName: 'lastBackupStatus', size: 'sm' },
  {
    label: 'Last Backup Successful At',
    columnName: 'lastBackupSuccessfulAt',
    size: 'sm',
  },
  {
    label: 'Last Notification Success Backup Sent At',
    columnName: 'lastBackupSuccessSentAt',
    size: 'sm',
  },
  { label: 'Next Backup At', columnName: 'nextBackupAt', size: 'sm' },
  { label: 'Next Smart Backup At', columnName: 'nextSmartBackupAt', size: 'sm' },
  { label: 'Number of files', columnName: 'fileCount', size: 'sm' },
  {
    label: 'Storage used on Latest Backup (in KB)',
    columnName: 'storageUsed',
    size: 'sm',
  },
  {
    label: 'Total Storage Used (in KB)',
    columnName: 'totalStorageUsed',
    size: 'sm',
  },
  { label: 'Total Version', columnName: 'totalVersion', size: 'sm' },
  { label: 'Last Downloaded Snapshot At', columnName: 'lastDownloadedSnapshotAt', size: 'sm' },
  { label: 'Last Downloaded Snapshot Name', columnName: 'lastDownloadedSnapshotName', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
  { label: 'Deactivated Since', columnName: 'deactivatedSince', size: 'sm' },
  { label: 'Deactivated By', columnName: 'deactivatedBy', size: 'sm' },
  { label: 'Deleted At', columnName: 'deletedAt', size: 'sm' },
];
export const SHAREPOINT_DETAIL_ROWS = {
  userBackupId: 'Sharepoint Account ID',
  siteName: 'Site Name',
  domainName: 'Domain Name',
  organizationName: 'Organization Name',
  partnerName: 'Partner Name',
  distributorName: 'Parent Name',
  hasGroup: 'Has Ms Group',
  active: 'Active',
  lastBackupId: 'Last Backup ID',
  lastBackupAt: 'Last Backup At',
  lastBackupSuccessfulAt: 'Last Successful Backup At',
  lastBackupSuccessSentAt: 'Last Notification Success Backup Sent At',
  nextBackupAt: 'Next Backup At',
  nextSmartBackupAt: 'Next Smart Backup At',
  fileCount: 'Number of files',
  storageUsed: {
    title: 'Storage used on Latest Backup',
    sub: 'in KB - Reflecting to the data on Tenant',
  },
  totalStorageUsed: {
    title: 'Total Storage Used',
    sub: 'in KB - Reflecting the usage of s3',
  },
  documentCount: 'Number of Documents',
  documentSize: 'Document Size',
  noteCount: 'Number of Notes',
  noteSize: 'Note Size',
  totalVersion: 'Total Version',
  lastDownloadedSnapshotAt: 'Last Downloaded Snapshot At',
  lastDownloadedSnapshotName: 'Last Downloaded Snapshot Name',
  deactivatedSince: 'Deactivated Since',
  deactivatedBy: 'Deactivated By',
  deletedAt: 'Deleted At',
  modifiedAt: 'Modified At',
  modifiedBy: 'Modified By',
  createdAt: 'Created At',
  updatedAt: 'Last Data Updated At',
};
export const SHAREPOINT_LAST_BACKUP_ROWS = {
  backupId: 'Last Sharepoint Backup ID',
  userBackupId: 'Sharepoint Account ID',
  startTime: 'Start Backup Time',
  endTime: 'End Backup Time',
  status: 'Backup Status',
  backupStorage: 'Backup Storage (in Byte)',
  currentFilesCount: 'Current File Numbers',
  totalFilesCount: 'Total File Numbers',
  retries: 'Retries Time',
  errorMessage: 'Error Message',
  machineIP: 'Run on Machine IP',
  createdAt: 'Created At',
  updatedAt: 'Last Updated At',
};
