import { createAsyncThunk } from '@reduxjs/toolkit';
import { getM365SitesTenantAPI } from '@services/WebApiService';

const getM365SitesTenantAsync = createAsyncThunk(
  'M365SitesTenant/getM365SitesTenant',
  async (paramsList) => {
    try {
      const data = await getM365SitesTenantAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getM365SitesTenantAsync;

const getM365SitesTenantReducers = {
  [pending]: (state) => {
    state.getM365SitesTenantState.fetching = true;
  },
  [rejected]: (state) => {
    state.getM365SitesTenantState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getM365SitesTenantState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getM365SitesTenantState.forbiddenState = true;
      state.getM365SitesTenantState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getM365SitesTenantState.m365SitesTenantCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getM365SitesTenantState.m365SitesTenantInformation = action?.payload?.data?.data;
      if (action?.payload?.data?.pagination) {
        state.getM365SitesTenantState.paginationList = JSON.parse(
          action?.payload?.data?.pagination
        );
      }
      state.getM365SitesTenantState.success = true;
      state.getM365SitesTenantState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getM365SitesTenantState.error = action.payload.error;
    }
  },
};

export { getM365SitesTenantAsync, getM365SitesTenantReducers };
