import { constant } from '@config/BaseSetting';
import { entraIdTenantAsync, resetEntraIdTenantState } from '@features/entraId/entraIdTenantSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const initialFilterState = {
  searchFieldValueText: '',
  searchFieldValueID: '',
  selectedSortColumn: '',
  adFilter: {
    status: '',
    completedSetup: '',
    tenantId: '',
  },
};

const initialPaginationState = {
  pageIndex: 0,
  pageSize: 10,
};

function transformAdFilter(adFilter) {
  if (!adFilter) return [];
  return Object.keys(adFilter).map((k) => {
    return { field: `filter_${k}`, value: adFilter[k]?.trim() };
  });
}

export default function useEntraIdTenantService(organizationOwnerId) {
  const [fetchFlag, setFetchFlag] = useState(true);
  const [matchText, setMatchText] = useState('');
  const [filterState, setFilterState] = useState(initialFilterState);
  const [paginationState, setPaginationState] = useState(initialPaginationState);
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetchFlag) {
      setFetchFlag(false);
      dispatch(
        entraIdTenantAsync({
          accessToken,
          reset: true,
          organizationOwnerId,
          fullTextSearch: filterState.searchFieldValueText?.trim(),
          pageNumber: paginationState.pageIndex + 1,
          pageSize: paginationState.pageSize,
          searchById: filterState.searchFieldValueID,
          orderBy: filterState.selectedSortColumn ?? '',
          filter: transformAdFilter(filterState.adFilter),
        })
      );
    }
  }, [fetchFlag]);

  const updateMatchTag = () => {
    let text = 'Matching ';

    if (filterState.searchFieldValueID) {
      if (text !== 'Matching ') {
        text += ', ';
      }
      text += ` ID = ${filterState.searchFieldValueID}`;
    }

    if (!filterState.searchFieldValueID && filterState.searchFieldValueText) {
      if (text !== 'Matching ') {
        text += ', ';
      }
      text += ` Name/Initial Domain = '${filterState.searchFieldValueText?.trim()}' `;
    }

    Object.keys(filterState.adFilter).forEach((key) => {
      if (filterState.adFilter[key]) {
        if (key === 'status') {
          text += 'Last Backup Status = ';
        } else if (key === 'completedSetup') {
          text += 'Completed Setup = ';
        } else if (key === 'tenantId') {
          text += 'Tenant ID = ';
        }
        text += filterState.adFilter[key]?.trim() ?? '';
        text += ', ';
      }
    });

    if (text === 'Matching ') {
      text = '';
    }

    text = text.replace(/,\s*$/, '');
    setMatchText(text);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateMatchTag();
    setPaginationState({ ...paginationState, pageIndex: 0 });
    setFetchFlag(true);
  };

  const handleReset = () => {
    setFilterState(initialFilterState);
    setPaginationState({ ...paginationState, pageIndex: 0 });
    setMatchText('');
    setFetchFlag(true);
  };

  const handleTextFieldChange = (value) => {
    let searchId = parseInt(value, 10);
    if (Number.isNaN(searchId)) {
      searchId = 0;
    }
    setFilterState({
      ...filterState,
      searchFieldValueText: value,
      searchFieldValueID: searchId,
    });
  };

  const handleAdFilterChange = (field, value) => {
    setFilterState({
      ...filterState,
      adFilter: {
        ...filterState.adFilter,
        [field]: value,
      },
    });
  };

  const handleSortColumnChange = (value) => {
    setFilterState({
      ...filterState,
      selectedSortColumn: value,
    });
  };

  return {
    filterState,
    matchText,
    paginationState,
    setPaginationState,
    handleSubmit,
    handleReset,
    handleTextFieldChange,
    handleAdFilterChange,
    handleSortColumnChange,
  };
}
