export const EMAIL_STATUS = 'messages';
export const EMAIL_STATUS_TEXT = 'Email';

export const CONTACT_STATUS = 'contacts';
export const CONTACT_STATUS_TEXT = 'Contact';

export const CALENDAR_STATUS = 'calendars';
export const CALENDAR_STATUS_TEXT = 'Calendar';

export const TASK_STATUS = 'tasks';
export const TASK_STATUS_TEXT = 'Task';

export const ONE_DRIVE_STATUS = 'od_files';
export const ONE_DRIVE_STATUS_TEXT = 'One Drive';

export const GOOGLE_DRIVE_STATUS = 'gd_files';
export const GOOGLE_DRIVE_STATUS_TEXT = 'Google Drive';

export const MS_GROUP_STATUS = 'ms_groups';
export const MS_GROUP_STATUS_TEXT = 'Groups & Teams';

export const SHAREPOINT_STATUS = 'sharepoint';
export const SHAREPOINT_STATUS_TEXT = 'Sharepoint';

export const SHARED_DRIVE_STATUS = 'sd_files';
export const SHARED_DRIVE_STATUS_TEXT = 'Shared Drive';

export const DOWNLOAD_STATUS_COLUMNS = [
  { label: 'ID', columnName: 'downloadId' },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
    navigateURL: (row) =>
      `/organization-info?id=${row.organizationOwnerId}&name=${row.organizationName}`,
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
    navigateURL: (row) => `/partner-info?id=${row.partnerId}&name=${row.partnerName}`,
  },
  { label: 'Download Status', columnName: 'status', size: 'sm' },
  { label: 'Download Type', columnName: 'downloadableType', size: 'sm' },
  { label: 'Email Account ID', columnName: 'userBackupId' },
  { label: 'Email', columnName: 'email' },
  { label: 'Start Download Time', columnName: 'startTime', size: 'sm' },
  { label: 'End Download Time', columnName: 'endTime', size: 'sm' },
  { label: 'Cloud Storage File Name', columnName: 'cloudStorageFileName', size: 'sm' },
  { label: 'Format', columnName: 'format', size: 'sm' },
  { label: 'Estimated Size', columnName: 'estimatedSize', size: 'sm' },
  { label: 'Download Size', columnName: 'downloadSize', size: 'sm' },
  { label: 'Current Item Number', columnName: 'currentObjectsCount', size: 'sm' },
  { label: 'Total Item Number', columnName: 'totalObjectsCount', size: 'sm' },
  { label: 'Query Params', columnName: 'downloadParams', size: 'sm' },
  { label: 'Message Ids', columnName: 'messageIds', size: 'sm' },
  { label: 'URLs', columnName: 'urls', size: 'sm' },
  { label: 'Retries Time', columnName: 'retries', size: 'sm' },
  { label: 'Error Messages', columnName: 'errorMessage', size: 'sm' },
  { label: 'Search ID', columnName: 'searchId', size: 'sm' },
  { label: 'Search Value', columnName: 'searchValue', size: 'sm' },
  { label: 'Skipped Items', columnName: 'skippedItems', size: 'sm' },
  { label: 'Additional Data', columnName: 'additionalData', size: 'sm' },
  { label: 'Run on Machine IP', columnName: 'machineIp', size: 'sm' },
  { label: 'Deactivated By', columnName: 'deactivatedBy', size: 'sm' },
  { label: 'Deactivated Since', columnName: 'deactivatedSince', size: 'sm' },
  { label: 'Notified At', columnName: 'notifiedAt', size: 'sm' },
  { label: 'Cancelled At', columnName: 'cancelledAt', size: 'sm' },
  { label: 'Expire At', columnName: 'expireAt', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
];

export const systemStatusItems = [
  {
    id: 1,
    value: 'completed',
    title: 'Completed',
  },
  {
    id: 2,
    value: 'running',
    title: 'Running',
  },
  {
    id: 3,
    value: 'error',
    title: 'Error',
  },
];

export const buttonStyle = {
  height: 36,
  backgroundColor: '#a5a5a5',
  textDecoration: 'none',
  color: 'black',
  '&:hover': {
    backgroundColor: '#a5a5a5',
  },
  textTransform: 'unset',
  fontSize: 14,
};
