//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import BaseSetting from '@config/BaseSetting';
import i18n from 'i18next';
import DrawerView from '@config/views/DrawerView';
import I18n from '@config/I18n';

const drawerView = new DrawerView();
const qboI18n = new I18n(BaseSetting.defaultLanguage, BaseSetting.resourcesLocales);

const initialState = {
  locale: i18n.language || BaseSetting.defaultLocale,
  isDrawerOpen: drawerView.isOpen(),
  alert: null,
  requireInitialSetup: null,
  isInitializing: true,
  error: null,
  isApplicationReady: false,
  snackbar: null,
  mailbox: [],
  transferSublink: false,
  seatUsageSublink: false,
  purgeSublink: false,
  tenantSubLink: false,
  handleTenantSubLink: false,
  partnerManagementLink: false,
  transferlink: false,
  subscriptionLink: false,
  toolLink: false,
  billingLink: false,
  organizationLink: false,
  partnerLink: false,
  tenantLink: false,
  systemStatusLink: false,
  handleSublink: false,
  handleOnSublink: false,
  handleOnPurgeSublink: false,
  paginationState: {
    dataIngetionPagination: {},
    dataIngetionListPagination: {},
    purgeMailPagination: {},
    partnersPaginations: {},
    organizationPaginations: {},
    m365TenantPaginations: {},
    gwsTenantPaginations: {},
    hexTenantPaginations: {},
    switchCredentialPaginations: {},
  },
  partnerDetailsState: [],
  organizationDetailState: [],
  m365TenantDetailState: [],
  gwsTenantDetailState: [],
  selectedTabState: {
    tabState: null,
    plansTabState: null,
    organizationTabState: null,
    userTabState: null,
    subResellerTabState: null,
  },
  fixedAlertState: {
    show: false,
    descriptionHtml: null,
    severity: 'success',
  },
  distributorFilterState: {
    forceUpdate: false,
    searchState: '',
    selectedPartner: '',
  },
  partnerFilterState: {
    forceUpdate: false,
    searchState: '',
    selectedPartner: '',
  },
  organizationFilterState: {
    forceUpdate: false,
    searchState: '',
    selectedOrganization: '',
  },
  planFilterState: {
    forceUpdate: false,
    searchState: '',
    selectedPlan: '',
  },
};

const applicationSlice = createSlice({
  name: 'Application',
  initialState,
  reducers: {
    setLocale(state, action) {
      if (qboI18n.availableLocales.includes(action.payload)) {
        // forceUseLocale
        // for forcing uses locale from BaseSetting
        const useLocale = BaseSetting.forceUseLocale || action.payload;

        qboI18n.setLocale(useLocale);
        state.locale = useLocale;
      }
    },
    setIsDrawerOpen(state, action) {
      if (action.payload) {
        drawerView.setOpen();
      } else {
        drawerView.setClose();
      }

      state.isDrawerOpen = action.payload;
    },
    setApplicationReadyStatus(state, action) {
      state.isApplicationReady = action.payload;
    },
    setAlert(state, action) {
      state.alert = {
        type: action.payload.type,
        message: action.payload.message,
        heading: action.payload.heading,
        path: action.payload.path,
        html: action.payload.html,
      };
    },
    clearAlert(state) {
      state.alert = null;
    },
    setMailBoxOpen(state, action) {
      const index = state.mailbox.indexOf(action.payload);
      if (index === -1) state.mailbox = [...state.mailbox, action.payload];
      else state.mailbox = state.mailbox.filter((item) => item !== action.payload);
    },
    setTransferSublink(state, action) {
      state.transferSublink = action.payload.open;
      // state.handleSublink = action.payload.handleSublink;
    },

    setSeatUsageSublink(state, action) {
      state.seatUsageSublink = action.payload.open;
      state.handleOnSublink = action.payload.handleOnSublink;
    },

    setPurgeSublink(state, action) {
      state.purgeSublink = action.payload.open;
      state.handleOnPurgeSublink = action.payload.handleOnPurgeSublink;
    },
    setTenantSublink(state, action) {
      state.tenantSubLink = action.payload.open;
      state.handleTenantSubLink = action.payload.handleTenantSubLink;
    },
    //
    setPartnerManagementLink(state, action) {
      state.partnerManagementLink = action.payload.open;
    },
    setTransferlink(state, action) {
      state.transferlink = action.payload.open;
    },
    setSubscriptionLink(state, action) {
      state.subscriptionLink = action.payload.open;
      state.handleSublink = action.payload.handleSublink;
    },

    setToolLink(state, action) {
      state.toolLink = action.payload.open;
    },

    setBillingLink(state, action) {
      state.billingLink = action.payload.open;
    },
    setOrganizationLink(state, action) {
      state.organizationLink = action.payload.open;
    },
    setPartnerLink(state, action) {
      state.partnerLink = action.payload.open;
    },
    setTenantLink(state, action) {
      state.tenantLink = action.payload.open;
    },
    setSystemStatusLink(state, action) {
      state.systemStatusLink = action.payload.open;
    },
    setPaginationState(state, action) {
      state.paginationState.dataIngetionPagination = action?.payload;
    },

    setPaginationListState(state, action) {
      state.paginationState.dataIngetionListPagination = action?.payload;
    },

    setPurgeMailPaginationState(state, action) {
      state.paginationState.purgeMailPagination = action?.payload;
    },

    setPartnersPaginationState(state, action) {
      state.paginationState.partnersPaginations = action?.payload;
    },

    setPartnerDetailsState(state, action) {
      state.partnerDetailsState = action?.payload;
    },
    setOrganizationDetailState(state, action) {
      state.organizationDetailState = action?.payload;
    },
    setM365TenantDetailState(state, action) {
      state.m365TenantDetailState = action?.payload;
    },
    setGWSTenantDetailState(state, action) {
      state.gwsTenantDetailState = action?.payload;
    },
    setPlanTabState(state, action) {
      state.selectedTabState.plansTabState = action?.payload;
    },
    setOrganizationTabState(state, action) {
      state.selectedTabState.organizationTabState = action?.payload;
    },
    setUserTabState(state, action) {
      state.selectedTabState.userTabState = action?.payload;
    },
    setSubResellerTabState(state, action) {
      state.selectedTabState.subResellerTabState = action?.payload;
    },
    setTabState(state, action) {
      state.selectedTabState.tabState = action?.payload;
    },
    setOrganizationPaginations(state, action) {
      state.paginationState.organizationPaginations = action?.payload;
    },
    setM365TenantPaginations(state, action) {
      state.paginationState.m365TenantPaginations = action?.payload;
    },
    setGWSTenantPaginations(state, action) {
      state.paginationState.gwsTenantPaginations = action?.payload;
    },
    setHEXTenantPaginations(state, action) {
      state.paginationState.hexTenantPaginations = action?.payload;
    },
    setSwitchCredentialPaginations(state, action) {
      state.paginationState.switchCredentialPaginations = action?.payload;
    },
    showFixedAlert(state, action) {
      state.fixedAlertState.show = true;
      state.fixedAlertState.descriptionHtml = action?.payload?.descriptionHtml;
      state.fixedAlertState.severity = action?.payload?.severity || 'success';
    },
    resetFixedAlert(state, action) {
      state.fixedAlertState.show = false;
      state.fixedAlertState.descriptionHtml = null;
      state.fixedAlertState.severity = 'success';
    },
    setDistributorFilterState(state, action) {
      state.distributorFilterState = action?.payload;
    },
    setPartnerFilterState(state, action) {
      state.partnerFilterState = action?.payload;
    },
    setOrganizationFilterState(state, action) {
      state.organizationFilterState = action?.payload;
    },
    setPlanFilterState(state, action) {
      state.planFilterState = action?.payload;
    },
    resetDistributorFilterState(state) {
      state.distributorFilterState = initialState.partnerFilterState;
    },
    resetPartnerFilterState(state) {
      state.partnerFilterState = initialState.partnerFilterState;
    },
    resetOrganizationFilterState(state) {
      state.organizationFilterState = initialState.organizationFilterState;
    },
    resetPlanFilterState(state) {
      state.planFilterState = initialState.planFilterState;
    },
  },
});

export const {
  setLocale,
  setIsDrawerOpen,
  setApplicationReadyStatus,
  setAlert,
  clearAlert,
  setMailBoxOpen,
  setTransferSublink,
  setSeatUsageSublink,
  setPaginationState,
  setPaginationListState,
  setPurgeMailPaginationState,
  setPurgeSublink,
  setPartnerDetailsState,
  setOrganizationDetailState,
  setM365TenantDetailState,
  setGWSTenantDetailState,
  setPlanTabState,
  setOrganizationTabState,
  setPartnersPaginationState,
  setUserTabState,
  setSubResellerTabState,
  setTabState,
  setOrganizationPaginations,
  setTenantSublink,
  setM365TenantPaginations,
  setGWSTenantPaginations,
  setPartnerSublink,
  setPartnerManagementLink,
  setTransferlink,
  setSubscriptionLink,
  setToolLink,
  setBillingLink,
  setOrganizationLink,
  setPartnerLink,
  setTenantLink,
  setSystemStatusLink,
  setHEXTenantPaginations,
  setSwitchCredentialPaginations,
  showFixedAlert,
  resetFixedAlert,
  setDistributorFilterState,
  setPartnerFilterState,
  setOrganizationFilterState,
  setPlanFilterState,
  resetDistributorFilterState,
  resetPartnerFilterState,
  resetOrganizationFilterState,
  resetPlanFilterState,
} = applicationSlice.actions;
export default applicationSlice.reducer;
