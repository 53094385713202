import { createSlice } from '@reduxjs/toolkit';
import {
  entraIdOrganizationAsync,
  entraIdOrganizationReducers,
} from './serviceActions/entraIdOrganizationAsync';

const initialState = {
  entraIdOrganizationState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    entraIdOrganizationInformation: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const entraIdOrganizationSlice = createSlice({
  name: 'EntraIdOrganization',
  initialState,
  reducers: {
    resetEntraIdOrganizationState(state) {
      state.entraIdOrganizationState = initialState.entraIdOrganizationState;
    },
    resetEntraIdOrganizationStatusCodeState(state) {
      state.entraIdOrganizationState.statusCode = initialState.entraIdOrganizationState.statusCode;
    },
  },
  extraReducers: {
    ...entraIdOrganizationReducers,
  },
});

export { entraIdOrganizationAsync };
export const { resetEntraIdOrganizationState, resetEntraIdOrganizationStatusCodeState } =
  entraIdOrganizationSlice.actions;
export default entraIdOrganizationSlice.reducer;
