import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { organizationInformationPath, resellersListPath } from '@config/Routes/WebClientRoutes';
import {
  setOrganizationDetailState,
  setPartnerDetailsState,
} from '@features/application/applicationSlice';
import { fetchDownloadStatusListAsync } from '@features/downloadStatus/downloadStatusInfoSlice';
import { ENTRA_ID } from '@pages/UserBackupPage/pageHooks/constants/entraidConstants';
import { ENTRA_ID_STATUS_COLUMNS } from './constants/entraidStatusConstants';
import { DOWNLOAD_STATUS_COLUMNS } from './constants/downloadStatusConstants';

export default function useDownloadStatusFetching(props) {
  const { defaultFilter, setFilterState, defaultPagination, setPaginationState } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [isReset, setIsReset] = useState(false);
  const [showMesageDialog, setShowMesageDialog] = useState(false);
  const [messageDialog, setMesageDialog] = useState({ title: '', message: '' });

  const handleFetchDownloadStatusListAsync = (
    pageIndex,
    pageSize,
    resellerId,
    OrganizationOwnerId,
    downloadType,
    filters
  ) => {
    dispatch(
      fetchDownloadStatusListAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageNumber: pageIndex,
        pageSize,
        resellerId,
        OrganizationOwnerId,
        downloadType,
        filters,
      })
    );
  };

  const handleResetFilter = () => {
    setIsReset(true);
  };

  useEffect(() => {
    if (isReset) {
      setFilterState(defaultFilter);
      setPaginationState(defaultPagination);
    }
    setIsReset(false);
  }, [isReset]);

  const tableColumns = (backupType) => {
    let columns = [];
    switch (backupType) {
      case ENTRA_ID:
        columns = ENTRA_ID_STATUS_COLUMNS;
        break;
      default:
        columns = DOWNLOAD_STATUS_COLUMNS;
        break;
    }

    // add click able function
    return columns.map((item) => {
      if (
        [
          'errorMessage',
          'cloudStorageFileName',
          'additionalData',
          'downloadParams',
          'urls',
        ].includes(item.columnName)
      ) {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          setShowMesageDialog(true);
          setMesageDialog({ title: item.label, message: row[item.columnName] });
          return '';
        };
      }

      return item;
    });
  };

  return {
    handleFetchDownloadStatusListAsync,
    tableColumns,
    showMesageDialog,
    setShowMesageDialog,
    messageDialog,
  };
}
