import React, { useEffect, useState } from 'react';
import {
  QboAdvanceTable,
  QboCard,
  QboFormGroup,
  QboTextField,
  QboTypography,
  QboAlert,
  ITBox,
  QboButton,
  QboSnackbar,
} from '@ui/Components';
import { useSelector } from 'react-redux';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import RequestModalTool from '@ui/Sections/TenantPage/RequestModalTool';
import LicenseCheckerConfirmation from '@ui/Sections/TenantPage/LicenseCheckerConfirmation';
import InvalidateServiceTokenConfirmation from '@ui/Sections/TenantPage/InvalidateServiceTokenConfirmation';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { isEqual } from 'lodash';
import QboSortByButton from '@ui/Components/QboSortByButton';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import PropTypes from 'prop-types';
import PartnerFilterSection from '@ui/Sections/Shared/PartnerFilterSection';
import OrganizationFilterSection from '@ui/Sections/Shared/OrganizationFilterSection';
import useDownload from '@hooks/useDownload';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useDataService from './pagehooks/useDataService';
import useTenantService from './pagehooks/useTenantService';

export default function M365TenantPage(props) {
  const { organizationOwnerId, organizationOwnerIdProp, paginationProp } = props;
  const { t } = useTranslation();
  const { hasPermission, featureFlags } = useFeatureFlag();
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const { getM365TenantState } = useSelector((state) => state.m365Tenant);
  const { exportM365TenantState } = useSelector((state) => state.exportM365Tenant);
  const { m365TenantInformation, fetching, paginationList } = getM365TenantState;
  const [m365ListData, setM365ListData] = useState([]);
  const [errorMessageTextFiled, setErrorMessageTextFiled] = useState('');
  const [errorMessagePartnerTextFiled, setErrorMessagePartnerTextFiled] = useState('');

  const [pagination, setPagination] = useState({
    pageIndex: paginationProp?.current?.pageIndex || 0,
    pageSize: paginationProp?.current?.pageSize || 10,
  });

  const filterStates = {
    advanceClick: false,
    textFieldValue: '',
    searchFieldValueText: '',
    searchOrgId: organizationOwnerId || 0,
    searchPartnerId: 0,
    searchOrgIdText: '',
    searchPartnerIdText: '',
    searchFieldValueID: '',
    selectedSortColumn: 'id',
    matchText: '',
    resetClicked: false,
    showFilters: false,
  };
  const [filterDataState, setFilterDataState] = useState(filterStates);

  const [adFilter, setAdFilter] = useState([
    { field: 'filter_is_main', value: 'true' },
    { field: 'filter_status', value: '' },
    { field: 'filter_mfa_enabled', value: '' },
    { field: 'filter_ad_sync_enabled', value: '' },
    { field: 'filter_enable_customer_app', value: '' },
    { field: 'filter_use_backup_admin', value: '' },
    { field: 'filter_non_backup_admin_flow', value: '' },
    { field: 'filter_deleted_at', value: 'false' },
    { field: 'filter_migrate_to_non_backup_admin_status', value: '' },
  ]);

  const {
    buttonStyle,
    initialSetupStatus,
    mainCredentails,
    sortColumnNames,
    backUpAdmin,
    spaFlow,
    migratedSpaStatus,
    mfaEnabled,
    adSync,
    customerTenantApp,
    deletedAtFilterValues,
    clickableCoulmnList,
  } = useDataService();

  const {
    fetchParams,
    handleM365TenantAsync,
    handleExport,
    handleResetExport,
    handleOnTextFiledChange,
    handleOnResetButtonClick,
    handleOnHideAdvanceClick,
    handleStateUpdate,
    matchTextHandler,
    getM365DataFromResponse,
    handleUpdateFilters,
    m365TenantHeaders,
    handleOnOwnerIDTextFiledChange,
    handleOnPartnerIDTextFiledChange,
    selectedCredential,
    setSelectedCredential,
    menuActions,
    requestLicenseCheckerModal,
    setRequestLicenseCheckerModal,
    requestAutodiscoverModal,
    setRequestAutodiscoverModal,
    invalidateServiceTokenModal,
    setInvalidateServiceTokenModal,
    hasMenuActions,
  } = useTenantService(
    filterDataState,
    setFilterDataState,
    filterStates,
    organizationOwnerId,
    pagination,
    adFilter,
    setAdFilter,
    setErrorMessageTextFiled,
    setErrorMessagePartnerTextFiled
  );

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const getExportFileName = () => {
    if (fetchParams?.organizationOwnerId) {
      return `organization_${fetchParams.organizationOwnerId}_m365_tenants.csv`;
    }
    if (fetchParams?.partnerID) {
      return `partner_${fetchParams.partnerID}_m365_tenants.csv`;
    }
    return 'm365_tenants.csv';
  };

  useDownload(
    exportM365TenantState.m365TenantExportData,
    'text/csv;charset=utf-8',
    getExportFileName(),
    handleResetExport,
    () => setSnackbarState({ open: true, type: 'success', message: 'Successfully downloaded' }),
    (err) => setSnackbarState({ open: true, type: 'error', message: err })
  );

  const isMainCredentialSelected = adFilter.find((item) => item.field === 'filter_is_main').value;
  const [m365Headers, setM365Headers] = useState(m365TenantHeaders);

  const handleOnSearchClick = (e) => {
    e.preventDefault();
    setPagination({ pageIndex: 0, pageSize: 10 });
    matchTextHandler();
    handleM365TenantAsync(true);
  };

  useEffect(() => {
    const previousProps = paginationProp.current;

    if (
      !organizationOwnerId ||
      organizationOwnerId !== organizationOwnerIdProp?.current ||
      !isEqual(previousProps, pagination)
    ) {
      organizationOwnerIdProp.current = organizationOwnerId;
      paginationProp.current = pagination;
      if (!fetching) {
        handleM365TenantAsync(false);
      }
    }
  }, [pagination]);

  useEffect(() => {
    getM365DataFromResponse(m365TenantInformation, setM365ListData);
  }, [m365TenantInformation]);

  const getM365TenantHeaders = (organizationId) => {
    if (organizationId) {
      const headers = m365TenantHeaders.filter(
        (header) => !['Organization Owner ID', 'Partner ID'].includes(header.label)
      );
      setM365Headers(headers);
    }
  };

  useEffect(() => {
    getM365TenantHeaders(organizationOwnerId);
  }, [organizationOwnerId]);

  const exportDisabled =
    Number(paginationList?.TotalCount || 0) <= 0 ||
    (!fetchParams?.organizationOwnerId && !fetchParams?.partnerID);

  const exportTooltip = () => {
    if (Number(paginationList?.TotalCount || 0) <= 0) {
      return t('tenant_page.tooltip.result_empty');
    }
    if (!fetchParams?.organizationOwnerId && !fetchParams?.partnerID) {
      return t('tenant_page.tooltip.partner_or_org_not_selected');
    }
    return null;
  };

  return (
    <>
      <QboSnackbar onClose={handleSnackbarClose} {...snackbarState} />
      {!organizationOwnerId && <HeaderPageBlock>M365 Tenant Information</HeaderPageBlock>}
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <div style={{ display: 'flex' }}>
            <QboTextField
              id="fullTextSearch"
              label="Credential Filter :"
              explicitStyleClass="FormControl__width-wrapper_40"
              value={filterDataState.textFieldValue}
              isWidthSetExplicit
              placeholder="Credential ID/Tenant ID/Domain"
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                handleOnTextFiledChange(e);
              }}
            />
          </div>
          {!organizationOwnerId && (
            <div style={{ display: 'flex' }}>
              <PartnerFilterSection
                label="Partner:"
                value={filterDataState.searchPartnerIdText}
                marginRightValue={2}
                marginTopValue={3}
                inputSize={180}
                handleChange={(value) => {
                  handleOnPartnerIDTextFiledChange(value);
                }}
              />
              <OrganizationFilterSection
                label="Organization:"
                partnerId={filterDataState.searchPartnerIdText}
                value={filterDataState.searchOrgId}
                marginRightValue={2}
                marginTopValue={3}
                inputSize={180}
                handleChange={(value) => {
                  handleOnOwnerIDTextFiledChange(value);
                }}
              />
            </div>
          )}
          <div style={{ marginTop: 8 }}>
            <Button
              disableRipple
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => {
                handleStateUpdate('advanceClick', !filterDataState.advanceClick);
                handleOnHideAdvanceClick();
              }}>
              {filterDataState.advanceClick
                ? t('partner_details_page.button.hide_advance_search')
                : t('partner_details_page.button.show_advance_search')}
            </Button>
          </div>
          {filterDataState.advanceClick && (
            <div>
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={adFilter.find((item) => item.field === 'filter_is_main').value}
                buttonWidth={220}
                startAdornmentTextWidth={190}
                startAdornmentText="Main Credential :"
                handleChange={(e) => handleUpdateFilters('filter_is_main', e.target.value)}
                menuItemList={mainCredentails}
              />
              {isMainCredentialSelected === 'true' && (
                <div>
                  <QboSortByButton
                    defaultValue="All"
                    marginTopValue={2}
                    marginRightValue={2}
                    value={adFilter.find((item) => item.field === 'filter_status').value}
                    buttonWidth={220}
                    startAdornmentTextWidth={230}
                    startAdornmentText="Initial Setup Status :"
                    handleChange={(e) => handleUpdateFilters('filter_status', e.target.value)}
                    menuItemList={initialSetupStatus}
                  />

                  <QboSortByButton
                    defaultValue="All"
                    marginTopValue={2}
                    marginRightValue={2}
                    value={adFilter.find((item) => item.field === 'filter_mfa_enabled').value}
                    buttonWidth={220}
                    startAdornmentTextWidth={140}
                    startAdornmentText="MFA Enabled :"
                    handleChange={(e) => handleUpdateFilters('filter_mfa_enabled', e.target.value)}
                    menuItemList={mfaEnabled}
                  />

                  <QboSortByButton
                    defaultValue="All"
                    marginTopValue={2}
                    marginRightValue={2}
                    value={adFilter.find((item) => item.field === 'filter_ad_sync_enabled').value}
                    buttonWidth={220}
                    startAdornmentTextWidth={230}
                    startAdornmentText="Ad Sync Department :"
                    handleChange={(e) =>
                      handleUpdateFilters('filter_ad_sync_enabled', e.target.value)
                    }
                    menuItemList={adSync}
                  />

                  <QboSortByButton
                    defaultValue="All"
                    marginTopValue={2}
                    marginRightValue={2}
                    value={
                      adFilter.find((item) => item.field === 'filter_enable_customer_app').value
                    }
                    buttonWidth={220}
                    startAdornmentTextWidth={300}
                    startAdornmentText="Customer Tenant App :"
                    handleChange={(e) =>
                      handleUpdateFilters('filter_enable_customer_app', e.target.value)
                    }
                    menuItemList={customerTenantApp}
                  />

                  <QboSortByButton
                    defaultValue="All"
                    marginTopValue={2}
                    marginRightValue={2}
                    value={adFilter.find((item) => item.field === 'filter_use_backup_admin').value}
                    buttonWidth={220}
                    startAdornmentTextWidth={190}
                    startAdornmentText="Backup Admin :"
                    handleChange={(e) =>
                      handleUpdateFilters('filter_use_backup_admin', e.target.value)
                    }
                    menuItemList={backUpAdmin}
                  />

                  <QboSortByButton
                    defaultValue="All"
                    marginTopValue={2}
                    marginRightValue={2}
                    value={
                      adFilter.find((item) => item.field === 'filter_non_backup_admin_flow').value
                    }
                    buttonWidth={220}
                    startAdornmentTextWidth={90}
                    startAdornmentText="SPA flow :"
                    handleChange={(e) =>
                      handleUpdateFilters('filter_non_backup_admin_flow', e.target.value)
                    }
                    menuItemList={spaFlow}
                  />

                  <QboSortByButton
                    defaultValue="All"
                    marginTopValue={2}
                    marginRightValue={2}
                    value={
                      adFilter.find(
                        (item) => item.field === 'filter_migrate_to_non_backup_admin_status'
                      ).value
                    }
                    buttonWidth={220}
                    startAdornmentTextWidth={400}
                    startAdornmentText="Migrated to SPA Status :"
                    handleChange={(e) =>
                      handleUpdateFilters(
                        'filter_migrate_to_non_backup_admin_status',
                        e.target.value
                      )
                    }
                    menuItemList={migratedSpaStatus}
                  />
                  <QboSortByButton
                    defaultValue="All"
                    marginTopValue={2}
                    marginRightValue={2}
                    value={adFilter.find((item) => item.field === 'filter_deleted_at')?.value}
                    buttonWidth={220}
                    startAdornmentTextWidth={90}
                    startAdornmentText="Deleted :"
                    handleChange={(e) => handleUpdateFilters('filter_deleted_at', e.target.value)}
                    menuItemList={deletedAtFilterValues}
                  />
                </div>
              )}
              <div>
                <QboSortByButton
                  defaultValue="None"
                  marginTopValue={2}
                  marginRightValue={2}
                  value={filterDataState.selectedSortColumn}
                  buttonWidth={220}
                  startAdornmentTextWidth={70}
                  startAdornmentText="Sort by:"
                  handleChange={(e) => handleStateUpdate('selectedSortColumn', e.target.value)}
                  menuItemList={sortColumnNames}
                />
              </div>
            </div>
          )}
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <hr />
        <ITBox
          direction="row"
          sx={{
            margin: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <QboTypography
            sx={{
              fontSize: 18,
              color: '#000000DE',
              fontWeight: 600,
            }}>
            M365 Tenant List
          </QboTypography>
          {hasPermission(featureFlags.RunExportM365TenantInfo) && (
            <QboButton
              disabled={exportDisabled}
              withTitle={exportDisabled}
              title={exportTooltip()}
              style={{ width: 120 }}
              variant="contained"
              onClick={handleExport}
              loading={exportM365TenantState.fetching}>
              {t('export_csv')}
            </QboButton>
          )}
        </ITBox>
        {!filterDataState.resetClicked && filterDataState.matchText ? (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'pre-wrap',
            }}>
            {!filterDataState.resetClicked && filterDataState.matchText}
          </QboTypography>
        ) : null}
        {(m365ListData.length > 0 || fetching) && (
          <QboAdvanceTable
            isLoading={fetching}
            enableTopToolBar={false}
            headers={m365Headers}
            rows={m365ListData}
            rowsCount={Number(paginationList?.TotalCount || 0)}
            pagination={pagination}
            setPagination={setPagination}
            enableColumnClickable={!false}
            columnsClickableList={clickableCoulmnList}
            renderRowActionMenuItems={menuActions}
            enableRowActions={hasMenuActions}
          />
        )}
        {m365ListData.length === 0 && !fetching && <NoStatusSection />}
      </QboCard>

      <RequestModalTool
        key={featureFlags.RunLicenseChecker}
        serviceType={featureFlags.RunLicenseChecker}
        selectedCredential={selectedCredential}
        showRequestModal={requestLicenseCheckerModal}
        title={t('tenant_page.run_license_checker.title')}
        closeSetupModal={() => {
          setRequestLicenseCheckerModal(false);
        }}
        topBarElement={
          <QboAlert
            variant="outlined"
            type="info"
            style={{
              border: '1px solid #ebebeb',
              background: '#f3f3f3',
              fontSize: '10px !important',
              marginTop: '20px',
            }}>
            <QboTypography
              noWrap={false}
              style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
              {t('tenant_page.run_license_checker.information')}
            </QboTypography>
          </QboAlert>
        }>
        <LicenseCheckerConfirmation selectedCredential={selectedCredential} />
      </RequestModalTool>

      <RequestModalTool
        key={featureFlags.RunAutodiscover}
        serviceType={featureFlags.RunAutodiscover}
        selectedCredential={selectedCredential}
        showRequestModal={requestAutodiscoverModal}
        title={t('tenant_page.run_autodiscover.title')}
        closeSetupModal={() => {
          setRequestAutodiscoverModal(false);
        }}>
        <LicenseCheckerConfirmation selectedCredential={selectedCredential} />
      </RequestModalTool>

      <RequestModalTool
        key={featureFlags.InvalidateServiceToken}
        serviceType={featureFlags.InvalidateServiceToken}
        selectedCredential={selectedCredential}
        showRequestModal={invalidateServiceTokenModal}
        title={t('tenant_page.invalidate_service_token.title')}
        closeSetupModal={() => {
          setInvalidateServiceTokenModal(false);
        }}
        topBarElement={
          <QboAlert
            variant="outlined"
            type="info"
            style={{
              border: '1px solid #ebebeb',
              background: '#f3f3f3',
              fontSize: '10px !important',
              marginTop: '20px',
            }}>
            <QboTypography
              noWrap={false}
              style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
              {t('tenant_page.invalidate_service_token.information')}
            </QboTypography>
          </QboAlert>
        }>
        <InvalidateServiceTokenConfirmation selectedCredential={selectedCredential} />
      </RequestModalTool>
    </>
  );
}

M365TenantPage.propTypes = {
  organizationOwnerId: PropTypes.number,
  organizationOwnerIdProp: PropTypes.object,
  paginationProp: PropTypes.object,
};

M365TenantPage.defaultProps = {
  organizationOwnerId: null,
  organizationOwnerIdProp: {},
  paginationProp: {},
};
