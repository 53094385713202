import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const SORMonitoringService = {
  sorMonitoringListAPI: (paramsList = {}) => {
    const { accessToken, reset, pageNumber, pageSize, filters } = paramsList;
    const params = {
      filters: [
        {
          field: 'filter_status',
          value: filters.status || '0',
        },
        {
          field: 'filter_startDate',
          value: filters.startDate,
        },
        {
          field: 'filter_endDate',
          value: filters.endDate,
        },
      ],
      'order-by': filters.orderBy || 'id',
    };

    const response = webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.soRMonitoringListPath({
        pageNumber,
        pageSize,
      }),
      parameters: params,
    });

    return response;
  },
  sorMonitoringDetailAPI: (paramsList = {}) => {
    const { accessToken, reset, auditTrailsId, pageNumber, pageSize, filters } = paramsList;
    const params = {
      filters: [
        {
          field: 'filter_status',
          value: filters.status || '0',
        },
        {
          field: 'filter_entityType',
          value: filters.entityType,
        },
        {
          field: 'filter_entityRole',
          value: filters.entityRole,
        },
        {
          field: 'filter_hasRetry',
          value: filters.hasRetry,
        },
      ],
      'order-by': filters.orderBy || 'id',
    };

    const response = webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.soRMonitoringDetailsPath({
        pageNumber,
        pageSize,
        auditTrailsId,
      }),
      parameters: params,
    });

    return response;
  },
  sorMonitoringResponseAPI: (paramsList = {}) => {
    const { accessToken, reset, auditTrailsDetailId } = paramsList;
    const response = webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.soRMonitoringResponsePath({ auditTrailsDetailId }),
    });

    return response;
  },
};

export default SORMonitoringService;
