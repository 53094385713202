import { createSlice } from '@reduxjs/toolkit';

import {
  userBackupReportAsync,
  userBackupReportExtraReducers,
} from './serviceActions/userBackupReportAsync';

const initialState = {
  userBackupReportState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    userBackupReportCorsErrorMessage: null,
    serviceType: null,
  },
};

const userBackupReportSlice = createSlice({
  name: 'userBackupReport',
  initialState,
  reducers: {
    resetUserBackupReportState(state) {
      state.userBackupReportState = initialState.userBackupReportState;
    },
  },
  extraReducers: {
    ...userBackupReportExtraReducers,
  },
});

export { userBackupReportAsync };

export const { resetUserBackupReportState } = userBackupReportSlice.actions;
export default userBackupReportSlice.reducer;
