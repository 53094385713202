import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { getPlansFilterListAsync } from '@features/plansList/plansFilterListSlice';
import { setPlanFilterState } from '@features/application/applicationSlice';

export default function usePlanFilterHook(resellerId, externalValue) {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { getPlansFilterListState } = useSelector((state) => state.plansFilterList);
  const { plansFilterList } = getPlansFilterListState;
  const { planFilterState } = useSelector((stateList) => stateList.application);

  const [planList, setPlanList] = useState([]);

  const handlePlanFilterSearch = (search, reseller) => {
    dispatch(
      getPlansFilterListAsync({
        accessToken: accessTokenSet,
        reset: true,
        ...{ searchTerm: search, resellerId: reseller },
      })
    );
  };

  const responseConverter = (data) => {
    return data.map((item) => ({
      id: item?.id,
      value: item?.id,
      title: `${item?.id} - ${item.planName} - ${item.planCurrency} ${item.planPrice?.toFixed(2)}`,
    }));
  };

  const resetPlanFilter = () => {
    dispatch(
      setPlanFilterState({
        forceUpdate: !planFilterState.forceUpdate,
        searchState: '',
        selectedPlan: '',
      })
    );
    handlePlanFilterSearch('', resellerId);
  };

  const UpdateFilters = (value, properties) => {
    const newValue = value;
    const newState = { ...planFilterState };
    properties.forEach((property) => {
      newState[property] = newValue;
    });
    dispatch(setPlanFilterState(newState));
  };

  useEffect(() => {
    if (planFilterState.searchState.length === 0 || planFilterState.searchState.length > 2)
      handlePlanFilterSearch(planFilterState.searchState, resellerId);
  }, [planFilterState.searchState]);

  useEffect(() => {
    setPlanList(responseConverter(plansFilterList));
  }, [plansFilterList]);

  useEffect(() => {
    UpdateFilters('', ['selectedPlan']);
    handlePlanFilterSearch('', resellerId);
  }, [resellerId]);

  useEffect(() => {
    if (externalValue === planFilterState.selectedPlan) return;
    UpdateFilters(externalValue || '', ['selectedPlan']);
  }, [externalValue]);

  return {
    planFilterState,
    resetPlanFilter,
    UpdateFilters,
    planList,
  };
}
