export default function useDataService() {
  const gwsSharedDrivesTenantHeaders = [
    { id: 1, label: 'GWS ID', columnName: 'id', sx: { paddingLeft: 2 } },
    {
      id: 2,
      label: 'Name',
      columnName: 'name',
      sx: { paddingLeft: 2 },
    },
    {
      id: 3,
      label: 'Organization Id',
      columnName: 'organizationId',
      sx: { paddingLeft: 2 },
    },
    {
      id: 4,
      label: 'Active',
      columnName: 'active',
      sx: { paddingLeft: 2 },
    },
    {
      id: 5,
      label: 'Order',
      columnName: 'order',
      sx: { paddingLeft: 2 },
    },
  ];

  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const filterStates = {
    advanceClick: false,
    textFieldValue: '',
    searchFieldValueText: '',
    searchFieldValueID: '',
    matchText: '',
    resetClicked: false,
  };

  return {
    gwsSharedDrivesTenantHeaders,
    buttonStyle,
    filterStates,
  };
}
