import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { sorMonitoringDetailPath } from '@config/Routes/WebClientRoutes';
import { fetchSORMonitoringListAsync } from '@features/sorMonitoring/sorMonitoringSlice';
import { SOR_MONITORING_COLUMNS } from './constants/sorMonitoringConstants';

export default function useSORMonitoringFetching(props) {
  const { defaultFilter, setFilterState, defaultPagination, setPaginationState } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [isReset, setIsReset] = useState(false);
  const [showMesageDialog, setShowMesageDialog] = useState(false);
  const [messageDialog, setMesageDialog] = useState({ title: '', message: '' });

  const handleFetchSORMonitoringListAsync = (pageIndex, pageSize, filters) => {
    dispatch(
      fetchSORMonitoringListAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageNumber: pageIndex,
        pageSize,
        filters,
      })
    );
  };

  const handleResetFilter = () => {
    setIsReset(true);
  };

  useEffect(() => {
    if (isReset) {
      setFilterState(defaultFilter);
      setPaginationState(defaultPagination);
    }
    setIsReset(false);
  }, [isReset]);

  const tableColumns = () => {
    const columns = SOR_MONITORING_COLUMNS;

    // add click able function
    return columns.map((item) => {
      if (item.columnName === 'id') {
        // eslint-disable-next-line no-param-reassign
        item.navigateURL = (row) => {
          const queryParams = createSearchParams({ id: row.id }).toString();
          return `${sorMonitoringDetailPath}?${queryParams}`;
        };
      }

      return item;
    });
  };

  return {
    handleFetchSORMonitoringListAsync,
    tableColumns,
    showMesageDialog,
    setShowMesageDialog,
    messageDialog,
  };
}
