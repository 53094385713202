import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import BaseSetting from '@config/BaseSetting';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  ITBox,
  QboAdvanceTable,
  QboButton,
  QboCard,
  QboFormGroup,
  QboModal,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import QboSortByButton from '@ui/Components/QboSortByButton';
import useUserClaims from '@hooks/useUserClaims';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { resetPlansListState } from '@features/plansList/plansListSlice';
import { setPlanTabState } from '@features/application/applicationSlice';
import StepperBlock from '@ui/Blocks/Shared/StepperBlock';
import SpecialPricingFormSection from '@ui/Sections/PlansListPage/SpecialPricingFormSection';
import SpecialPricingConfirmationSection from '@ui/Sections/PlansListPage/SpecialPricingConfirmationSection';
import useDataService from './pageHooks/useDataService';
import usePlansService from './pageHooks/usePlansService';
import usePricingService from './pageHooks/usePricingService';

export default function PlansListPage(props) {
  const { resellerID, tabFlag, selectTab, plan } = props;
  const dispatch = useDispatch();
  const claims = useUserClaims();

  const { t } = useTranslation();
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const previousPropsRef = useRef(pagination);
  const [advanceClick, setAdvanceClick] = useState(false);
  const [matchText, setMatchText] = useState('');
  const [statusChange, setStatusChange] = useState('');
  const [searchByID, setSearchByID] = useState('');
  const [fullTextSearch, setFullTextSearch] = useState('');
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const [licenceTypeState, setLicenceTypeState] = useState('');
  const [planType, setPlanType] = useState('');
  const [resetClicked, setResetClicked] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [forceUpdate, setForceUpdate] = useState(false);
  const [searchState, setSearchState] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [partnerNames, setPartnerNames] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');
  const {
    planStatusList,
    planTypes,
    columnNames,
    buttonStyle,
    licenseType,
    partnerNamesList,
    clickableCoulmnList,
  } = useDataService();
  const { getPlansListState } = useSelector((state) => state.plansList);
  const { fetching, plansList, paginationList: paginations, statusCode } = getPlansListState;
  const { selectedTabState } = useSelector((state) => state.application);
  const { partnerDetailState } = useSelector((state) => state.partnerDetail);
  const filterPlanName = plan ?? '';
  const { getPartnersFilterListState } = useSelector((state) => state.partnersFilterList);
  const { partnersFilterList } = getPartnersFilterListState;
  const resetParams = [
    { field: 'filter_planStatus', value: '' },
    { field: 'filter_license', value: '' },
    { field: 'filter_planType', value: '' },
    { field: 'filter_partnerName', value: '' },
    { field: 'filter_planName', value: filterPlanName },
  ];

  const [adFilter, setAdFilter] = useState([
    { field: 'filter_planStatus', value: '' },
    { field: 'filter_license', value: '' },
    { field: 'filter_planType', value: '' },
    { field: 'filter_partnerName', value: '' },
    { field: 'filter_planName', value: filterPlanName },
  ]);

  const handleUpdateFilters = (label, val) => {
    const newList = adFilter.map((ele) => {
      if (ele.field === label) {
        return { ...ele, value: val };
      }
      return ele;
    });
    setAdFilter([...newList]);
  };

  const {
    handleOnAdvanceSearchClick,
    handleOnPlansAsync,
    handleOnSerchFieldChange,
    matchingText,
    handleOnCloseClick,
    handlePartnerNameChange,
    handlePartnerFilterSearch,
  } = usePlansService(
    setAdvanceClick,
    advanceClick,
    setSearchByID,
    setFullTextSearch,
    setSearchFieldValue,
    setSearchTerm,
    setSelectedPartner,
    handleUpdateFilters,
    searchTerm
  );

  const {
    form,
    stepper,
    openSetupModal,
    closeSetupModal,
    showRequestModal,
    setShowRequestModal,
    onSubmitForm,
    onStepNext,
    onStepBack,
    validList,
    setValidList,
    inValidList,
    setInValidList,
  } = usePricingService();

  const fetchApiHandler = (
    searchID,
    searchText,
    planName,
    partner,
    status,
    licence,
    columnSelected,
    paginationList,
    filters
  ) => {
    setMatchText('');
    handleOnPlansAsync(resellerID, searchID, searchText, columnSelected, paginationList, filters);
    matchingText(searchByID, searchText, planName, partner, status, licence, setMatchText);
  };

  useEffect(() => {
    const previousProps = previousPropsRef.current;
    if (selectedTabState?.plansTabState !== selectTab || !isEqual(previousProps, pagination)) {
      previousPropsRef.current = pagination;
      fetchApiHandler(
        searchByID,
        fullTextSearch,
        selectedColumn,
        planType,
        partnerName,
        statusChange,
        licenceTypeState,
        pagination,
        adFilter
      );
    }
  }, [pagination]);

  useEffect(() => {
    if (!resellerID) {
      dispatch(setPlanTabState(null));
    }
  }, [resellerID]);

  const filterResetHandler = () => {
    setAdFilter([
      { field: 'filter_planStatus', value: '' },
      { field: 'filter_license', value: '' },
      { field: 'filter_planType', value: '' },
      { field: 'filter_partnerName', value: '' },
      { field: 'filter_planName', value: filterPlanName },
    ]);
  };

  useEffect(() => {
    if (!advanceClick) {
      filterResetHandler();
    }
  }, [advanceClick]);

  useEffect(() => {
    if (searchTerm.length === 0 || searchTerm.length > 2) {
      handlePartnerFilterSearch(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    const convertedResponse = partnersFilterList
      .filter(
        (item) =>
          item?.partnerName !== undefined && item?.partnerName !== null && item?.partnerName !== ''
      )
      .map((item) => ({
        id: item.id,
        value: item.partnerName,
        title: item.partnerName,
      }));
    setPartnerNames(convertedResponse);
  }, [partnersFilterList]);

  const handleOnSearchClick = (e) => {
    setResetClicked(false);
    e.preventDefault();
    fetchApiHandler(
      searchByID,
      fullTextSearch,
      planType,
      partnerName,
      statusChange,
      licenceTypeState,
      selectedColumn,
      {
        pageIndex: 0,
        pageSize: 10,
      },
      adFilter
    );
  };

  useEffect(() => {
    if ((selectTab === 0 || selectTab) && selectedTabState?.plansTabState !== selectTab) {
      dispatch(setPlanTabState(selectTab));
    }
  }, [selectTab]);

  const handleOnResetButtonClick = () => {
    setMatchText('');
    setResetClicked(true);
    setSearchByID('');
    setStatusChange('');
    setSearchFieldValue('');
    setPartnerName('');
    setSelectedColumn('');
    setFullTextSearch('');
    setPlanType('');
    filterResetHandler();
    setLicenceTypeState('');
    setSelectedPartner('');
    dispatch(resetPlansListState());
    fetchApiHandler(0, '', '', '', '', '', '', { pageIndex: 0, pageSize: 10 }, resetParams);
  };

  return (
    <>
      {!tabFlag && <HeaderPageBlock>Plans List</HeaderPageBlock>}
      <QboCard>
        <QboCard isMainWrapper>
          <QboFormGroup
            onSubmit={(e) => handleOnSearchClick(e)}
            buttonPostion="start"
            buttonStyle={buttonStyle}
            handleOnResetButton={handleOnResetButtonClick}
            firstButtonLabel={t('partner_details_page.button.search')}
            withGenerateAndResetButton>
            <QboTextField
              id="planName"
              value={searchFieldValue}
              isWidthSetExplicit
              placeholder="ID/Plan Name"
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                handleOnSerchFieldChange(e);
              }}
            />
            <div style={{ marginTop: 8 }}>
              <Button
                disableRipple
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  textTransform: 'none',
                }}
                onClick={() => {
                  handleOnAdvanceSearchClick();
                }}>
                {advanceClick
                  ? t('partner_details_page.button.hide_advance_search')
                  : t('partner_details_page.button.show_advance_search')}
              </Button>
            </div>
            {advanceClick && (
              <div>
                <QboSortByButton
                  forceUpdate={forceUpdate}
                  searchState={searchState}
                  handleCloseClick={handleOnCloseClick}
                  isSearchEnable={!false}
                  handleOnSearchChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  handleChange={(e) => {
                    handlePartnerNameChange(e);
                  }}
                  marginRightValue={2}
                  marginTopValue={2}
                  value={selectedPartner}
                  buttonWidth={220}
                  startAdornmentTextWidth={180}
                  startAdornmentText="Partner Name:"
                  menuItemList={partnerNames}
                />

                <QboSortByButton
                  defaultValue="All"
                  marginRightValue={2}
                  marginTopValue={2}
                  value={planType}
                  buttonWidth={220}
                  startAdornmentTextWidth={110}
                  startAdornmentText="Plan Type:"
                  handleChange={(e) => {
                    setPlanType(e.target.value);
                    handleUpdateFilters('filter_planType', e.target.value);
                  }}
                  menuItemList={planTypes}
                />
                <QboSortByButton
                  defaultValue="All"
                  marginTopValue={2}
                  marginRightValue={2}
                  value={statusChange}
                  buttonWidth={220}
                  startAdornmentTextWidth={110}
                  startAdornmentText="Plan Status:"
                  handleChange={(e) => {
                    setStatusChange(e.target.value);
                    handleUpdateFilters('filter_planStatus', e.target.value);
                  }}
                  menuItemList={planStatusList}
                />
                {/* <QboSortByButton
                  defaultValue="All"
                  marginRightValue={2}
                  marginTopValue={2}
                  value={partnerName}
                  buttonWidth={220}
                  startAdornmentTextWidth={150}
                  startAdornmentText="Partner Name:"
                  handleChange={(e) => {
                    setPartnerName(e.target.value);
                    handleUpdateFilters('filter_partnerName', e.target.value);
                  }}
                  menuItemList={partnerNamesList}
                /> */}
                <QboSortByButton
                  defaultValue="All"
                  marginRightValue={2}
                  marginTopValue={2}
                  value={licenceTypeState}
                  buttonWidth={220}
                  startAdornmentTextWidth={150}
                  startAdornmentText="License Type:"
                  handleChange={(e) => {
                    setLicenceTypeState(e.target.value);
                    handleUpdateFilters('filter_license', e.target.value);
                  }}
                  menuItemList={licenseType}
                />
                <div>
                  <QboSortByButton
                    defaultValue="None"
                    marginTopValue={2}
                    marginRightValue={2}
                    value={selectedColumn}
                    buttonWidth={220}
                    startAdornmentTextWidth={70}
                    startAdornmentText="Sort by:"
                    handleChange={(e) => {
                      setSelectedColumn(e.target.value);
                    }}
                    menuItemList={columnNames}
                  />
                </div>
              </div>
            )}
          </QboFormGroup>
        </QboCard>

        <ITBox
          direction="row"
          sx={{
            margin: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <QboTypography
            sx={{
              fontSize: 18,
              color: '#000000DE',
              fontWeight: 600,
            }}>
            Plan List
          </QboTypography>
          {claims?.role && ['finance'].includes(claims?.role?.toLowerCase()) && (
            <QboButton
              style={{ width: 150 }}
              variant="contained"
              onClick={() => {
                openSetupModal();
              }}
              loading={plansList.fetching}>
              Change Price
            </QboButton>
          )}
        </ITBox>
        {plansList.length > 0 && statusCode ? (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
            }}>
            {!resetClicked && matchText}
          </QboTypography>
        ) : null}

        {(plansList?.length > 0 || fetching) && (
          <QboAdvanceTable
            isLoading={fetching}
            enableSorting
            enableTopToolBar={false}
            headers={BaseSetting.plansListHeaders}
            rows={plansList}
            pagination={pagination}
            setPagination={setPagination}
            rowsCount={paginations?.TotalCount}
            columnsClickableList={clickableCoulmnList}
            enableColumnClickable={!false}
          />
        )}
        {plansList?.length === 0 && !fetching && <NoStatusSection />}
      </QboCard>

      <QboModal
        onClose={closeSetupModal}
        open={showRequestModal}
        title={t('plans_list_page.modal.title_text')}
        showCloseIcon
        preventOutsideClick>
        <StepperBlock
          steps={stepper.steps}
          icons={stepper.stepIcons}
          hideLabel
          activeStepAlt={stepper.activeStep}
          isStepOptionalAlt={stepper.isStepOptional}
          isStepSkippedAlt={stepper.isStepOptional}>
          <SpecialPricingFormSection
            requestTypeOptions={form.requestTypes}
            onSubmit={onStepNext}
            resetForm={form.resetForm}
            formData={form.attributes}
            isValid={form.isValid}
          />
          <SpecialPricingConfirmationSection
            onSubmit={onSubmitForm}
            formData={form.attributes}
            onBack={onStepBack}
            onCloseModal={closeSetupModal}
            detailState={partnerDetailState}
            validList={validList}
            setValidList={setValidList}
            inValidList={inValidList}
            setInValidList={setInValidList}
          />
        </StepperBlock>
      </QboModal>
    </>
  );
}

PlansListPage.propTypes = {
  resellerID: PropTypes.number,
  selectTab: PropTypes.number,
  tabFlag: PropTypes.bool,
  plan: PropTypes.string,
};

PlansListPage.defaultProps = {
  resellerID: 0,
  selectTab: null,
  tabFlag: false,
  plan: '',
};
