import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOwnerListApi } from '@services/WebApiService';

const ownerListAsync = createAsyncThunk('DmeSettings/ownerList', async (paramsList) => {
  try {
    const data = await getOwnerListApi(paramsList);
    return data;
  } catch (error) {
    return { error: error.response };
  }
});

const { pending, fulfilled, rejected } = ownerListAsync;

const ownerListReducers = {
  [pending]: (state) => {
    state.ownerListState.fetching = true;
  },
  [rejected]: (state) => {
    state.ownerListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.ownerListState.fetching = false;

    if (action?.payload?.error) {
      state.ownerListState.errorMessage = action.payload.error?.data.message;
      state.ownerListState.statusCode = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.ownerListState.forbiddenState = true;
        state.ownerListState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.error && !action?.payload?.data) {
      state.ownerListState.errorMessage = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.ownerListState.forbiddenState = false;
      state.ownerListState.forbiddenErrorMessage = {};
      state.ownerListState.statusCode = action.payload?.status;
      state.ownerListState.data = action?.payload?.data?.data;
    }
  },
};

export { ownerListAsync, ownerListReducers };
