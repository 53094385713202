import React from 'react';
import PropTypes from 'prop-types';
import { ITBox, QboLabelGroup } from '@ui/Components';
import { Grid } from '@mui/material';

function UserBackupReportConfirmation({ selectedAccount }) {
  return (
    <ITBox
      className="TransferSubresellerPage__information-box"
      direction="column"
      sx={{ marginBottom: '20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <QboLabelGroup
            title="Organization Owner ID"
            description={selectedAccount?.organizationId?.toString() || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <QboLabelGroup
            title="Organization Name"
            description={selectedAccount?.organizationName || '-'}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <QboLabelGroup
            title="Partner ID"
            description={selectedAccount?.partnerId?.toString() || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <QboLabelGroup
            title="Partner Organization Name"
            description={selectedAccount?.partnerName?.toString() || '-'}
          />
        </Grid>
      </Grid>

      <QboLabelGroup title="email" description={selectedAccount?.email || '-'} />
      <QboLabelGroup
        title="Email Account ID"
        description={selectedAccount?.userBackupId?.toString() || '-'}
      />
    </ITBox>
  );
}

UserBackupReportConfirmation.propTypes = {
  selectedAccount: PropTypes.object,
};

UserBackupReportConfirmation.defaultProps = {
  selectedAccount: null,
};

export default UserBackupReportConfirmation;
