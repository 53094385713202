import { createSlice } from '@reduxjs/toolkit';
import {
  getBulkMailboxDeactivationItemsAsync,
  getBulkMailboxDeactivationItemsExtraReducers,
} from './serviceActions/getBulkMailboxDeactivationItemsAsync';

const initialState = {
  getBulkMailboxDeactivationItemsState: {
    fetching: false,
    statusCode: null,
    list: [],
    errorMessage: null,
    pagination: {},
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const getBulkMailboxDeactivationItemsSlice = createSlice({
  name: 'getBulkMailboxDeactivationItems',
  initialState,
  reducers: {
    resetGetBulkMailboxDeactivationItemsState(state) {
      state.getBulkMailboxDeactivationItemsState =
        initialState.getBulkMailboxDeactivationItemsState;
    },
  },
  extraReducers: {
    ...getBulkMailboxDeactivationItemsExtraReducers,
  },
});

export { getBulkMailboxDeactivationItemsAsync };

export const { resetGetBulkMailboxDeactivationItemsState } =
  getBulkMailboxDeactivationItemsSlice.actions;
export default getBulkMailboxDeactivationItemsSlice.reducer;
