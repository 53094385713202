import {
  hexTenantDetailPath,
  organizationInformationPath,
  resellersListPath,
} from '@config/Routes/WebClientRoutes';

export default function useDataService() {
  const entraIdTenantHeaders = [
    {
      label: 'ID',
      columnName: 'id',
      sx: { paddingLeft: 2 },
      size: 50,
    },
    {
      label: 'Tenant ID',
      columnName: 'tenantId',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Organization Name',
      columnName: 'organizationName',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Partner Name',
      columnName: 'partnerName',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Name',
      columnName: 'name',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Initial Domain',
      columnName: 'initialDomain',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Next Backup At',
      columnName: 'nextBackupAt',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Last Backup ID',
      columnName: 'lastBackupId',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Last Backup Status',
      columnName: 'lastBackupStatus',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Last Successful Backup At',
      columnName: 'lastBackupSuccessfulAt',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Object Count',
      columnName: 'objectCount',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Country',
      columnName: 'country',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Permission Status',
      columnName: 'permissionStatus',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'CreatedAt',
      columnName: 'createdAt',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'DeletedAt',
      columnName: 'deletedAt',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Deactivated Since',
      columnName: 'deactivatedSince',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Deactivation Reason',
      columnName: 'deactivatedBy',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Backup Frequency',
      columnName: 'backupFrequency',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Completed Setup',
      columnName: 'completedSetup',
      sx: { paddingLeft: 2 },
    },
  ];
  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const clickableColumn = [
    {
      id: 1,
      column: 'partnerName',
      path: resellersListPath,
    },
    {
      id: 2,
      column: 'organizationName',
      path: organizationInformationPath,
      tab: 0,
    },
  ];

  const sortColumnNames = [{ id: 1, value: 'id', title: 'ID' }];

  const completedSetupValues = [
    { id: 1, value: 'true', title: 'True' },
    { id: 2, value: 'false', title: 'False' },
  ];

  const statusValues = [
    {
      id: 1,
      value: 'completed',
      title: 'Completed',
    },
    {
      id: 2,
      value: 'preparing',
      title: 'Preparing Backup',
    },
    {
      id: 3,
      value: 'running',
      title: 'Running',
    },
    {
      id: 4,
      value: 'retrying',
      title: 'Retry',
    },
    {
      id: 5,
      value: 'error',
      title: 'Error',
    },
  ];

  return {
    entraIdTenantHeaders,
    buttonStyle,
    clickableColumn,
    sortColumnNames,
    completedSetupValues,
    statusValues,
  };
}
