import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { QboButton, QboLink, QboSimpleDataTable } from '@ui/Components';
import RequestModalTool from '@ui/Sections/Shared/tools/RequestModalTool';
import useBulkMailboxDeactivation from '@hooks/tools/useBulkMailboxDeactivation';
import useFeatureFlag from '@hooks/useFeatureFlag';

function TableConfirmation({ title, list }) {
  const [visible, setVisible] = useState(false);

  return (
    <div style={{ marginBottom: '50px' }}>
      <h2>{title}</h2>

      <QboLink
        sx={{ fontSize: '18px', fontWeight: '500', marginBottom: '20px', display: 'block' }}
        onClick={() => {
          setVisible(!visible);
        }}>
        {visible ? 'Hide the list' : 'See the list'}
      </QboLink>

      {visible && (
        <QboSimpleDataTable
          sx={{ width: '100%' }}
          rows={list}
          header={[
            {
              id: 1,
              label: 'ID',
              columnName: 'id',
            },
            {
              id: 2,
              label: 'Email',
              columnName: 'email',
            },
            {
              id: 3,
              label: 'Organization Name',
              columnName: 'organizationName',
            },
            {
              id: 4,
              label: 'Partner Name',
              columnName: 'partnerName',
            },
          ]}
        />
      )}
    </div>
  );
}

TableConfirmation.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
};

TableConfirmation.defaultProps = {
  title: '',
  list: [],
};

export default function BulkMailboxDeactivationToolSection({ list, selectedIds, onCloseModal }) {
  const { t } = useTranslation();
  const { featureFlags, hasPermission } = useFeatureFlag();

  const {
    form,
    onValidateData,
    toolState,
    isAbleToSubmit,
    validList,
    inValidList,
    showRequestModal,
    setShowRequestModal,
  } = useBulkMailboxDeactivation({
    list,
    selectedIds,
    onSuccessRequest: onCloseModal,
  });

  if (!hasPermission(featureFlags.BulkMailboxDeactivation)) {
    return null;
  }

  return (
    <>
      <RequestModalTool
        buttonDisabled={!isAbleToSubmit()}
        form={form}
        onValidateData={onValidateData}
        submitState={toolState}
        showRequestModal={showRequestModal}
        title={t('user_backup_page.bulk_mailbox_deactivation.title')}
        closeSetupModal={() => {
          setShowRequestModal(false);
        }}>
        <div style={{ marginBottom: '10px' }}>
          {validList && validList.length > 0 && (
            <TableConfirmation
              list={validList}
              title={`The deactivation process can now be initiated for ${validList.length} mailboxes`}
            />
          )}

          {inValidList && inValidList.length > 0 && (
            <TableConfirmation
              list={inValidList}
              title={`Deactivation cannot be initiated for ${inValidList.length} mailboxes because it is either inactive or has a backup in running status.`}
            />
          )}
        </div>
      </RequestModalTool>

      <QboButton
        disabled={selectedIds.length === 0}
        sxText={{ fontSize: '18px !important' }}
        variant="contained"
        onClick={() => setShowRequestModal(true)}>
        Run Bulk Mailbox Deactivation
      </QboButton>
    </>
  );
}

BulkMailboxDeactivationToolSection.propTypes = {
  list: PropTypes.array,
  selectedIds: PropTypes.array,
  onCloseModal: PropTypes.func,
};

BulkMailboxDeactivationToolSection.defaultProps = {
  list: [],
  selectedIds: [],
  onCloseModal: () => {},
};
