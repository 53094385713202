import { createAsyncThunk } from '@reduxjs/toolkit';
import { sorMonitoringListAPI } from '@services/WebApiService';

const fetchSORMonitoringListAsync = createAsyncThunk(
  'SORMonitoringList/getSORMonitoringLists',
  async (paramsList) => {
    try {
      const data = await sorMonitoringListAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = fetchSORMonitoringListAsync;

const getSORMonitoringListExtraReducers = {
  [pending]: (state) => {
    state.sorMonitoringListState.fetching = true;
  },
  [rejected]: (state) => {
    state.sorMonitoringListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.sorMonitoringListState.fetching = false;

    if (action?.payload?.status === 403) {
      state.sorMonitoringListState.forbiddenState = true;
      state.sorMonitoringListState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.sorMonitoringListState.UsersListCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.sorMonitoringListState.sorMonitoringList = action?.payload?.data?.data;
      state.sorMonitoringListState.paginations = JSON.parse(action?.payload?.data?.pagination);
      state.sorMonitoringListState.success = true;
      state.sorMonitoringListState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.sorMonitoringListState.error = action.payload.error;
    }
  },
};

export { fetchSORMonitoringListAsync, getSORMonitoringListExtraReducers };
