import { createSlice } from '@reduxjs/toolkit';
import {
  fetchDownloadStatusListAsync,
  getDownloadStatusListExtraReducers,
} from './serviceActions/fetchDownloadStatusListAsync';

const initialState = {
  downloadStatusListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginations: {},
    downloadStatusList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    downloadStatusListCorsErrorMessage: null,
  },
};

const downloadStatusSlice = createSlice({
  name: 'downloadStatusList',
  initialState,
  reducers: {
    resetDownloadStatusListState(state) {
      state.downloadStatusListState = initialState.downloadStatusListState;
    },
  },
  extraReducers: {
    ...getDownloadStatusListExtraReducers,
  },
});

export { fetchDownloadStatusListAsync };
export const { resetDownloadStatusListState } = downloadStatusSlice.actions;
export default downloadStatusSlice.reducer;
