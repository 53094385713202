import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateSpecialPricingAPI } from '@services/WebApiService';

const updateSpecialPricingAsync = createAsyncThunk(
  'specialPricingAsync/updateSpecialPricingAsync',
  async (paramsList) => {
    try {
      const data = await updateSpecialPricingAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = updateSpecialPricingAsync;

const updateSpecialPricingExtraReducers = {
  [pending]: (state) => {
    state.updateSpecialPricingState.fetching = true;
  },
  [rejected]: (state) => {
    state.updateSpecialPricingState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.updateSpecialPricingState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.updateSpecialPricingState.status = action?.payload?.status;
      state.updateSpecialPricingState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.updateSpecialPricingState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.updateSpecialPricingState.message =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.updateSpecialPricingState.status = action?.payload?.error?.status;
      state.updateSpecialPricingState.message = statusMessage?.title || statusMessage?.message;
      state.updateSpecialPricingState.error = action.payload.error;
    } else {
      state.updateSpecialPricingState.success = true;
      state.updateSpecialPricingState.serviceType = action.payload.data.data.serviceTypeCode;
    }
  },
};

export { updateSpecialPricingAsync, updateSpecialPricingExtraReducers };
