import { QboModal, QboSimpleDataTable, QboSimpleTable, QboSnackbar } from '@ui/Components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import NotFoundSection from '../SystemStatusPage/NotFoundSection';

export default function SimpleTableModalSection(props) {
  const { id, title, columns, fetching, dataState, handleOnClose } = props;
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  return (
    <QboModal onClose={handleOnClose} open={!!id} title={title} showCloseIcon preventOutsideClick>
      {fetching && renderLoading()}
      {!fetching && !!Object.keys(dataState).length && (
        <>
          <QboSnackbar onClose={handleSnackbarClose} {...snackbarState} />
          <QboSimpleDataTable rows={dataState} header={columns} />
        </>
      )}
      {!fetching && !Object.keys(dataState).length && <NotFoundSection />}
    </QboModal>
  );
}

SimpleTableModalSection.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string,
  columns: PropTypes.array,
  fetching: PropTypes.bool,
  dataState: PropTypes.object,
  handleOnClose: PropTypes.func,
};

SimpleTableModalSection.defaultProps = {
  title: '',
  columns: [],
  fetching: false,
  dataState: {},
  handleOnClose: () => {},
};
