import { createSlice } from '@reduxjs/toolkit';

import {
  editResellerConfigsAsync,
  editResellerConfigsExtraReducers,
} from './serviceActions/editResellerConfigsAsync';

const initialState = {
  editResellerConfigsState: {
    fetching: false,
    statusCode: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
    serviceType: null,
  },
};

const editResellerConfigsSlice = createSlice({
  name: 'editResellerConfigs',
  initialState,
  reducers: {
    resetEditResellerConfigsState(state) {
      state.editResellerConfigsState = initialState.editResellerConfigsState;
    },
  },
  extraReducers: {
    ...editResellerConfigsExtraReducers,
  },
});

export { editResellerConfigsAsync };

export const { resetEditResellerConfigsState } = editResellerConfigsSlice.actions;
export default editResellerConfigsSlice.reducer;
