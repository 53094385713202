import React, { useEffect, useState, useRef } from 'react';
import { showPartnerListPath } from '@config/Routes/WebClientRoutes';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { QboBreadcrumbs, QboCard, QboTabs, QboTypography } from '@ui/Components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  setOrganizationTabState,
  setPartnerDetailsState,
  setPlanTabState,
  setTabState,
  setUserTabState,
} from '@features/application/applicationSlice';
import ResellerNotificationsListPage from '@pages/ResellersPage/ResellerNotificationsListPage';
import ManageLogPage from '@pages/ManageLogPage';
import PlansListPage from '@pages/PlansListPage';
import OrganizationListPage from '@pages/OrganizationListPage';
import UserListPage from '@pages/UsersListPage';
import ResellersPage from '@pages/ResellersPage';
import SubResellerPage from '@pages/SubResellerPage';
import { resellerLogType } from '@pages/ManageLogPage/pageHooks/useManageLog';
import InvoicesListPage from '@pages/InvoicesListPage';
import useFeatureFlag from '@hooks/useFeatureFlag';

function TextWithLabel({ title, description, color }) {
  return (
    <div style={{ display: 'flex', marginTop: 15 }}>
      <QboTypography sx={{ fontSize: 13, fontWeight: 700 }}>{title}</QboTypography>
      <QboTypography wrap sx={{ fontSize: 13, color: { color } }}>
        &nbsp;{description}
      </QboTypography>
    </div>
  );
}

TextWithLabel.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
};

TextWithLabel.defaultProps = {
  title: '',
  color: '',
  description: '',
};

export default function ResellerTabs() {
  const { partnerDetailsState } = useSelector((state) => state.application);
  const { t } = useTranslation();
  const [selectTab, setSelectTab] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedTabState } = useSelector((state) => state.application);
  const previousPropsRef = useRef(null);
  const previousResellerPropsRef = useRef(null);
  const { featureFlags, hasPermission } = useFeatureFlag();

  const { getSelectedResellerConfigsState } = useSelector(
    (state) => state.selectedResellerConfigsState
  );

  const { row } = partnerDetailsState;
  const [loaded, setLoaded] = useState(false);
  const [original, setOriginal] = useState();
  const location = useLocation();

  useEffect(() => {
    setLoaded(false);
  }, [row, location]);

  useEffect(() => {
    if (loaded) return;
    const paramsString = location.search.startsWith('?')
      ? location.search.substring(1)
      : location.search;
    const searchParams = new URLSearchParams(paramsString);
    const rowObj = row?.row?.original || row;
    setOriginal({
      ...rowObj,
      id: searchParams.get('id') ?? rowObj.id,
      name: searchParams.get('name') ?? rowObj.name,
    });
    setLoaded(true);
  }, [loaded]);

  const handleOnTabClick = (e, newValue) => {
    // dispatch(setTabState(newValue));
    setSelectTab(newValue);
  };
  useEffect(() => {
    if (selectedTabState.tabState) {
      setSelectTab(selectedTabState.tabState);
    }
  }, [selectedTabState.tabState]);

  const names = [
    'Settings',
    'Plans',
    'Notifications',
    'Sub-Resellers',
    'Users',
    'Organisations',
    'Logs',
    'Invoices',
  ];

  useEffect(() => {
    if (loaded && (!original.id || !original.name)) {
      navigate(showPartnerListPath);
    }
  }, [original]);

  const handleOnBreadcrumbClick = () => {
    dispatch(setPartnerDetailsState({}));
    dispatch(setPlanTabState(null));
    dispatch(setOrganizationTabState(null));
    dispatch(setUserTabState(null));
  };

  useEffect(() => {
    if (selectedTabState.tabState) {
      setSelectTab(selectedTabState.tabState);
    }
  }, [selectedTabState.tabState]);

  const yourComponent = (namesArray, selectTabState, originalState) => {
    if (namesArray[selectTabState] === 'Plans') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <PlansListPage
            resellerID={originalState?.id}
            tabFlag={!false}
            selectTab={selectTab}
            plan={originalState?.plan}
          />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Organisations') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <OrganizationListPage
            resellerID={originalState?.id}
            tabFlag={!false}
            selectTab={selectTab}
            plan={(originalState?.planId && originalState?.planName) || ''}
            resellerIDProp={previousResellerPropsRef}
          />
        </QboCard>
      );
    }
    if (namesArray[selectTabState] === 'Users') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <UserListPage resellerID={originalState?.id} tabFlag={!false} selectTab={selectTab} />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Settings') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <ResellersPage resellerID={originalState?.id} resellerName={originalState?.name} />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Notifications') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <ResellerNotificationsListPage resellerID={originalState?.id} />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Sub-Resellers') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <SubResellerPage
            resellerID={originalState?.id}
            tabFlag={!false}
            selectTab={selectTab}
            resellerIDProp={previousPropsRef}
          />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Logs') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <ManageLogPage type={resellerLogType} id={originalState?.id} />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Invoices' && hasPermission(featureFlags.InvoicesList)) {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <InvoicesListPage resellerId={originalState?.id} />
        </QboCard>
      );
    }

    return (
      <QboCard sx={{ marginTop: 2 }} isCentered isBordered>
        <HeaderPageBlock>
          The {namesArray[selectTabState] || namesArray[0]} feature will be available soon.
        </HeaderPageBlock>
      </QboCard>
    );
  };

  return (
    <>
      <HeaderPageBlock>Partner Information</HeaderPageBlock>
      <QboBreadcrumbs sx={{ marginBottom: 2 }}>
        <Link
          onClick={() => handleOnBreadcrumbClick()}
          to={showPartnerListPath}
          style={{
            color: '#1c63d5',
            fontSize: '1.5rem',
            textDecoration: 'underline',
          }}>
          Partners
        </Link>
        <QboTypography color="text.primary" style={{ fontSize: '1.5rem' }}>
          Partner Information
        </QboTypography>
      </QboBreadcrumbs>
      {loaded && original && (
        <QboCard isMainWrapper>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
            <QboTypography variant="h5" wrap sx={{ fontSize: 18 }}>
              {original.name}
            </QboTypography>
            <QboTypography variant="h5" sx={{ marginLeft: 1, fontSize: 16 }}>
              (ID: {original.id})
            </QboTypography>
          </div>

          <div
            style={{
              overflowX: 'auto',
              marginRight: 5,
              display: 'flex',
              scrollbarWidth: 'thin',
              backgroundColor: '#c4e3f0',
            }}>
            <QboTabs tabIndex={selectTab} title={names} onChange={handleOnTabClick} />
          </div>

          {yourComponent(names, selectTab, original)}
        </QboCard>
      )}
    </>
  );
}
