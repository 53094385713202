import { createSlice } from '@reduxjs/toolkit';

import {
  getPlansFilterListAsync,
  getPlansFilterListExtraReducers,
} from './serviceActions/getPlansFilterListAsync';

const initialState = {
  getPlansFilterListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    plansFilterList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    plansFilterListCorsErrorMessage: null,
  },
};

const plansFilterListSlice = createSlice({
  name: 'PlansFilterList',
  initialState,
  reducers: {
    resetPlansFilterListState(state) {
      state.getPlansFilterListState = initialState.getPlansFilterListState;
    },
    resetPlansFilterListStatusCodeState(state) {
      state.getPlansFilterListState.statusCode = initialState.getPlansFilterListState.statusCode;
    },
  },
  extraReducers: {
    ...getPlansFilterListExtraReducers,
  },
});

export { getPlansFilterListAsync };
export const { resetPlansFilterListState, resetPlansFilterListStatusCodeState } =
  plansFilterListSlice.actions;
export default plansFilterListSlice.reducer;
