import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { showFixedAlert } from '@features/application/applicationSlice';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import {
  bulkMailboxDeactivationAsync,
  resetBulkMailboxDeactivationState,
} from '@features/bulkMailboxDeactivation/bulkMailboxDeactivationSlice';
import useForm from './useForm';

export default function useBulkMailboxDeactivation({
  list = [],
  selectedIds = [],
  onSuccessRequest = () => {},
}) {
  const dispatch = useDispatch();
  const [showRequestModal, setShowRequestModal] = useState(false);
  const selectedList = list.filter((l) => selectedIds.includes(l.id));
  const validList = selectedList.filter((l) => l.ableToRunBackup);
  const inValidList = selectedList.filter((l) => !l.ableToRunBackup);

  const form = useForm();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { bulkMailboxDeactivationState } = useSelector((state) => state.bulkMailboxDeactivation);
  const { t } = useTranslation();

  const handleRequestAsync = (parameters) => {
    dispatch(
      bulkMailboxDeactivationAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const submitRequest = () => {
    if (form.isValid && validList && validList.length > 0) {
      const params = {
        'support-ticket': form.attributes.supportTicketAttributes.value,
        description: form.attributes.descriptionAttributes.value,
        'escalation-ticket': form.attributes.escalationTicketAttributes.value,
        payloads: validList.map((l) => ({
          'email-account-id': l.id,
          'org-owner-id': l.organizationId,
        })),
      };
      handleRequestAsync(params);
    }
  };

  const onValidateData = (e) => {
    form.onValidateData(e);
    submitRequest();
  };

  useEffect(() => {
    submitRequest();
  }, [form.isValid]);

  useEffect(() => {
    if (bulkMailboxDeactivationState.statusCode === 200) {
      setShowRequestModal(false);
      form.resetForm();
      dispatch(resetBulkMailboxDeactivationState());

      if (bulkMailboxDeactivationState.serviceType) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': bulkMailboxDeactivationState.serviceType,
        });

        dispatch(
          showFixedAlert({
            descriptionHtml: t(
              'user_backup_page.bulk_mailbox_deactivation.success_submitted_with_link',
              { link }
            ),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('user_backup_page.bulk_mailbox_deactivation.success_submitted'),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
    }
  }, [bulkMailboxDeactivationState.statusCode]);

  const isAbleToSubmit = () => {
    return validList && validList.length > 0;
  };

  return {
    form,
    onValidateData,
    toolState: bulkMailboxDeactivationState,
    validList,
    inValidList,
    isAbleToSubmit,
    showRequestModal,
    setShowRequestModal,
  };
}
