import { createSlice } from '@reduxjs/toolkit';
import {
  fetchSORMonitoringListAsync,
  getSORMonitoringListExtraReducers,
} from './serviceActions/fetchSORMonitoringListAsync';
import {
  fetchSORMonitoringDetailAsync,
  getSORMonitoringDetailExtraReducers,
} from './serviceActions/fetchSORMonitoringDetailAsync';
import {
  fetchSORMonitoringResponseAsync,
  getSORMonitoringResponseExtraReducers,
} from './serviceActions/fetchSORMonitoringResponseAsync';

const initialState = {
  sorMonitoringListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginations: {},
    sorMonitoringList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    sorMonitoringListCorsErrorMessage: null,
  },
  sorMonitoringDetailState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginations: {},
    sorMonitoringDetail: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    sorMonitoringDetailCorsErrorMessage: null,
  },
  sorMonitoringResponseState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    sorMonitoringResponse: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    sorMonitoringResponseCorsErrorMessage: null,
  },
};

const sorMonitoringSlice = createSlice({
  name: 'sorMonitoringList',
  initialState,
  reducers: {
    resetSORMonitoringListState(state) {
      state.sorMonitoringListState = initialState.sorMonitoringListState;
    },
    resetSORMonitoringDetailState(state) {
      state.sorMonitoringDetailState = initialState.sorMonitoringDetailState;
    },
    resetSORMonitoringResponseState(state) {
      state.sorMonitoringResponseState = initialState.sorMonitoringResponseState;
    },
  },
  extraReducers: {
    ...getSORMonitoringListExtraReducers,
    ...getSORMonitoringDetailExtraReducers,
    ...getSORMonitoringResponseExtraReducers,
  },
});

export {
  fetchSORMonitoringListAsync,
  fetchSORMonitoringDetailAsync,
  fetchSORMonitoringResponseAsync,
};
export const {
  resetSORMonitoringListState,
  resetSORMonitoringDetailState,
  resetSORMonitoringResponseState,
} = sorMonitoringSlice.actions;
export default sorMonitoringSlice.reducer;
