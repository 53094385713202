import { createAsyncThunk } from '@reduxjs/toolkit';
import UserBackupService from '@services/WebApiService/UserBackupService';

const bulkMailboxDeactivationAsync = createAsyncThunk(
  'BulkMailboxDeactivation/bulkMailboxDeactivation',
  async (paramsList) => {
    try {
      const data = await UserBackupService.bulkMailboxDeactivationApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = bulkMailboxDeactivationAsync;

const bulkMailboxDeactivationExtraReducers = {
  [pending]: (state) => {
    state.bulkMailboxDeactivationState.fetching = true;
  },
  [rejected]: (state) => {
    state.bulkMailboxDeactivationState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.bulkMailboxDeactivationState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.bulkMailboxDeactivationState.statusCode = action?.payload?.status;
      state.bulkMailboxDeactivationState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.bulkMailboxDeactivationState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.bulkMailboxDeactivationState.message =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.bulkMailboxDeactivationState.statusCode = action?.payload?.error?.status;
      state.bulkMailboxDeactivationState.message = statusMessage?.title || statusMessage?.message;
      state.bulkMailboxDeactivationState.error = action.payload.error;
    } else {
      state.bulkMailboxDeactivationState.success = true;
      state.bulkMailboxDeactivationState.serviceType = action.payload.data.data.serviceTypeCode;
    }
  },
};

export { bulkMailboxDeactivationAsync, bulkMailboxDeactivationExtraReducers };
