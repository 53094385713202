import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  userBackupDetailPath,
  organizationInformationPath,
  resellersListPath,
} from '@config/Routes/WebClientRoutes';
import {
  setOrganizationDetailState,
  setPartnerDetailsState,
  showFixedAlert,
} from '@features/application/applicationSlice';
import {
  fetchUserBackupListAsync,
  fetchUserBackupDetailAsync,
  fecthLastBackupAsync,
  resetFilterState,
  resetPaginationState,
  setDetailPageState,
} from '@features/userBackup/userBackupInfoSlice';
import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFeatureFlag from '@hooks/useFeatureFlag';
import {
  EMAIL,
  EMAIL_DETAIL_ROWS,
  EMAIL_LAST_BACKUP_ROWS,
  EMAIL_COLUMNS,
} from './constants/emailConstants';
import {
  CONTACT,
  CONTACT_COLUMNS,
  CONTACT_DETAIL_ROWS,
  CONTACT_LAST_BACKUP_ROWS,
} from './constants/contactConstants';
import {
  CALENDAR,
  CALENDAR_COLUMNS,
  CALENDAR_DETAIL_ROWS,
  CALENDAR_LAST_BACKUP_ROWS,
} from './constants/calendarConstants';
import {
  TASK,
  TASK_COLUMNS,
  TASK_DETAIL_ROWS,
  TASK_LAST_BACKUP_ROWS,
} from './constants/taskConstants';
import {
  DRIVE,
  DRIVE_COLUMNS,
  DRIVE_DETAIL_ROWS,
  DRIVE_LAST_BACKUP_ROWS,
} from './constants/driveConstants';
import {
  SHAREPOINT,
  SHAREPOINT_COLUMNS,
  SHAREPOINT_DETAIL_ROWS,
  SHAREPOINT_LAST_BACKUP_ROWS,
} from './constants/sharepointConstants';
import {
  MS_GROUP,
  MS_GROUP_COLUMNS,
  MS_GROUP_DETAIL_ROWS,
  MS_GROUP_LAST_BACKUP_ROWS,
} from './constants/msGroupConstants';
import {
  MS_CHAT,
  MS_CHAT_COLUMNS,
  MS_CHAT_DETAIL_ROWS,
  MS_CHAT_LAST_BACKUP_ROWS,
} from './constants/msChatConstants';
import {
  SD_DRIVE,
  SD_DRIVE_COLUMNS,
  SD_DRIVE_DETAIL_ROWS,
  SD_DRIVE_LAST_BACKUP_ROWS,
} from './constants/sdDriveConstants';
import {
  ENTRA_ID,
  ENTRA_ID_COLUMNS,
  ENTRA_ID_DETAIL_ROWS,
  ENTRA_ID_LAST_BACKUP_ROWS,
} from './constants/entraidConstants';

export default function useUserBackupFecthing() {
  const { hasPermission, featureFlags } = useFeatureFlag();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [fetchParams, setFetchParams] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [requestUserDiscrepancyReportModal, setRequestUserDiscrepancyReportModal] = useState();
  const [requestUserBackupReportModal, setRequestUserBackupReportModal] = useState();

  const handleFetchUserBackupListAsync = (
    pageIndex,
    pageSize,
    resellerId,
    organizationOwnerId,
    backupType,
    filters
  ) => {
    dispatch(
      fetchUserBackupListAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageNumber: pageIndex,
        pageSize,
        resellerId,
        organizationOwnerId,
        backupType,
        filters,
      })
    );
    setFetchParams({
      pageNumber: pageIndex,
      pageSize,
      resellerId,
      organizationOwnerId,
      backupType,
      filters,
    });
  };

  const handleFetchUserBackupDetailAsync = (userBackupId, backupType) => {
    dispatch(
      fetchUserBackupDetailAsync({
        accessToken: accessTokenSet,
        reset: true,
        userBackupId,
        backupType,
      })
    );
  };

  const handleFetcLastBackupAsync = (backupId, backupType) => {
    dispatch(
      fecthLastBackupAsync({
        accessToken: accessTokenSet,
        reset: true,
        backupId,
        backupType,
      })
    );
  };

  const handleResetFilter = () => {
    dispatch(resetFilterState());
    dispatch(resetPaginationState());
  };

  const tableColumns = (backupType) => {
    let columns = [];
    switch (backupType) {
      case EMAIL:
        columns = EMAIL_COLUMNS;
        break;
      case CONTACT:
        columns = CONTACT_COLUMNS;
        break;
      case CALENDAR:
        columns = CALENDAR_COLUMNS;
        break;
      case TASK:
        columns = TASK_COLUMNS;
        break;
      case DRIVE:
        columns = DRIVE_COLUMNS;
        break;
      case SHAREPOINT:
        columns = SHAREPOINT_COLUMNS;
        break;
      case MS_GROUP:
        columns = MS_GROUP_COLUMNS;
        break;
      case MS_CHAT:
        columns = MS_CHAT_COLUMNS;
        break;
      case SD_DRIVE:
        columns = SD_DRIVE_COLUMNS;
        break;
      case ENTRA_ID:
        columns = ENTRA_ID_COLUMNS;
        break;
      default:
        columns = EMAIL_COLUMNS;
        break;
    }

    // add click able function
    return columns.map((item) => {
      if (item.columnName === 'email') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleResetFilter();
          dispatch(
            setDetailPageState({
              id: row.userBackupId,
              email: row.email,
              backupType,
            })
          );
          return navigate(userBackupDetailPath);
        };
      }
      if (item.columnName === 'siteName') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleResetFilter();
          dispatch(
            setDetailPageState({
              id: row.userBackupId,
              email: `${row.siteName} - ${row.domainName}`,
              backupType,
            })
          );
          return navigate(userBackupDetailPath);
        };
      }
      if (item.columnName === 'groupName') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleResetFilter();
          dispatch(
            setDetailPageState({
              id: row.userBackupId,
              email: `${row.groupName} - ${row.domainName}`,
              backupType,
            })
          );
          return navigate(userBackupDetailPath);
        };
      }
      if (item.columnName === 'sharedDriveName') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleResetFilter();
          dispatch(
            setDetailPageState({
              id: row.userBackupId,
              email: `${row.sharedDriveName} - ${row.domainName}`,
              backupType,
            })
          );
          return navigate(userBackupDetailPath);
        };
      }
      if (item.columnName === 'backupName') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleResetFilter();
          dispatch(
            setDetailPageState({
              id: row.userBackupId,
              email: `${row.backupName}`,
              backupType,
            })
          );
          return navigate(userBackupDetailPath);
        };
      }
      if (item.columnName === 'organizationName') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleResetFilter();
          dispatch(
            setOrganizationDetailState({
              organizationInformationPath,
              row: { organizationOwnerId: row.organizationId, orgName: row.organizationName },
            })
          );
          return navigate({
            pathname: organizationInformationPath,
            search: createSearchParams({
              id: row.organizationId,
              name: row.organizationName,
            }).toString(),
          });
        };
      }
      if (item.columnName === 'distributorName') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleResetFilter();
          dispatch(
            setPartnerDetailsState({
              resellersListPath,
              row: { id: row.distributorId },
            })
          );
          return navigate({
            pathname: resellersListPath,
            search: createSearchParams({
              id: row.distributorId,
              name: row.distributorName,
            }).toString(),
          });
        };
      }
      if (item.columnName === 'partnerName') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleResetFilter();
          dispatch(
            setPartnerDetailsState({
              resellersListPath,
              row: { id: row.partnerId },
            })
          );
          return navigate({
            pathname: resellersListPath,
            search: createSearchParams({
              id: row.partnerId,
              name: row.partnerName,
            }).toString(),
          });
        };
      }

      return item;
    });
  };

  const detailRows = (backupType) => {
    switch (backupType) {
      case EMAIL:
        return EMAIL_DETAIL_ROWS;
      case CONTACT:
        return CONTACT_DETAIL_ROWS;
      case CALENDAR:
        return CALENDAR_DETAIL_ROWS;
      case TASK:
        return TASK_DETAIL_ROWS;
      case DRIVE:
        return DRIVE_DETAIL_ROWS;
      case SHAREPOINT:
        return SHAREPOINT_DETAIL_ROWS;
      case MS_GROUP:
        return MS_GROUP_DETAIL_ROWS;
      case MS_CHAT:
        return MS_CHAT_DETAIL_ROWS;
      case SD_DRIVE:
        return SD_DRIVE_DETAIL_ROWS;
      case ENTRA_ID:
        return ENTRA_ID_DETAIL_ROWS;
      default:
        return EMAIL_DETAIL_ROWS;
    }
  };

  const lastBackupRows = (backupType) => {
    switch (backupType) {
      case EMAIL:
        return EMAIL_LAST_BACKUP_ROWS;
      case CONTACT:
        return CONTACT_LAST_BACKUP_ROWS;
      case CALENDAR:
        return CALENDAR_LAST_BACKUP_ROWS;
      case TASK:
        return TASK_LAST_BACKUP_ROWS;
      case DRIVE:
        return DRIVE_LAST_BACKUP_ROWS;
      case SHAREPOINT:
        return SHAREPOINT_LAST_BACKUP_ROWS;
      case MS_GROUP:
        return MS_GROUP_LAST_BACKUP_ROWS;
      case MS_CHAT:
        return MS_CHAT_LAST_BACKUP_ROWS;
      case SD_DRIVE:
        return SD_DRIVE_LAST_BACKUP_ROWS;
      case ENTRA_ID:
        return ENTRA_ID_LAST_BACKUP_ROWS;
      default:
        return EMAIL_LAST_BACKUP_ROWS;
    }
  };

  const showUserDiscrepancyModal = (row) => {
    if (row.protocol !== 'Microsoft 365' || row.deletedAt || row.deactivatedSince) {
      dispatch(
        showFixedAlert({
          descriptionHtml: t('tenant_page.user_discrepancy_report.validations.main_and_active'),
          severity: 'error',
        })
      );
    } else {
      setRequestUserDiscrepancyReportModal(true);
      setSelectedAccount(row);
    }
  };

  const showUserBackupReportModal = (row) => {
    if (row.protocol !== 'Microsoft 365' || row.deletedAt || row.deactivatedSince) {
      dispatch(
        showFixedAlert({
          descriptionHtml: t('user_backup_page.user_backup_report.validations.main_and_active'),
          severity: 'error',
        })
      );
    } else {
      setRequestUserBackupReportModal(true);
      setSelectedAccount(row);
    }
  };

  const MenuActions = (row, closeMenu) => {
    const menus = [];
    let i = 1;
    if (hasPermission(featureFlags.UserDiscrepancyReport)) {
      menus.push(
        <MenuItem
          key={i}
          onClick={() => {
            showUserDiscrepancyModal(row);
            setTimeout(() => {
              closeMenu();
            }, 1000);
          }}>
          {t('tenant_page.user_discrepancy_report.title')}
        </MenuItem>
      );
      i += 1;
    }
    if (hasPermission(featureFlags.UserBackupReport)) {
      menus.push(
        <MenuItem
          key={i}
          onClick={() => {
            showUserBackupReportModal(row);
            setTimeout(() => {
              closeMenu();
            }, 1000);
          }}>
          {t('user_backup_page.user_backup_report.title')}
        </MenuItem>
      );
    }
    return menus;
  };

  return {
    handleFetchUserBackupListAsync,
    handleFetchUserBackupDetailAsync,
    handleFetcLastBackupAsync,
    tableColumns,
    detailRows,
    lastBackupRows,
    fetchParams,
    MenuActions,
    selectedAccount,
    requestUserDiscrepancyReportModal,
    setRequestUserDiscrepancyReportModal,
    requestUserBackupReportModal,
    setRequestUserBackupReportModal,
  };
}
